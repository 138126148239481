import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';

export const statusList = {
  0: <FontAwesomeIcon icon={faEllipsisH}/>,
  1: <FontAwesomeIcon icon={faCheck}/>,
  2: <FontAwesomeIcon icon={faCheck} className="__text-success"/>,
  3: <FontAwesomeIcon icon={faCheckDouble} className="__text-success"/>,
};

export const ChatMessageStatus = ({ status }) => (<small className="chat--message-status text-right">{statusList[status]}</small>);

ChatMessageStatus.propTypes = {
  status: PropTypes.number,
};
