export const initialState = {
  error: false,
  apiCallResult: [],
};

export const actions = {
  USERPROFILE_ADD_REQUEST: 'USERPROFILE_ADD:REQUEST',
  USERPROFILE_ADD_SUCCESS: 'USERPROFILE_ADD:SUCCESS',
  USERPROFILE_ADD_ERROR: 'USERPROFILE_ADD:ERROR',

  USERPROFILE_DELETE_REQUEST: 'USERPROFILE_DELETE:REQUEST',
  USERPROFILE_DELETE_SUCCESS: 'USERPROFILE_DELETE:SUCCESS',
  USERPROFILE_DELETE_ERROR: 'USERPROFILE_DELETE:ERROR',

  USERPROFILE_VERIFY_REQUEST: 'USERPROFILE_VERIFY:REQUEST',
  USERPROFILE_VERIFY_SUCCESS: 'USERPROFILE_VERIFY:SUCCESS',
  USERPROFILE_VERIFY_ERROR: 'USERPROFILE_VERIFY:ERROR',

  USERPROFILE_CANCEL_VERIFY_REQUEST: 'USERPROFILE_CANCEL_VERIFY:REQUEST',
  USERPROFILE_CANCEL_VERIFY_SUCCESS: 'USERPROFILE_CANCEL_VERIFY:SUCCESS',
  USERPROFILE_CANCEL_VERIFY_ERROR: 'USERPROFILE_CANCEL_VERIFY:ERROR',

  USERPROFILE_CONFIRM_VERIFY_REQUEST: 'USERPROFILE_CONFIRM_VERIFY:REQUEST',
  USERPROFILE_CONFIRM_VERIFY_SUCCESS: 'USERPROFILE_CONFIRM_VERIFY:SUCCESS',
  USERPROFILE_CONFIRM_VERIFY_ERROR: 'USERPROFILE_CONFIRM_VERIFY:ERROR',
};

export function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USERPROFILE_ADD_REQUEST:
      return {
        ...state,
      };

    case actions.USERPROFILE_ADD_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.USERPROFILE_ADD_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.USERPROFILE_DELETE_REQUEST:
      return {
        ...state,
      };

    case actions.USERPROFILE_DELETE_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.USERPROFILE_DELETE_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.USERPROFILE_VERIFY_REQUEST:
      return {
        ...state,
      };

    case actions.USERPROFILE_VERIFY_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.USERPROFILE_VERIFY_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.USERPROFILE_CANCEL_VERIFY_REQUEST:
      return {
        ...state,
      };

    case actions.USERPROFILE_CANCEL_VERIFY_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.USERPROFILE_CANCEL_VERIFY_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.USERPROFILE_CONFIRM_VERIFY_REQUEST:
      return {
        ...state,
      };

    case actions.USERPROFILE_CONFIRM_VERIFY_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.USERPROFILE_CONFIRM_VERIFY_ERROR:
      return {
        ...state,
        error: true,
      };

    default:
      return { ...state };
  }
}
