import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <h2>Ой, кажется такой страницы не существует. <br/><small>Как вы сюда попали?</small></h2>
      </div>
    );
  }
}

export default NotFound;
