import React from 'react';
import PropTypes from 'prop-types';

export const ChatMessageTimestamp = ({ timestamp }) => (
  <small className="chat--message-timestamp text-muted">{new Date(timestamp).toLocaleString()}</small>
);

ChatMessageTimestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};
