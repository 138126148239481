import AxiosClient from '../../../functional/Axios/AxiosClient';

const apiAuthPath = '/users';

/**
 * Authentication users API methods
 * Patch: /users
 *
 * @param {authRequest} function
 * @param {changeCurrentUserProfile} function
 * @param {fetchUser} function
 * @param {apiSend2FACode} function
 * @param {apiCreate2FACode} function
 */
export const apiAuth = {
  /**
   * Authentication users
   * Request URL: /login
   * Request Method: POST
   *
   * @param {Object} payload - response payload params
   * @param {string} payload.email - users email address
   * @param {string} payload.password - users password
   *
   * @returns {Promise<T | *>}
   */
  authRequest: ({ payload }) => {
    const { email, password } = payload;

    return AxiosClient.post(`${apiAuthPath}/login`, {
      email,
      password,
    })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Change current users profile
   * Request URL: /changeCurrentProfile
   * Request Method: POST
   *
   * @returns {Promise<T | *>}
   * @param payload
   */
  changeCurrentUserProfile: (payload) => AxiosClient.post(`${apiAuthPath}/changeCurrentProfile`, {
    ...payload
  })
    .then((data) => data)
    .catch((error) => error),

  /**
   * Fetch users
   * Request URL: /{id}
   * Request Method: GET
   *
   *
   * @returns {Promise<T | *>}
   */
  fetchUser: () => AxiosClient.get(`${apiAuthPath}/`)
    .then((data) => data)
    .catch((error) => error),

  /**
   * Send two-factor authentication code
   * Request URL: /login/validate
   * Request Method: POST
   *
   * @param {string} code - two-factor authentication code
   *
   * @returns {Promise<T | *>}
   */
  apiSend2FACode: (code) => AxiosClient.post(`${apiAuthPath}/login/validate`, { code })
    .then((result) => result)
    .catch((errors) => {
      throw errors;
    }),

  /**
   * Create two-factor authentication code
   * Request URL: /login/generate
   * Request Method: POST
   *
   * @returns {Promise<T | *>}
   */
  apiCreate2FACode: () => AxiosClient.post(`${apiAuthPath}/login/generate`)
    .then((result) => result)
    .catch((errors) => errors),
};
