import { actions } from './ResetPassword.actions';

export const initialState = {
  email: '',
  update: false,
  error: false,
};

export function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
      };

    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        update: true,
        error: false,
      };

    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        update: false,
        error: true,
      };

    case actions.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
      };

    case actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
      };

    case actions.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        update: false,
        error: true,
      };

    default:
      return { ...state };
  }
}
