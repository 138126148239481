import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getAllUsersWithoutPagination } from '../store/users.actions';
import UserSelect from '../UserSelect/UserSelect';

const UserSelectContainer = (props) => {
  const { getAllUsersWithoutPagination, users } = props;
  const [userId, setUserId] = useState('');

  const handleSelect = (id) => {
    props.handleSelect(id);
    setUserId(id);
  };

  return (
    <>
      {
        users && <UserSelect
          users={users}
          handleSelect={handleSelect}
          defaultValue={userId}
          fetchUsers={getAllUsersWithoutPagination}/>
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  users: store.root.users.elements,
});

const mapDispatchToProps = {
  getAllUsersWithoutPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectContainer);
