import { combineReducers } from 'redux';
import { usersAdminReducer } from '../User/store/users.reducer';
import { newsReducer } from '../News2.0/store/newsAdmin.reducer';
import { faqReducer } from '../Faq/store/faqAdmin.reducer';
import { directionsReducer } from '../Directions/store/direcitons.root.reducer';
import { servicesReducer } from '../Service/store/services.root.reducer';
import { branchesReducer } from '../Branches/store/branchesAdminReducer';
import { doctorsRootReducer } from '../Doctor/store/doctors.root.reducer';
import claimsReducer from '../Claim/store/claims.reducer';
import widgetsAdminReducer from '../Widgets/store/widgetsAdmin.reducer';
import { chatsRootReducer } from '../Chats/store/rootChats.reducer';
import templatesAdminReducer from '../Templates/store/templates.reducer';
import replacementsAdminReducer from '../Replacements/store/replacements.reducer';
import reviewsRootReducer from '../Reviews/store/reviews.reducer';
import settingsRootReducer from '../RootSettings/store/settings.root.reducer';

export const rootAdminReducer = combineReducers({
  users: usersAdminReducer,
  news: newsReducer,
  faq: faqReducer,
  directions: directionsReducer,
  services: servicesReducer,
  branches: branchesReducer,
  claims: claimsReducer,
  doctors: doctorsRootReducer,
  widgets: widgetsAdminReducer,
  chats: chatsRootReducer,
  templates: templatesAdminReducer,
  replacements: replacementsAdminReducer,
  reviews: reviewsRootReducer,
  settings: settingsRootReducer,
});
