import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../containers/UI/Buttons/Button';
import { InputBase } from '../../containers/Inputs/InputBase/inputBase';
import { userForgotPassword } from './store/ForgotPassword.actions';
import { getGlobalConfig } from '../root/AppConfig/store/appconfigActions';
import { hideBell } from '../UI/Header/store/headerActions';

/**
 * Component ForgotPassword
 * The page with email to getting a reset password link
 *
 * @param {Object} props - component props
 *
 * @returns {JSX.Element}
 */
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      email: '',
    };
  }

  componentDidMount() {
    this.props.getAppConfig();
    this.props.hideBell();
  }

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.userForgotPassword(this.state.email);
  };

  render() {
    const { email } = this.state;

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-sm-6 offset-3 m-auto">
            <form className="form-restore" onSubmit={this.onSubmit}>
              <h2 className="text-center">
                Восстановление пароля
              </h2>
              <div className="row">
                <div className="col-12 text-center">
                  Для восстановления пароля введите в поле адрес почты,
                  указанной при регистрации
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-3">
                    <InputBase
                      type="email"
                      className="form-control"
                      placeholder="Почта *"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <p className="text-center">
                <small className="text-muted text-center">
                  Поля, отмеченные * обязательны для заполнения
                </small>
              </p>
              <Button className="btn-firm-filled w-100" type="submit">
                Восстановить пароль
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  getAppConfig: PropTypes.func.isRequired,
  userForgotPassword: PropTypes.func.isRequired,
  hideBell: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  appconfig: store.appconfig,
  forgotPassword: store.forgotPassword,
});

const mapDispatchToProps = {
  getAppConfig: getGlobalConfig,
  userForgotPassword,
  hideBell,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
