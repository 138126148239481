/**
 * Create new state to add pagination fields
 * @param initialState object
 */
export default function withFetch(initialState) {
  if (Object.prototype.hasOwnProperty.call(initialState, 'fetch')) {
    return initialState;
  }

  return {
    ...initialState,
    fetch: false,
  };
}
