/**
 * Create new state to add pagination fields
 * @param initialState object
 */
export default function withQuery(initialState) {
  if (Object.prototype.hasOwnProperty.call(initialState, 'query')) {
    return initialState;
  }

  return {
    ...initialState,
    query: '',
  };
}
