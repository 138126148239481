/**
 * Authentication actions
 */
export const actions = {
  USER_REQUEST: 'USER:REQUEST',
  USER_REQUEST_SUCCESS: 'USER:REQUEST_SUCCESS',
  USER_REQUEST_FAILED: 'USER:REQUEST_FAILED',
  SET_ONLINE: 'SESSION: SET ONLINE',
  SET_OFFLINE: 'SESSION: SET OFFLINE',
  WIPE_SESSION: 'SESSION: WIPE',
  RENEW_TOKEN: 'SESSION:TOKEN_RENEW'
};

/**
 * Get users
 *
 *
 * @returns {actions.USER_REQUEST}
 */
export function getUser(payload) {
  return {
    type: actions.USER_REQUEST,
    payload,
  };
}

/**
 * Set users is online
 *
 * @returns {actions.SET_ONLINE}
 */
export function setOnline() {
  return {
    type: actions.SET_ONLINE,
  };
}

/**
 * Set users is offline
 *
 */
export function setOffline() {
  return {
    type: actions.SET_OFFLINE,
  };
}

export function renewToken(payload) {
  return {
    type: actions.RENEW_TOKEN,
    payload,
  };
}
