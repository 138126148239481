/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize, faWindowMinimize } from '@fortawesome/free-regular-svg-icons';
import SideBarTabContainer from './SideBarTabContainer';
import SideBarTab from './SideBarTab';
import SideBarComponent from './SideBarComponent';
import SideBarComponentContainer from './SideBarComponentContainer';
import SideBarPortal from './SideBarPortal';
import SessionStorageCrypt from '../../../functional/SessionStorageCrypt';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: props.defaultTab || this.props.historyEnabled ? SessionStorageCrypt.getItem(window.location.pathname) || 0 : 0,
      flattenView: false,
      showOpts: false,
    };
  }

  changeTab(index) {
    if (this.props.changeTab) this.props.changeTab(index);
    this.setState({ current: index });
    if (this.props.historyEnabled) {
      SessionStorageCrypt.setItem(window.location.pathname, index);
    }
  }

  Numerator(start = 0) {
    let _start = start;
    return () => _start++;
  }

  componentDidMount() {
    const {
      children,
      tabsOnly
    } = this.props;
    if (tabsOnly) return false;

    if (children && isArray(children)) {
      let ct = 0;
      let cc = 0;

      this.props.children.forEach((child) => {
        if (child.type === SideBarTab) {
          ct++;
        }

        if (child.type === SideBarComponent) {
          cc++;
        }
      });

      if (ct !== cc) throw new Error('Число табов должно соответствовать числу компонентов');
    }
  }

  flatSideBar() {
    this.setState({ flattenView: !this.state.flattenView });
  }

  render() {
    const TabsCounter = this.Numerator();
    const ComponentsCounter = this.Numerator();
    const {
      children,
      sideBarSize,
      containerSize
    } = this.props;
    const { flattenView } = this.state;

    if (!isArray(children)) return 'Ошибка компонента';
    return (
      <div className="row">
        {/* {this.state.showOpts && <button className="btn btn-small sidebar-flat" */}
        {/*                                onClick={() => this.flatSideBar()} */}
        {/*                                style={{ */}
        {/*                                  position: 'absolute', */}
        {/*                                  marginLeft: '-25px', */}
        {/*                                  height: '15px' */}
        {/*                                }}> */}
        {/*  {this.state.flattenView ? <FontAwesomeIcon icon={faWindowMaximize}/> : <FontAwesomeIcon icon={faWindowMinimize}/>} */}
        {/* </button>} */}
        <div className={(flattenView && 'd-none') || sideBarSize || 'col-12 col-md-4'}>
          <SideBarTabContainer>
            {children.map((child, index) => {
              if (child.type === SideBarTab) {
                const count = TabsCounter();
                return React.cloneElement(child, {
                  ...child.props,
                  index: count,
                  changeTab: this.changeTab.bind(this),
                  key: index,
                  active: count === this.state.current,
                });
              }
              return null;
            })}
          </SideBarTabContainer>
          <div className={(flattenView && 'd-none') || 'row'}>
            {children.map((child, index) => {
              if (child.type === SideBarPortal) {
                return React.cloneElement(child, {
                  key: index,
                });
              }
              return null;
            })}
          </div>
        </div>
        <div className={(flattenView && 'col') || containerSize || 'col-12 col-md-8'}>
          <SideBarComponentContainer>
            {children.map((child, index) => {
              if (child.type === SideBarComponent) {
                const count = ComponentsCounter();
                return React.cloneElement(child, {
                  ...child.props,
                  index: count,
                  key: index,
                  active: count === this.state.current,
                });
              }
              return null;
            })}
          </SideBarComponentContainer>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  containerSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  sideBarSize: PropTypes.string,
  defaultTab: PropTypes.number,
  tabsOnly: PropTypes.bool,
  changeTab: PropTypes.func,
  historyEnabled: PropTypes.bool,
};

export default SideBar;
