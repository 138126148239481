import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import './css/style.scss';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { slugify } from 'transliteration';
import { notifyError, notifySuccess } from '../../../UI/Notificator/notificatorActions';
import { SocketManager } from '../../../../ioc/socketManager';
import { changeStatusNotificationAction } from '../../../Notifications/store/notificationsActions';
import { ChatWindow } from './components/ChatWindow/ChatWindow';
import randomBytes from '../../../../functional/randomBytes';
import {
  initClinicChat, updateClinicChatMessages, hideClinicChat, showClinicChat
} from './chatModalActions';
import { ALLOWED_MIMES } from '../../types/ALLOWED_MIMES';
import { CHAT_EVENTS } from '../../types/CHAT_EVENTS';
import { FS_LIMIT } from '../../types/FS_LIMIT';
import { SOCKET_EVENT_TYPE } from '../../../../ioc/types/SOCKET_EVENT_TYPE';
import Button from '../../../../containers/UI/Buttons/Button';
import { RBAC } from '../../../../functional/roles';
import { downloadChatFile } from '../../utils/downloadChatFile';
import { RSS_REGEX } from '../../../../const/rssRegex';

const fileInputId = 'ClinicChatUploadFile';

const ChatWindowDisabled = (props) => (
  <div className="chat-modal d-flex flex-column align-content-between justify-content-between">
    Необходимо войти в систему
    <button className="btn btn-sm btn-link text-dark mb-1" onClick={props.close}>закрыть</button>
  </div>);

class ChatModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatId: '',
      isUploading: false,
      isInitialized: false,
    };
  }

  componentDidMount() {
    if (RBAC(this.props.account, 'USER')) {
      document.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          this.sendMessage(e);
        }
      });

      SocketManager.pushEvent('_onConnectEvents', {
        fn: () => {
          SocketManager.ws.emit('WS:CLINIC_CHAT_INIT', {
            clientId: this.props.account.user.id,
          });
        },
        args: null,
        type: SOCKET_EVENT_TYPE.SOCKET,
      });

      SocketManager.ws.on('WS:CLINIC_CHAT_INITIALIZED', (chat) => {
        this.props.initClinicChat(chat);
        this.setState({ isInitialized: true });
      });

      SocketManager.ws.on(CHAT_EVENTS.CHAT_WITH_CLINIC.FILE_UPLOADED, (messageData) => {
        SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_CLINIC.MESSAGE, messageData);
        this.props.updateClinicChatMessages(messageData);
      });

      SocketManager.ws.on(CHAT_EVENTS.CHAT_WITH_CLINIC.UPDATE_MESSAGES, async (data) => {
        this.props.updateClinicChatMessages(data);
      });
    }
  }

  userTyping = () => {
    SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_CLINIC.USER_TYPING, this.props.chat._id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const k = document.getElementsByClassName('chat-modal--body')[0];
    if (k) k.scrollTop = 99999;
  }

  handlePopup = () => {
    this.props.showClinicChat();
    this.readMessages();
  };

  readMessages = () => {
    const unreadNotifications = this.props.notifications.elements
      .filter((elem) => elem.status === 0 && elem.type === 'NEW_MESSAGE_CLINIC');

    if (unreadNotifications && unreadNotifications.length > 0) {
      unreadNotifications.forEach((notification) => {
        this.props.changeStatusNotificationAction(1, notification._id);
      });
    }
  };

  sendMessage = (ev) => {
    const { chat, account } = this.props;
    ev.preventDefault();
    const textarea = document.getElementById('txt');
    if (textarea) {
      const message = textarea.value.replace(RSS_REGEX, '');
      if (message.length === 0) return false;

      const messageData = {
        chatId: chat._id,
        author: account.user.id,
        text: message,
        hash: `${chat._id}+${randomBytes()}`,
        status: 0,
        type: 'text',
      };

      SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_CLINIC.MESSAGE, messageData);
      this.props.updateClinicChatMessages(messageData);

      textarea.value = '';
    }
  };

  sendFile = () => {
    const file = document.getElementById(fileInputId).files[0];
    const reader = new FileReader();
    this.setState({ isUploading: true });

    if (file) {
      if (ALLOWED_MIMES.indexOf(file.type) === -1) {
        toast.error('Ошибка: неподдерживаемый формат файла');
        setTimeout(() => this.setState({ isUploading: false }), 500);
        return false;
      }

      if (file.size > FS_LIMIT) {
        toast.error(`Ошибка: файл слишком большой. Размер ограничен ${FS_LIMIT}`);
        setTimeout(() => this.setState({ isUploading: false }), 500);
        return false;
      }

      reader.onprogress = () => {
      };

      reader.onload = (e) => {
        const rawData = e.target.result;
        const newFileName = slugify(file?.name);
        const fileData = {
          fileName: newFileName,
          chatId: this.props.chat._id,
          rawData,
        };
        SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_CLINIC.UPLOAD_FILE, fileData);
        setTimeout(() => this.setState({ isUploading: false }), 500);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  downloadFile = downloadChatFile.bind(this)

  render() {
    const {
      chat: { messages, showModal }, account, fetch, hideClinicChat, notifications
    } = this.props;

    const { isInitialized } = this.state;

    if (!RBAC(account, 'USER')) return false;
    if (!isInitialized) {
      return <Button className="btn-outline-dark btn-sm">Чат с клиникой <span className="spinner-border spinner-grow-sm"/></Button>;
    }
    const isNewMessage = notifications.elements.some((elem) => elem.status === 0 && elem.type === 'NEW_MESSAGE_CLINIC');

    return (
      <>
        <Button onClick={this.handlePopup} className="btn-outline-dark btn-sm">
          Чат с клиникой&nbsp;
          {isNewMessage && <span className="badge badge-success">new</span>}
          {!isNewMessage && <FontAwesomeIcon icon={faComment}/>}
        </Button>
        {
          showModal && account.user.firstName && !fetch
          && <ChatWindow
            close={hideClinicChat}
            send={this.sendMessage}
            messages={messages}
            user={account.user}
            fetch={fetch}
            loadFile={this.sendFile}
            downloadFile={this.downloadFile}
            typingCb={this.userTyping}
          />
        }
        {showModal && !account.user.firstName && <ChatWindowDisabled close={hideClinicChat}/>}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.config,
  account: store.account,
  notifications: store.notifications,
  chat: store.chat.chatModal,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  changeStatusNotificationAction,
  updateClinicChatMessages,
  initClinicChat,
  hideClinicChat,
  showClinicChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
