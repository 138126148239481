import { actions } from '../store/modalReducer';

export function hideModal() {
  return {
    type: actions.HIDE_MODAL,
  };
}

export function showModal() {
  return {
    type: actions.SHOW_MODAL,
  };
}

export function loadModal(payload) {
  return {
    type: actions.LOAD_MODAL,
    payload: { ...payload },
  };
}
