import { call, put, takeEvery } from 'redux-saga/effects';
import { configApi } from '../api';
import { actions } from './appconfigReducer';
import { actions as loaderActions } from '../../../UI/Loader/store/loaderReducer';
import LocalStorageCrypt from '../../../../functional/LocalStorageCrypt/LocalStorageCrypt';

export function* getConfigSaga(action) {
  try {
    yield put({ type: loaderActions.SHOW_LOADER, payload: true });

    const response = yield call(configApi.getAppConfig, { ...action.payload });
    const { data } = response;

    if (data) {
      LocalStorageCrypt.remove('appconfig');
      LocalStorageCrypt.setItem('appconfig', data);
    }

    yield put({ type: loaderActions.HIDE_LOADER });
    yield put({
      type: actions.CONFIG_REQUEST_SUCCESS,
      payload: LocalStorageCrypt.getItem('appconfig'),
    });
  } catch (error) {
    yield put({ type: actions.CONFIG_REQUEST_FAILED, payload: LocalStorageCrypt.getItem('appconfig'), });
    yield put({ type: loaderActions.HIDE_LOADER });
  }
}

export function* getAdminConfigSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });

    const response = yield call(configApi.getAdminAppConfig, { ...action.payload });

    const { data } = response;

    if (data) {
      LocalStorageCrypt.remove('appconfig');
      LocalStorageCrypt.setItem('appconfig', data);
    }

    yield put({ type: loaderActions.HIDE_LOADER });
    yield put({
      type: actions.CONFIG_REQUEST_SUCCESS,
      payload: LocalStorageCrypt.getItem('appconfig'),
    });

    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.CONFIG_ADMIN_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export const registerConfigSagas = [
  takeEvery([actions.CONFIG_REQUEST], getConfigSaga),
  takeEvery([actions.CONFIG_ADMIN_REQUEST], getAdminConfigSaga),
];
