import React from 'react';
import './SideBarTab.css';

/**
 *
 * @param index
 * @param active
 * @param children
 * @param sideEffects
 * @param changeTab
 * @param className
 * @returns {*}
 * @constructor
 */
const SideBarTab = ({
  index, active, children, sideEffects, changeTab, className,
}) => {
  const _changeTab = () => {
    if (typeof sideEffects === 'object' && sideEffects.length > 0) {
      sideEffects.forEach((effect) => {
        if (effect.args) return effect.fn(...effect.args);
        return effect.fn();
      });
    }
    changeTab(index);
  };
  if (active) {
    return (
      <li style={{ cursor: 'pointer' }}
          className={`list-group-item tab-active d-flex justify-content-between ${className ?? ''}`}>
        {children}
      </li>);
  }

  return (<li onClick={_changeTab} style={{ cursor: 'pointer' }}
              className={`list-group-item  d-flex justify-content-between ${className ?? ''}`}>
    {children}
  </li>);
};
export default SideBarTab;
