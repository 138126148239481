import { call, put, takeEvery } from 'redux-saga/effects';
import { bookingApi } from '../api';
import { actions } from './booking.reducer';
import { actions as notifyActions } from '../../../../Notificator/notificatorReducer';
import { actions as modalActions } from '../../../store/modalReducer';

export function* bookVisitSaga(action) {
  try {
    const response = yield call(bookingApi.bookVisit, { ...action.payload });
    yield put({
      type: actions.BOOKING_BOOK_VISIT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.BOOKING_BOOK_VISIT_ERROR,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export function* approveVisitSaga(action) {
  try {
    const response = yield call(bookingApi.approveVisit, { ...action.payload });
    yield put({
      type: actions.BOOKING_APPROVE_VISIT_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Ваше резервирование успешно подтверждено',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  } catch (error) {
    yield put({
      type: actions.BOOKING_APPROVE_VISIT_ERROR,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка резервирования',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export const registerBookingSagas = [
  takeEvery([actions.BOOKING_BOOK_VISIT_REQUEST], bookVisitSaga),
  takeEvery([actions.BOOKING_APPROVE_VISIT_REQUEST], approveVisitSaga),
];
