import { actions as rootChatsActions } from './rootChats.reducer';

export function getAllChatsByAdmin(payload) {
  return {
    type: rootChatsActions.CHATS_ADMIN_GET_REQUEST,
    payload,
  };
}

export function getAllClinicChatsByAdmin(payload) {
  return {
    type: rootChatsActions.CHATS_CLINIC_ADMIN_GET_REQUEST,
    payload,
  };
}

export function setAdminPage(page) {
  return {
    type: rootChatsActions.CHATS_ADMIN_CHANGE_PAGE,
    payload: page,
  };
}

export function setAdminClinicPage(page) {
  return {
    type: rootChatsActions.CHATS_ADMIN_CLINIC_CHANGE_PAGE,
    payload: page,
  };
}

export function resetPagination(type) {
  return {
    type: rootChatsActions.CHATS_PAGE_ADMIN_RESET,
    payload: { type },
  };
}

export function addAdminToChat(obj) {
  return {
    type: rootChatsActions.ADMIN_ADD_TO_CHAT_REQUEST,
    payload: { ...obj },
  };
}

export function removeAdminFromChat(obj) {
  return {
    type: rootChatsActions.ADMIN_REMOVE_FROM_CHAT_REQUEST,
    payload: { ...obj },
  };
}
