import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const apiRootDoctor = {
  getAdminDoctors: () => AxiosClient.get('root/doctors/'),
  addDirectionToDoctor: (id, directionId) => AxiosClient.post(`/root/doctors/direction/${id}`, { directionId }),
  addServiceToDoctor: (id, serviceId) => AxiosClient.post(`/root/doctors/service/${id}`, { serviceId }),
  addBranchToDoctor: (id, branchId) => AxiosClient.post(`/root/doctors/branch/${id}`, { branchId }),
  addMisIdDoctor: (id, misId) => AxiosClient.post(`/root/doctors/misId/${id}`, { misId }),
  addDoctorInfo: (id, docInfo) => AxiosClient.put(`/root/doctors/${id}`, { docInfo }),
  addClaimToDoctor: (doctorId, claimId, status) => AxiosClient.post('/root/doctors/claim', { doctorId, claimId, status }),
  deleteDoctorDirection: (id, doctorId) => AxiosClient.delete('root/doctors/direction', { data: { id, doctorId } }),
  deleteDoctorService: (id, doctorId) => AxiosClient.delete('root/doctors/service', { data: { id, doctorId } }),
  deleteDoctorBranch: (id, doctorId) => AxiosClient.delete('root/doctors/branch', { data: { id, doctorId } }),
};
