import React from 'react';
import PropTypes from 'prop-types';

export const UserAgreement = (props) => {
  const { agreement, hideSidePanel, title } = props;
  return (
    <div className="agreement-container pl-5 pr-5 pb-2 pt-3 row">
      <div className="agreement-content col-12">
        {
          agreement && <div>
            <h3>{title}</h3>
            <div className="text-justify"
                 dangerouslySetInnerHTML={{ __html: agreement.replace(/\n/g, '<br />') }}></div>
          </div>
        }
        {
          !agreement && <div>
            Похоже вы еще не заполнили все необходимые настройки клиники
          </div>
        }
      </div>
      <div className="d-flex justify-content-end col-12">
        <button className="btn btn-sm btn-danger btn-custom mt-3" onClick={hideSidePanel}>
          Закрыть
        </button>
      </div>
    </div>
  );
};

UserAgreement.propTypes = {
  agreement: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hideSidePanel: PropTypes.func.isRequired,
};
