/**
 * Registration form actions
 */
export const actions = {
  REGISTER_REQUEST: 'REGISTER:REQUEST',
  REGISTER_REQUEST_SUCCESS: 'REGISTER:REQUEST_SUCCESS',
  REGISTER_REQUEST_FAILED: 'REGISTER:REQUEST_FAILED',
  VERIFY_REQUEST: 'VERIFY:REQUEST',
  VERIFY_REQUEST_SUCCESS: 'VERIFY:REQUEST_SUCCESS',
  VERIFY_REQUEST_FAILED: 'VERIFY:REQUEST_FAILED',
};

/**
 * Register users
 *
 * @returns {actions.REGISTER_REQUEST}
 */
export function registerUser(payload, action) {
  return {
    type: actions.REGISTER_REQUEST,
    payload,
    action,
  };
}

/**
 * Verification users
 *
 * @returns {actions.VERIFY_REQUEST}
 */
export function verify(payload) {
  return {
    type: actions.VERIFY_REQUEST,
    payload,
  };
}
