/**
 * To use notifications simple subscribe component to the store and use these methods
 */

import { actions } from './notificatorReducer';

/**
 *
 * @param str
 * @returns {{payload: *, type: string}}
 */
export function notifySuccess(str) {
  return {
    type: actions.NOTIFICATION_SUCCESS,
    payload: str,
  };
}

/**
 *
 * @param str
 * @returns {{payload: *, type: string}}
 */
export function notifyInfo(str) {
  return {
    type: actions.NOTIFICATION_INFO,
    payload: str,
  };
}

/**
 *
 * @param str
 * @returns {{payload: *, type: string}}
 */
export function notifyError(str) {
  return {
    type: actions.NOTIFICATION_ERROR,
    payload: str,
  };
}

/**
 *
 * @param str
 * @returns {{payload: *, type: string}}
 */
export function notifyWarning(str) {
  return {
    type: actions.NOTIFICATION_WARNING,
    payload: str,
  };
}
