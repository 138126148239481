import AxiosClient from '../../../functional/Axios/AxiosClient';

const apiResetPasswordPath = '/users';

export function updatePasswordViaEmail(payload) {
  const { email, password } = payload;
  return AxiosClient.post(`${apiResetPasswordPath}/updatePasswordViaEmail`, {
    email,
    password,
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export function resetPassword(payload) {
  return AxiosClient.get(`${apiResetPasswordPath}/resetpassword/${payload.token}`)
    .then((res) => res)
    .catch((error) => error.response);
}
