import axios from 'axios';
import SessionStorageCrypt from '../SessionStorageCrypt';

const AxiosClient = axios.create({
  baseURL:
    window.location.hostname === 'localhost'
      ? 'http://localhost:3000/api'
      : `${window.location.protocol}//${window.location.hostname}/api`,
  // onDownloadProgress: function (progressEvent) {
  //   store.dispatch({type: loaderActions.FETCH_INDICATOR_SHOW});
  //   store.dispatch({type: loaderActions.FETCH_INDICATOR_HIDE});
  // }
});

AxiosClient.interceptors.request.use((config) => {
  const token = SessionStorageCrypt.getItem('jwtToken');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token || '';
  return config;
});

export default AxiosClient;
