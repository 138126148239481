export const initialState = {
  messages: [],
  status: [],
  participants: [],
  createdAt: 0,
  updatedAt: 0,
  fetch: true,
  showModal: false,
};

export const actions = {
  CHAT_INIT_WITH_CLINIC: 'CHAT:CLINIC:INIT_WITH_CLINIC',
  CHAT_INIT_WITH_CLINIC_SUCCESS: 'CHAT:CLINIC:INIT_WITH_CLINIC_SUCCESS',
  CHAT_INIT_WITH_CLINIC_FAILED: 'CHAT:CLINIC:INIT_WITH_CLINIC_FAILED',

  CHAT_WITH_CLINIC_SHOW: 'CHAT_CLINIC:SHOW',
  CHAT_WITH_CLINIC_HIDE: 'CHAT_CLINIC:HIDE',

  CHAT_WITH_CLINIC_UPDATE_MESSAGES: 'CHAT:CLINIC:UPDATE_MESSAGES',
};

export function chatModalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHAT_INIT_WITH_CLINIC:
      return {
        ...action.payload,
        fetch: false,
      };

    case actions.CHAT_WITH_CLINIC_UPDATE_MESSAGES: {
      const messages = state.messages.slice(0);
      messages.push(action.payload);
      return {
        ...state,
        messages,
      };
    }

    case actions.CHAT_WITH_CLINIC_SHOW:
      return {
        ...state,
        showModal: true,
      };

    case actions.CHAT_WITH_CLINIC_HIDE:
      return {
        ...state,
        showModal: false,
      };

    default:
      return { ...state };
  }
}
