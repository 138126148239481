import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MetaManager from '../../functional/MetaManager';
import './Breadcrumbs.scss';

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = { location: '' };
  }

  componentDidMount() {
    // элегантный костыль, т.к. у нас отвалился экшен, а пока не до крошек
    const timer = setInterval(() => this.setState({ location: window.location.pathname }), 400);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextState.location !== this.state.location;
  }

  render() {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Личный кабинет</Link>
                </li>
                {MetaManager.getDirectory()
                && <li className="breadcrumb-item">
                  {MetaManager.getPage() !== undefined
                  && <Link to={MetaManager.getDirectoryLink()}>{MetaManager.getDirectory()}</Link>}
                  {MetaManager.getPage() === undefined && MetaManager.getDirectory()}
                </li>
                }

                {MetaManager.getPage()
                && <li className="breadcrumb-item">
                  {MetaManager.getPage()}
                </li>
                }
                {MetaManager.getSubPage()
                && <li className="breadcrumb-item">
                  {MetaManager.getSubPage()}
                </li>
                }
              </ol>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumbs;
