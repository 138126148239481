import React from 'react';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { loadModal, hideModal } from '../../../UI/Modal/actions/ModalActions';
import { connect } from 'react-redux';

const LinkHandler = (props) => {
  const { url, loadModal, hideModal } = props

  const handleLinkClick = () => {
    loadModal({
      component: ExternalLink,
      args: {
        url,
        hideModal,
      },
    });
  };
  return(
    <a role="button" className="" onClick={handleLinkClick}>Внешняя ссылка</a>
  )
}

const mapDispatchToProps = {
  loadModal, hideModal,
};

export default connect(null, mapDispatchToProps)(LinkHandler);
