import {
  put, call, takeEvery, select,
} from 'redux-saga/effects';
import { chatsApi } from '../../api/chats.api';
import { actions } from './chatRoomReducer';
import { ACCESS_ROLES } from '../../../../functional/roles';

export function* getChatSaga(payload) {
  const { id } = payload;
  const type = window.location.search.split('type=')[1];

  try {
    const role = yield select((state) => state.account.user.role);
    let response;

    if (role === +ACCESS_ROLES.CLINIC || +ACCESS_ROLES.ROOT) {
      if (type === 'CHAT_WITH_CLINIC') {
        response = yield call(chatsApi.fetchClinicChat, id);
      } else {
        response = yield call(chatsApi.fetchChat, id);
      }
    } else {
      response = yield call(chatsApi.fetchChat, id);
    }

    yield put({
      type: actions.CHAT_INIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.CHAT_INIT_FAILED });
  }
}

export const registerChatRoomSagas = [
  takeEvery([actions.CHAT_INIT], getChatSaga),
];
