import withPagination from '../../../../store/utils/mutators/withPagination';
import withQuery from '../../../../store/utils/mutators/withQuery';
import withFetch from '../../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  fetch: false,
  filter: false,
  userInfo: {},
  userInfoFetch: true,
};

export const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  USERS_ADMIN_REQUEST: 'USERS_ADMIN:REQUEST',
  USERS_ADMIN_REQUEST_SUCCESS: 'USERS_ADMIN:REQUEST_SUCCESS',
  USERS_ADMIN_REQUEST_FAILED: 'USERS_ADMIN:REQUEST_FAILED',
  USERS_ADMIN_UPDATE: 'USERS_ADMIN:UPDATE',
  USERS_ADMIN_UPDATE_SUCCESS: 'USERS_ADMIN:UPDATE_SUCCESS',
  USERS_ADMIN_UPDATE_FAILED: 'USERS_ADMIN:UPDATE_FAILED',

  USERS_ADMIN_CHANGE_PAGE: 'USERS_ADMIN:CHANGE_PAGE',
  USERS_ADMIN_CHANGE_PAGE_SUCCESS: 'USERS_ADMIN:CHANGE_PAGE_SUCCESS',
  USERS_ADMIN_CHANGE_PAGE_FAILED: 'USERS_ADMIN:CHANGE_PAGE_FAILED',

  USERS_ADMIN_NO_PAGINATION_REQUEST: 'USERS_ADMIN_NO_PAGINATION:REQUEST',
  USERS_ADMIN_NO_PAGINATION_REQUEST_SUCCESS: 'USERS_ADMIN_NO_PAGINATION:REQUEST_SUCCESS',
  USERS_ADMIN_NO_PAGINATION_REQUEST_FAILED: 'USERS_ADMIN_NO_PAGINATION:REQUEST_FAILED',

  USERS_ADMIN_WIPE: 'USERS_ADMIN:WIPE',

  USERS_ADMIN_ADD_USER: 'USERS_ADMIN:USER_REGISTERED',
  USERS_ADMIN_ADD_USER_SUCCESS: 'USERS_ADMIN:ADD_USER_SUCCESS',
  USERS_ADMIN_ADD_USER_FAILED: 'USERS_ADMIN:ADD_USER_FAILED',

  USERS_ADMIN_CHANGE_ROLE_USER: 'USERS_ADMIN:CHANGE_ROLE_USER',
  USERS_ADMIN_CHANGE_ROLE_USER_SUCCESS: 'USERS_ADMIN:CHANGE_ROLE_USER_SUCCESS',
  USERS_ADMIN_CHANGE_ROLE_USER_FAILED: 'USERS_ADMIN:CHANGE_ROLE_USER_FAILED',

  USERS_ADMIN_DELETE_USER: 'USERS_ADMIN:DELETE_USER',
  USERS_ADMIN_DELETE_USER_SUCCESS: 'USERS_ADMIN:DELETE_USER_SUCCESS',
  USERS_ADMIN_DELETE_USER_FAILED: 'USERS_ADMIN:DELETE_USER_FAILED',

  USERS_ADMIN_GET_USER: 'USERS_ADMIN:GET_USER',
  USERS_ADMIN_GET_USER_SUCCESS: 'USERS_ADMIN:GET_USER_SUCCESS',
  USERS_ADMIN_GET_USER_FAILED: 'USERS_ADMIN:GET_USER_FAILED',

  USERS_ADMIN_CHANGE_LIMIT: 'USERS_ADMIN_CHANGE_LIMIT'
};

export function usersAdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USERS_ADMIN_CHANGE_LIMIT: {
      return {
        ...state,
        pagination: { ...state.pagination, limit: action.payload }
      };
    }

    case actions.USERS_ADMIN_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.USERS_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.data.users,
        fetch: false,
        pagination: {
          ...state.pagination,
          count: action.payload.data.count,
        },
      };

    case actions.USERS_ADMIN_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload,
          limit: state.pagination.limit,
        },
      };

    case actions.USERS_ADMIN_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.USERS_ADMIN_NO_PAGINATION_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USERS_ADMIN_NO_PAGINATION_REQUEST_SUCCESS:
      return {
        elements: action.payload.data.users,
        pagination: { ...initialState.pagination },
        fetch: false,
      };

    case actions.USERS_ADMIN_ADD_USER:
      return {
        ...state,
        fetch: true,
      };

    case actions.USERS_ADMIN_ADD_USER_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_ADD_USER_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_CHANGE_ROLE_USER:
      return {
        ...state,
        fetch: true,
      };

    case actions.USERS_ADMIN_CHANGE_ROLE_USER_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_CHANGE_ROLE_USER_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_DELETE_USER:
      return {
        ...state,
        fetch: true,
      };

    case actions.USERS_ADMIN_DELETE_USER_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_DELETE_USER_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USERS_ADMIN_GET_USER:
      return {
        ...state,
      };

    case actions.USERS_ADMIN_GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        userInfoFetch: false,
      };

    case actions.USERS_ADMIN_GET_USER_FAILED:
      return {
        ...state,
        userInfoFetch: false,
      };

    case actions.USERS_ADMIN_WIPE:
      return {
        ...state,
        elements: [],
      };

    default:
      return { ...state };
  }
}

export default usersAdminReducer;
