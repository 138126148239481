export const initialState = {
  elements: [],
  elementsFiltered: [],
  fetching: false,
};

export const actions = {
  DIRECTIONS_ADMIN_REQUEST: ' DIRECTIONS_ADMIN:REQUEST',
  DIRECTIONS_ADMIN_REQUEST_SUCCESS: ' DIRECTIONS_ADMIN:REQUEST_SUCCESS',
  DIRECTIONS_ADMIN_REQUEST_FAILED: ' DIRECTIONS_ADMIN:REQUEST_FAILED',

  DIRECTIONS_ADMIN_FILTER: 'DIRECTIONS_ADMIN:FILTER',
  DIRECTIONS_ADMIN_FILTER_SUCCESS: 'DIRECTIONS_ADMIN:FILTER_SUCCESS',
  DIRECTIONS_ADMIN_FILTER_FAILED: 'DIRECTIONS_ADMIN:FILTER_FAILED',

  DIRECTIONS_ADMIN_RESET_FILTER: 'DIRECTIONS_ADMIN:RESET_FILTER',

  DIRECTION_ADD_REQUEST: 'DIRECTION_ADD:REQUEST',
  DIRECTION_ADD_REQUEST_SUCCESS: 'DIRECTION_ADD:REQUEST_SUCCESS',
  DIRECTION_ADD_REQUEST_FAILED: 'DIRECTION_ADD:REQUEST_FAILED',

  DIRECTION_ADD_MISID_REQUEST: 'DIRECTION_ADD_MISID:REQUEST',
  DIRECTION_ADD_MISID_REQUEST_SUCCESS: 'DIRECTION_ADD_MISID:REQUEST_SUCCESS',
  DIRECTION_ADD_MISID_REQUEST_FAILED: 'DIRECTION_ADD_MISID:REQUEST_FAILED',

  DIRECTION_DELETE_REQUEST: 'DIRECTION_DELETE:REQUEST',
  DIRECTION_DELETE_REQUEST_SUCCESS: 'DIRECTION_DELETE:REQUEST_SUCCESS',
  DIRECTION_DELETE_REQUEST_FAILED: 'DIRECTION_DELETE:REQUEST_FAILED',
};

export function directionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DIRECTIONS_ADMIN_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTIONS_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DIRECTIONS_ADMIN_FILTER:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTIONS_ADMIN_FILTER_SUCCESS:
      return {
        ...state,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DIRECTION_ADD_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTION_ADD_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.DIRECTION_ADD_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case actions.DIRECTION_ADD_MISID_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTION_ADD_MISID_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.DIRECTION_ADD_MISID_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case actions.DIRECTION_DELETE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTION_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.DIRECTION_DELETE_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default directionsReducer;
