async function receiptCreator(sum, user, appconfig) {
  const receipt = {
    Items: [
      {
        label: 'Оплата онлайн консультации',
        price: sum,
        quantity: 1,
        amount: sum,
        vat: appconfig?.clinicSettings?.financial?.vat,
        method: 1,
        object: 4,
      },
    ],
    amounts: {
      electronic: sum,
    },
    TaxationSystem: appconfig?.clinicSettings?.financial?.taxationSystem, // система налогообложения; необязательный, если у вас одна система налогообложения
    email: user?.email, // e-mail покупателя, если нужно отправить письмо с чекомк
  };

  return receipt;
}

export default async function cloudPaymentsDataCreator(args) {
  const { transactionId, sum, appconfig, account } = args
  const data = {};
  const receipt = await receiptCreator(sum, account?.user, appconfig);

  data.cloudPayments = {
    Inn: appconfig?.clinicSettings?.financial?.inn,
    Type: 'Income',
    CultureName: 'ru-RU',
    InvoiceId: transactionId,
    AccountId: account?.user?.id,
    customerReceipt: receipt,
  };

  return data;
}
