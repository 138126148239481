import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const ClaimItemDirection = (props) => {
  const { claim } = props;
  return (
    <div className="mt-2">
      <Chip label={claim.direction?.title} size={'small'}/>
    </div>
  );
};

ClaimItemDirection.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimItemDirection;
