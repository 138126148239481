import { dialogActions } from '../store/dialog.reducer';

export function showDialog() {
  return {
    type: dialogActions.SHOW_DIALOG,
  };
}

export function hideDialog() {
  return {
    type: dialogActions.HIDE_DIALOG,
  };
}

export function loadDialog(props) {
  return {
    type: dialogActions.LOAD_DIALOG,
    payload: props,
  };
}
