import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Chat.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { isEmpty, throttle as _throttle } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SocketManager } from '../../../../ioc/socketManager';
import { getChat, initChat, updateChatMessages } from './chatRoomActions';
import LocalLoader from '../../../../containers/UI/LocalLoader';
import { hideSidePanel, loadSidePanel } from '../../../UI/SidePanel/actions/sidePanel.actions';
import { loadModal } from '../../../UI/Modal/actions/ModalActions';
import { ChatMessage } from './components/ChatMessage/ChatMessage';
import randomBytes from '../../../../functional/randomBytes';
import ChatFiles from '../ChatFiles/ChatFiles';
import ChatParticipants from '../ChatParticipants/ChatParicipants';
import { MESSAGE_EVENT } from '../../types/MESSAGE_EVENT';
import { CHAT_EVENTS } from '../../types/CHAT_EVENTS';
import { SOCKET_EVENT_TYPE } from '../../../../ioc/types/SOCKET_EVENT_TYPE';
import { initClinicChat } from '../ChatModal/chatModalActions';
import addRbacTag from '../../../../hoc/addRbacTag';
import downloadBlob from '../../../../utils/downloadBlob';
import { FilesApi } from '../../../User/components/UserFiles/api/files.api';
import { RSS_REGEX } from '../../../../const/rssRegex';

const flashingText = ({ id, text }, timeout = 1500) => {
  const statusContainer = document.getElementById(id);
  let called = false;

  if (statusContainer) {
    if (called) return false;

    statusContainer.innerText = text;
    setTimeout(() => {
      statusContainer.innerText = '';
      called = true;
    }, timeout);
  }
};

function z() {
  const animatedTextStatus = {
    id: 'online-status',
    text: 'Пользователь печатает...',
  };
  flashingText(animatedTextStatus);
}
const debouncedTypingHandler = _throttle(z, 1550);

class ChatRoomRoot extends Component {
  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();

    this.state = {
      chatId: '',
      loading: true,
      error: false,

      host: false,
      parseUrl: true,

      connectionStatus: 'Устанавливается...',
    };

    this.peer = null;
  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
  }

  componentDidMount() {
    const id = window.location.pathname.split('/')[2];
    const type = window.location.search.split('type=')[1];

    if (!type) {
      throw new Error('Type of chat not set');
    } else {
      this.socketEvents = CHAT_EVENTS[type];
      this.setState({ chatId: id });
      this.props.initChat(id);

      SocketManager.joinChannel(id);

      SocketManager.pushEvent('_onConnectEvents', { fn: SocketManager.joinChannel, args: id, type: SOCKET_EVENT_TYPE.SOCKET });
      SocketManager.pushEvent('_onDisconnectEvents', { fn: SocketManager.leaveChannel, args: id, type: SOCKET_EVENT_TYPE.SOCKET });

      const updateMessageEvent = {
        action: this?.socketEvents.UPDATE_MESSAGES,
        listener: (data) => {
          this.props.updateChatMessages(data);
          this.scrollDown();
        },
      };

      const onFileUploaded = {
        action: 'CUSTOM',
        listener: (messageData) => {
          SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_DOCTOR.MESSAGE, messageData);
          this.props.updateChatMessages(messageData);
          setTimeout(() => this.scrollDown(), 50);
        },
      };

      const userTypingEvent = { action: this.socketEvents.USER_TYPING, listener: debouncedTypingHandler };

      SocketManager.registerWsEvents([updateMessageEvent, userTypingEvent, onFileUploaded]);

      document.addEventListener('keydown', this.keyPressListener);

      setTimeout(() => {
        this.setState({ initialized: true });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollDown();
  }

  keyPressListener = (e) => {
    if (e.keyCode === 13) {
      this.textareaRef.current.value = `${this.textareaRef.current.value}\n`;
    }

    if (e.keyCode === 13 && e.ctrlKey) {
      this.sendMessage();
    }
  }

  componentWillUnmount() {
    SocketManager.removeWsEvents(this.socketEvents);
    document.removeEventListener('keydown', this.keyPressListener);
    SocketManager.leaveChannel(this.state.chatId);
  }

  sendMessage = (ev) => {
    if (ev) ev.preventDefault();

    const message = this.textareaRef.current.value.trim().replace(RSS_REGEX, '');

    if (message.length < 1) return;

    const messageData = {
      chatId: this.state.chatId,
      author: this.props.account.user.id,
      text: message,
      hash: `${this.state.chatId}+${randomBytes()}`,
      status: 0,
      type: 'text',
    };

    SocketManager.ws.emit(this.socketEvents.MESSAGE, messageData);
    this.props.updateChatMessages(messageData);
    this.clearMessageForm();

    setTimeout(() => this.scrollDown(), 50);
  }

  clearMessageForm = () => {
    this.textareaRef.current.value = '';
  }

  userTyping = () => {
    SocketManager.ws.emit(this.socketEvents.USER_TYPING, this.state.chatId);
  }

  scrollDown = (hard = false) => {
    const k = document.getElementsByClassName('messages-container')[0];

    if (k) {
      if (hard) {
        k.scrollTop = 999999;
      } else {
        k.lastChild.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  };

  downloadFile = (e) => {
    const target = e.currentTarget;

    if (target?.dataset?.alias && target?.dataset?.filename) {
      FilesApi.downloadFile(target.dataset.alias)
        .then(({ data }) => {
          downloadBlob(data, target.dataset.filename);
        }).catch((e) => {
          console.log(e);
          toast.error('Файл был удален или больше вам недоступен');
        });
    }
  }

  render() {
    const {
      messages, fetch,
    } = this.props.chat;

    if (fetch || !this.state.chatId) return <LocalLoader title="Подготавливаем чат..."/>;
    if (!fetch) this.scrollDown(true);
    const type = window.location.search.split('type=')[1];

    if (this.state.error) {
      return <div className="card bg-danger"> Произошла ошибка. Перезагрузите
        страницу.</div>;
    }

    const owner = `${this.props.account.user.firstName} ${this.props.account.user.surName}`;
    const isMessagesExists = !isEmpty(messages);

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card">
              <div className="card-body chat">
                <div className="card-title">
                  <div>
                    <small><span style={{ transition: 'all ease .2s' }} id="online-status"/>&nbsp;</small>
                  </div>
                  <hr/>
                </div>
                <div className="messages-container">
                  {
                    isMessagesExists && messages.map((message, i) => <ChatMessage
                    message={message}
                    key={i}
                    owner={owner}
                    socketEvent={type === 'CHAT_WITH_DOCTOR' ? MESSAGE_EVENT.CHAT : MESSAGE_EVENT.CLINIC }
                    downloadFile={this.downloadFile}/>)
                  }
                  {
                    !isMessagesExists && <small className="text-black-50">История сообщений пуста</small>
                  }
                </div>
              </div>
              <div className="card-footer">
                <div className="message-input">
                  <textarea
                    placeholder="Сообщение"
                    ref={this.textareaRef}
                    onKeyPress={() => this.userTyping()}
                    className="form-control"
                  />
                </div>
                <div className="chat-controls">
                  <div className="chat-controls__control text-right">
                    <button onClick={this.sendMessage} className="btn btn-success">
                      Отправить <FontAwesomeIcon icon={faPaperPlane}/>
                    </button>
                  </div>
                </div>
                <div className="chat-controls d-flex justify-content-end">
                  <span className="text-muted">
                    <small>
                       Ctrl+Enter
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-4 mt-lg-0">
            <ChatParticipants chat={this.props.chat}/>
            <ChatFiles
              isDisabled={false}
              chatId={this.state.chatId}
              socketEvents={this?.socketEvents}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
  chat: store.chat.chatRoom,
});

const mapDispatchToProps = {
  getChat,
  initChat,
  initClinicChat,
  updateChatMessages,
  loadSidePanel,
  hideSidePanel,
  loadModal,
};

ChatRoomRoot.propTypes = {
  loadSidePanel: PropTypes.func,
  hideSidePanel: PropTypes.func,
  loadModal: PropTypes.func,
  initChat: PropTypes.func,
  initClinicChat: PropTypes.func,
  updateChatMessages: PropTypes.func,
  chat: PropTypes.objectOf(PropTypes.any),
  account: PropTypes.object,
  currentChat: PropTypes.objectOf(PropTypes.any),
  events: PropTypes.oneOf([Object]),
};

addRbacTag(ChatRoomRoot, 'ChatsView');
export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomRoot);
