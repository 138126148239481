import { actions as loginActions } from '../components/Login/store/login.actions';
import { actions as authValidateActions } from '../components/AuthValidate/store/authValidate.actions';
import { actions as authActions } from './auth.actions';
import SessionStorageCrypt from '../../../functional/SessionStorageCrypt';

export const actions = { ...loginActions, ...authValidateActions, ...authActions };

/**
 * Authentication Reducer Initial State
 */
export const initialState = {
  auth: {
    token: SessionStorageCrypt.getItem('jwtToken') || null,
  },
  user: SessionStorageCrypt.getItem('user') || {
    id: '',
    firstName: '',
    lastName: '',
    surName: '',
    role: '',
    profileId: '',
    profiles: [],
  },
  settings: {},
  session: { online: false },
  access: {},
};

/**
 * Authentication Reducer
 *
 * @param {initialState} state - initial state
 * @param {Object} action - action
 *
 */
export function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.WIPE_SESSION:
      return { ...initialState };

    case actions.AUTH_REQUEST:
      return { ...state };

    case authActions.RENEW_TOKEN: {
      return { ...state, auth: { token: action.payload } };
    }

    case actions.AUTH_REQUEST_SUCCESS: {
      const { token, user, access } = action.payload;

      return {
        ...state,
        auth: { token },
        user,
        access: access || null,
      };
    }

    case actions.AUTH_USER_PROFILE_CHANGE:
      return { ...state };

    case actions.AUTH_USER_PROFILE_CHANGE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case actions.USER_REQUEST:
      return { ...state };

    case actions.USER_REQUEST_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case actions.SET_ONLINE:
      return {
        ...state,
        session: { online: true },
      };

    case actions.SET_OFFLINE:
      return {
        ...state,
        session: { online: false },
      };

    default:
      return { ...state };
  }
}
