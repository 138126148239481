const defaultState = {
  elements: [],
  elementsFiltered: [],
  fetch: false,
};

const initialState = {
  ...defaultState,
};

export const actions = {
  DOCTORS_ALL_REQUEST: 'DOCTORS_ALL:REQUEST',
  DOCTORS_ALL_REQUEST_SUCCESS: 'DOCTORS_ALL:REQUEST_SUCCESS',
  DOCTORS_ALL_REQUEST_FAILED: 'DOCTORS_ALL:REQUEST_FAILED',

  DOCTORS_ALL_UPDATE: 'DOCTORS_ALL:UPDATE',
  DOCTORS_ALL_UPDATE_SUCCESS: 'DOCTORS_ALL:UPDATE_SUCCESS',
  DOCTORS_ALL_UPDATE_FAILED: 'DOCTORS_ALL:UPDATE_FAILED',
};

export function doctorsRootReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DOCTORS_ALL_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.DOCTORS_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        elementsFiltered: action.payload,
        fetch: false,
      };

    case actions.DOCTORS_ALL_UPDATE:
      return {
        ...state,
        fetch: true,
      };

    case actions.DOCTORS_ALL_UPDATE_SUCCESS:
      return {
        ...state,
        elementsFiltered: action.payload,
        fetch: false,
      };

    default:
      return { ...state };
  }
}

export default doctorsRootReducer;
