import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { first, isString, trim } from 'lodash';
import { apiRegistration } from '../api/apiRegistration';
import { actions as registerActions } from './registration-form.actions';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';

function* verification(profile) {
  try {
    const profileVerification = yield call(apiRegistration.profileVerification, profile);
    if (profileVerification.status === 200) {
      yield call(apiRegistration.setProfileVerificationStatus, {
        profileId: profile.profileId,
        status: true,
      });
    }
  } catch (errors) {
    yield call(apiRegistration.setProfileVerificationStatus, {
      profileId: profile.profileId,
      status: false,
    });
  }
}

export function* registerUser(payload) {
  const state = payload.payload;
  const { email, phone } = state;

  try {
    yield put({ type: 'SHOW_LOADER' });
    const result = yield call(apiRegistration.registerUser, state);
    const {
      _id, firstName, lastName, surName, sex, birthday,
    } = first(result.data.profiles);
    const profile = {
      profileId: _id,
      lastName,
      firstName,
      surName,
      sex,
      birthday,
      email: trim(email),
      phonenum: trim(phone).replace(/\s+/g, ''),
    };

    yield call(payload.action);
    yield call(verification, profile);

    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Регистрация успешно завершена!',
    });

    yield put({ type: registerActions.REGISTER_REQUEST_SUCCESS });
  } catch (errors) {
    if (errors && errors.response) {
      yield put({ type: 'HIDE_LOADER' });
      const errorsDescription = errors.response.data;
      if (errors.response.status === 500) {
        toast.error('Ошибка соединения с удаленным сервером');
      } else if (isString(errorsDescription)) {
        // toast.error('Неизвесная ошибка');
      } else if (errorsDescription) {
        Object.keys(errorsDescription).forEach((key) => {
          toast.error(errorsDescription[key]);
        });
      }
    }
    yield put({
      type: registerActions.REGISTER_REQUEST_FAILED,
      payload: errors,
    });
  }
}

export function* verify(payload) {
  const data = payload.payload;

  try {
    const result = yield call(apiRegistration.verify, data);

    yield put({
      type: registerActions.VERIFY_REQUEST_SUCCESS,
      payload: {
        ...result,
        data,
      },
    });
  } catch (errors) {
    yield put({
      type: registerActions.VERIFY_REQUEST_FAILED,
      payload: errors,
    });
    if (errors?.response?.data) {
      toast.error(errors?.response?.data);
    } else {
      toast.error('Неизвестная ошибка, обратитесь в поддержку если она повторится');
    }
  }
}

export const registerRegisterSagas = [
  takeEvery([registerActions.REGISTER_REQUEST], registerUser),
  takeEvery([registerActions.VERIFY_REQUEST], verify),
];
