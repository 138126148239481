import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { isEmpty as _isEmpty } from 'lodash';
import { slugify } from 'transliteration';
import Steer from '../../../../containers/UI/Steer/Steer';
import FileInput from '../../../../containers/Inputs/FileInput/FileInput';
import AxiosClient from '../../../../functional/Axios/AxiosClient';
import { SocketManager } from '../../../../ioc/socketManager';
import ChatFile from './ChatFile';
import { ALLOWED_MIMES } from '../../types/ALLOWED_MIMES';
import { FS_LIMIT } from '../../types/FS_LIMIT';
import { CHAT_EVENTS } from '../../types/CHAT_EVENTS';
import { FilesApi } from '../../../User/components/UserFiles/api/files.api';
import './ChatFiles.scss';
import { PreviewImageFile } from './PreviewImageFile/PreviewImageFile';

const fileHelpMessage = `Вы можете прикрепить файл, необходимый врачу. Например, анализы или справку.
Все прикрепленные файлы останутся в этой консультации. Доступ к ним у вас будет всегда.`;

const inputId = 'chatAttachFile01';

class ChatFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: true,
      filesList: [],
    };
  }

  componentDidMount() {
    const { socketEvents } = this.props;
    SocketManager.ws.on(socketEvents?.GET_FILES, (data) => {
      setTimeout(() => {
        this.setState({ filesList: data, isUploading: false });
      }, 200);
    });
    setTimeout(() => {
      SocketManager.ws.emit(socketEvents.GET_FILES, { chatId: this.props.chatId });
    }, 400);
  }

  deleteFile = (e) => {
    const { socketEvents } = this.props;
    this.setState({ isUploading: true });
    const t = e.currentTarget.dataset.file;
    AxiosClient.delete(`/chats/${this.props.chatId}/${t}`)
      .then(() => {SocketManager.ws.emit(socketEvents.GET_FILES, { chatId: this.props.chatId })})
      .catch((e) => {
        // todo:: perform errorCode enums over whole chats
        if (e?.response?.data?.errorCode === 1000) {
          toast.error('Вы не можете удалить этот файл: вы не владелец');
        } else {
          toast.error('Ошибка удаления файла');
        }
      })
      .finally(() => {
        setTimeout(() => this.setState({ isUploading: false }), 500);
      });
  };

  uploadFile = () => {
    const { socketEvents } = this.props;
    const file = document.getElementById(inputId).files[0];
    const reader = new FileReader();
    this.setState({ isUploading: true });

    if (file) {
      if (ALLOWED_MIMES.indexOf(file.type) === -1) {
        toast.error('Ошибка: неподдерживаемый формат файла');
        setTimeout(() => this.setState({ isUploading: false }), 500);
        return false;
      }

      if (file.size > FS_LIMIT) {
        toast.error(`Ошибка: файл слишком большой. Размер ограничен ${FS_LIMIT}`);
        setTimeout(() => this.setState({ isUploading: false }), 500);
        return false;
      }

      reader.onprogress = () => {
      };

      reader.onload = (e) => {
        const rawData = e.target.result;
        const newFileName = slugify(file?.name);
        const fileData = {
          fileName: newFileName,
          chatId: this.props.chatId,
          rawData,
        };
        SocketManager.ws.emit(socketEvents.UPLOAD_FILE, fileData);
      };

      reader.readAsArrayBuffer(file);
    } else {
      this.setState({ isUploading: false });
      toast.error('Непредвиденная ошибка');
    }
  };

  previewImage = (e) => {
    const target = e.currentTarget;
    const { alias } = target.dataset;
    const filename = target.getAttribute('title');
    const mime = target.getAttribute('mime');
    FilesApi.downloadFile(alias)
      .then(({ data }) => {
        this.props.loadModal({
          component: PreviewImageFile,
          args: {
            data,
            filename,
            mime,
            close: this.props.hideModal.bind(this)
          },
        });
      })
      .catch(() => {
        toast.error('Файл был удален или больше вам недоступен');
      });
  }

  render() {
    const { isDisabled } = this.props;
    const { isUploading, filesList } = this.state;

    return (
      <div className="mt-2">
        <div>
          <small>
            <Steer tooltip={{ title: fileHelpMessage }} text=""/><b> Загруженные файлы</b>:
          </small>
        </div>
        <div>
          <FileInput
            onChange={this.uploadFile} id={inputId}
            disabled={isDisabled || isUploading}

          />
        </div>
        <div className="chat--files-container">
          {_isEmpty(filesList) && 'Файлов нет'}
          {!_isEmpty(filesList) && filesList.map((file) => <ChatFile
            key={file.alias}
            file={file}
            delete={!isDisabled}
            deletecb={this.deleteFile}
            isUploading={this.state.isUploading}
            preview={this.previewImage}
          />)}
        </div>

      </div>
    );
  }
}

ChatFiles.propTypes = {
  isDisabled: PropTypes.bool,
  chatId: PropTypes.string,
  socketEvents: PropTypes.oneOf([CHAT_EVENTS.CHAT_WITH_CLINIC, CHAT_EVENTS.CHAT_WITH_DOCTOR]),
};

export default ChatFiles;
