import React from 'react';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideModal, showModal, loadModal } from './actions/ModalActions';
import './Modal.css';

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ModalContainer = (props) => (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={props.visible}
        onClose={props.hideModal}
        closeAfterTransition
    >
        <div className="card modal-children">
            {props.component && <props.component {...props.args}/>}
        </div>
    </Modal>
);

const mapStateToProps = (store) => ({
  ...store.components.modal,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  loadModal,
};

Modal.propTypes = {
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  children: PropTypes.node,
  visible: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
