import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { resetPassword, updatePasswordViaEmail } from '../api/apiResetPassword';
import { actions } from './ResetPassword.actions';

const responses = {
  ok: 200,
  error: 400,
};

export function* resetPasswordSaga(action) {
  try {
    const data = { token: action.payload.token };
    const result = yield call(resetPassword, data);
    if (result.status === responses.ok) {
      yield put({ type: actions.RESET_PASSWORD_SUCCESS, payload: result.data });
    } else {
      toast.error(result.data);
      yield put({ type: actions.RESET_PASSWORD_ERROR });
    }
  } catch (errors) {
    yield put({ type: actions.RESET_PASSWORD_ERROR });
  }
}

export function* updatePasswordViaEmailSaga(action) {
  try {
    const data = {
      email: action.payload.email,
      password: action.payload.password,
    };
    const result = yield call(updatePasswordViaEmail, data);
    if (result.status === responses.ok) {
      toast.success('Пароль успешно изменен');
      action.payload.redirect();
    } else {
      toast.error(result.data.message || result.data);
      yield put({ type: actions.RESET_PASSWORD_ERROR });
    }
  } catch (errors) {
    yield put({ type: actions.RESET_PASSWORD_ERROR });
  }
}

export const resetPasswordSagas = [
  takeLatest([actions.RESET_PASSWORD_REQUEST], resetPasswordSaga),
  takeLatest([actions.UPDATE_PASSWORD_REQUEST], updatePasswordViaEmailSaga),
];
