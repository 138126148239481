import React from 'react';
import PropTypes from 'prop-types';
import Chevron from '../../../UI/Chevron/Chevron';

const ClaimItemChevron = (props) => {
  const { showActions, toggleClaimActions, account } = props;
  return (
    <>
      {
        account.user.role !== 1 && <div className="col-12 text-right p-0">
          <Chevron showActions={showActions} toggleChevron={toggleClaimActions}/>
        </div>
      }
    </>
  );
};

ClaimItemChevron.propTypes = {
  showActions: PropTypes.bool.isRequired,
  toggleClaimActions: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

export default ClaimItemChevron;
