import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const claimsApi = {
  getAllClaims: ({ query }) => AxiosClient.get(`/root/claim?${query}`),

  fetchDoctorServices: (id) => AxiosClient.get(`/root/doctors/services/${id}`),

  createTransaction: (transaction) => AxiosClient.post('/root/transactions/', transaction),

  addServiceToClaim: (data) => AxiosClient.post('/root/claim/service', data),

  addAdminToClaim: (data) => AxiosClient.put('/root/claim/admin', data),

  deleteAdminFromClaim: (data) => AxiosClient.delete('/root/claim/admin', { data: { data } }),

  claimDownloadFile: (filename) => AxiosClient.post('/claim/file', { filename }, { responseType: 'blob' }),

  updateClaim: (data) => AxiosClient.put('/root/claim/', data),

  createClaim: (data) => AxiosClient.post('/root/claim/', data),
};
