import AxiosClient from '../../../../functional/Axios/AxiosClient';

export const rootChatsApi = {

  addAdminToChat: ({ ...payload }) => {
    const {
      id, el,
    } = payload.payload;
    const { _id, type } = el;
    return AxiosClient.put('/root/chats/admin', { id, _id, type })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  removeAdminFromChat: ({ ...payload }) => {
    const {
      id, chatId, chatType,
    } = payload.payload;
    return AxiosClient.delete('/root/chats/admin', { data: { id, chatId, chatType } })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  fetchAllChatsByAdmin: ({ query }) => AxiosClient.get(`/root/chats/?${query}`),

  fetchAllClinicChatsByAdmin: ({ query }) => AxiosClient.get(`/root/chats/clinic?${query}`),
};
