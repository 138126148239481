export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  FAQ_ALL_REQUEST: 'FAQ_ALL:REQUEST',
  FAQ_ALL_REQUEST_SUCCESS: 'FAQ_ALL:REQUEST_SUCCESS',
  FAQ_ALL_REQUEST_FAILED: 'FAQ_ALL:REQUEST_FAILED',
  FAQ_CREATE_TOPIC_REQUEST: 'FAQ_CREATE_TOPIC:REQUEST',
  FAQ_CREATE_TOPIC_SUCCESS: 'FAQ_CREATE_TOPIC:REQUEST_SUCCESS',
  FAQ_CREATE_TOPIC_FAILED: 'FAQ_CREATE_TOPIC:REQUEST_FAILED',
  FAQ_CREATE_ARTICLE_REQUEST: 'FAQ_CREATE_ARTICLE:REQUEST',
  FAQ_CREATE_ARTICLE_SUCCESS: 'FAQ_CREATE_ARTICLE:REQUEST_SUCCESS',
  FAQ_CREATE_ARTICLE_FAILED: 'FAQ_CREATE_ARTICLE:REQUEST_FAILED',
  FAQ_UPDATE_ARTICLE_REQUEST: 'FAQ_UPDATE_ARTICLE:REQUEST',
  FAQ_UPDATE_ARTICLE_SUCCESS: 'FAQ_UPDATE_ARTICLE:REQUEST_SUCCESS',
  FAQ_UPDATE_ARTICLE_FAILED: 'FAQ_UPDATE_ARTICLE:REQUEST_FAILED',
  FAQ_UPDATE_TOPIC_REQUEST: 'FAQ_UPDATE_TOPIC:REQUEST',
  FAQ_UPDATE_TOPIC_SUCCESS: 'FAQ_UPDATE_TOPIC:REQUEST_SUCCESS',
  FAQ_UPDATE_TOPIC_FAILED: 'FAQ_UPDATE_TOPIC:REQUEST_FAILED',
  FAQ_DELETE_TOPIC_REQUEST: 'FAQ_DELETE_TOPIC:REQUEST',
  FAQ_DELETE_TOPIC_SUCCESS: 'FAQ_DELETE_TOPIC:REQUEST_SUCCESS',
  FAQ_DELETE_TOPIC_FAILED: 'FAQ_DELETE_TOPIC:REQUEST_FAILED',
  FAQ_DELETE_ARTICLE_REQUEST: 'FAQ_DELETE_ARTICLE:REQUEST',
  FAQ_DELETE_ARTICLE_SUCCESS: 'FAQ_DELETE_ARTICLE:REQUEST_SUCCESS',
  FAQ_DELETE_ARTICLE_FAILED: 'FAQ_DELETE_ARTICLE:REQUEST_FAILED',
};

export function faqReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FAQ_ALL_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.FAQ_ALL_REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_CREATE_TOPIC_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_CREATE_ARTICLE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_UPDATE_ARTICLE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_DELETE_TOPIC_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.FAQ_DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.FAQ_DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default faqReducer;
