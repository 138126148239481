import {
  put, takeEvery, takeLatest, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { actions as notificationActions } from './notificationsReducer';
import { fetchNotifications, changeStatusNotification, readAllNotifications } from '../api';

function* getUserNotificationsSaga() {
  try {
    const { data } = yield call(fetchNotifications);
    yield put({
      type: notificationActions.GET_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: notificationActions.GET_NOTIFICATIONS_FAILED });
  }
}

function* readUserNotificationSaga({ payload }) {
  try {
    const { data } = yield call(changeStatusNotification, payload);
    yield put({
      type: notificationActions.READ_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: notificationActions.READ_NOTIFICATION_FAILED });
  }
}

function* deleteUserNotificationSaga({ payload }) {
  try {
    const { data } = yield call(changeStatusNotification, payload);
    yield put({
      type: notificationActions.DELETE_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: notificationActions.DELETE_NOTIFICATION_FAILED });
  }
}

function* readAllNotificationsSaga() {
  try {
    yield call(readAllNotifications);
    yield put({
      type: notificationActions.READ_NOTIFICATIONS_SUCCESS,
      payload: [],
    });
  } catch (error) {
    yield put({ type: notificationActions.READ_NOTIFICATIONS_FAILED });
  }
}

function* pushWebNotificationSaga({ payload }) {
  const options = {
    body: `${payload.message}`,
    dir: 'auto',
    icon: '/favicon-32x32.png',
  };
  // eslint-disable-next-line no-new
  new Notification('Новое уведомление!', options);
  yield put({
    type: notificationActions.PUSH_WEB_NOTIFICATION_SUCCESS,
  });
}

export const registerNotificationSagas = [
  takeEvery([notificationActions.GET_NOTIFICATIONS], getUserNotificationsSaga),
  takeEvery([notificationActions.READ_NOTIFICATIONS], readAllNotificationsSaga),
  takeEvery([notificationActions.READ_NOTIFICATION], readUserNotificationSaga),
  takeEvery([notificationActions.DELETE_NOTIFICATION], deleteUserNotificationSaga),
  takeEvery([notificationActions.PUSH_WEB_NOTIFICATION], pushWebNotificationSaga),
  takeLatest([
    notificationActions.READ_NOTIFICATION_FAILED,
    notificationActions.GET_NOTIFICATIONS_FAILED,
    notificationActions.DELETE_NOTIFICATION_FAILED,
    // eslint-disable-next-line func-names
  ], function* () {
    yield toast.error('Не удалось установить соединение с сервером. Действие невозможно');
  }),
];
