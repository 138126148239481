import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../../../../../containers/UI/Buttons/Button';
import UserAnalysisList from '../../UserAnalysis/UserAnalysisList/UserAnalysisList';

const VisitListDetails = (props) => {
  const { visitDetails, profileId, hideDetails } = props;
  const [showFiles, setShowFiles] = useState(false);

  const showFilesToggle = () => {
    setShowFiles(!showFiles);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <p><strong>Диагноз:</strong><br/>
          {
            visitDetails?.diagnosesNames && visitDetails?.diagnosesNames.map((elem) => (
              <p key={visitDetails?.recId}>{elem}</p>
            ))
          }
        </p>
        <p>Режим: <br/> {visitDetails?.regime}</p>
      </div>
      <p><strong>План обследований:</strong> <br/> {visitDetails?.examPlan}</p>
      <p><strong>Назначения:</strong> <br/> {visitDetails?.pharmacotherapy}</p>
      <p><strong>Дата следующего визита:</strong> {visitDetails?.nextVisit}</p>
      {
        showFiles && <UserAnalysisList
          files={visitDetails?.files}
          profileId={profileId}
        />
      }
      <div className="d-flex justify-content-between mt-4">
        <Button className="btn-outline-primary btn-sm" onClick={hideDetails}>
          Скрыть детали
        </Button>
        {
          !isEmpty(visitDetails?.files) && <Button className="btn-outline-primary btn-sm" onClick={showFilesToggle}>
            {
              showFiles ? 'Скрыть файлы' : 'Прикрепленные файлы'
            }
          </Button>
        }
      </div>
    </>
  );
};

export default VisitListDetails;
