import AxiosClient from '../../../functional/Axios/AxiosClient';

export const newsApi = {
  getNews: ({ query }) => AxiosClient.get(`/news?${query}`),

  newsGet: (role) => {
    AxiosClient.get(`/news/${role}`)
      .then((data) => data)
      .catch((error) => error);
  },

  getLatestNews: ({ ...payload }) => {
    const { id } = payload.payload;
    return AxiosClient.get(`/news/latest/${id}`)
      .then((data) => data)
      .catch((error) => error);
  },

};
