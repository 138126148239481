import React from 'react';
import PropTypes from 'prop-types';

export const ChatMessageInfo = ({ children }) => (
  <div className="chat--message-info d-flex justify-content-between">
    { children }
  </div>
);

ChatMessageInfo.propTypes = {
  children: PropTypes.node.isRequired,
};
