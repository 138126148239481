import withQuery from '../../../../store/utils/mutators/withQuery';
import withPagination from '../../../../store/utils/mutators/withPagination';
import withFetch from '../../../../store/utils/mutators/withFetch';

const defaultState = {
  elements: [],
  filter: [],
  fetch: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  CHAT_GET_REQUEST: 'CHAT:GET_CHAT_REQUEST',
  CHAT_GET_SUCCESS: 'CHAT:GET_CHAT_SUCCESS',
  CHAT_GET_FAILED: 'CHAT:GET_CHAT_FAILED',

  CHAT_UPDATE_REQUEST: 'CHAT:UPDATE_CHAT_REQUEST',
  CHAT_UPDATE_SUCCESS: 'CHAT:UPDATE_SUCCESS',
  CHAT_UPDATE_FAILED: 'CHAT:UPDATE_FAILED',

  CHATS_GET_REQUEST: 'CHATS:GET_CHATS_REQUEST',
  CHATS_GET_SUCCESS: 'CHATS:GET_CHATS_SUCCESS',
  CHATS_GET_FAILED: 'CHATS:GET_CHATS_FAILED',

  CHATS_CHANGE_PAGE: 'CHATS:CHANGE_PAGE',
  CHATS_CHANGE_PAGE_SUCCESS: 'CHATS:CHANGE_PAGE_SUCCESS',
  CHATS_CHANGE_PAGE_FAILED: 'CHATS:CHANGE_PAGE_FAILED',
  CHATS_PAGE_RESET: 'CHATS:PAGE_RESET',

  CHATS_FILTER: 'CHATS:FILTER_REQUEST',
  CHATS_FILTER_RESET: 'CHATS:FILTER_REQUEST_RESET',
  CHATS_FILTER_SUCCESS: 'CHATS:FILTER_REQUEST_SUCCESS',
  CHATS_FILTER_FAILED: 'CHATS:FILTER_REQUEST_FAILED',
};

export function activeChatsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHATS_GET_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.CHATS_GET_SUCCESS:
      return {
        ...state,
        elements: action.payload.chats,
        query: action.payload.query,
        fetch: false,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      };

    case actions.CHATS_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload,
          limit: state.pagination.limit,
        },
      };

    case actions.CHATS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.CHATS_GET_FAILED:
      return {
        ...state,
        elements: [],
      };

    case actions.CHAT_UPDATE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const index = state.elements.findIndex((chat) => chat._id === action.payload._id);

      if (index !== -1) {
        let chat = state.elements[index];
        // eslint-disable-next-line no-param-reassign
        delete state.elements[index];
        chat = { ...action.payload, hasNewMessages: true };
        state.elements.unshift(chat);
      }
      return {
        ...state,
      };

    case actions.CHATS_FILTER:
      return {
        ...state,
        query: action.payload,
        pagination: { ...initialState.pagination },
      };

    case actions.CHATS_FILTER_RESET:
      return {
        ...state,
        query: '',
        pagination: { ...initialState.pagination },
      };

    case actions.CHATS_PAGE_RESET: {
      return {
        ...state,
        fetch: false,
        pagination: {
          ...initialState.pagination,
        },
      };
    }

    default:
      return { ...state };
  }
}
