import React from 'react';

const ModalButtons = (props) => (
    <div className="mt-2 mr-3">
        {
            (props.direction || props.doctor) && props.comment ? (
                <button type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled={props.disabled}
                        onClick={props.createClaim}>
                    Оставить заявку
                </button>
            ) : (
                <button type="button"
                        className="btn btn-outline-primary btn-sm disabled">Заполните все необходимые поля
                </button>

            )
        }
    </div>
);

export default ModalButtons;
