import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import Button from '../../../containers/UI/Buttons/Button';
import { hideDialog, showDialog } from './actions/dialog.actions';

function Dialog(props) {
  const { visible, hideDialog, onClose } = props;

  const handleClose = () => {
    hideDialog();
    onClose()
  };

  return (
    <MuiDialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="border-bottom">{props.title}</DialogTitle>
      <DialogContent className="border-bottom py-3">
        {props.message}
      </DialogContent>
      <DialogActions className="py-3">
        {props.buttons.map((btn) => <Button key={btn.label}
                                            className={btn.className}
                                            onClick={btn.onClick}
                                            color="primary">
          {btn.label}
        </Button>)}
      </DialogActions>
    </MuiDialog>
  );
}

const mapStateToProps = (store) => ({
  ...store.components.dialog,
});

const mapDispatchToProps = {
  showDialog,
  hideDialog,
};

Dialog.defaultProps = {
  title: 'Заголовок',
  message: 'Кажется, вы забыли загрузить сюда текст',
  buttons: [{
    label: 'Kek',
    onClick: () => {
    },
  }],
  visible: false,
  onClose: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
