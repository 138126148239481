import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { useHistory } from 'react-router-dom';
import ChatModal from '../../Chat/components/ChatModal';
import pckg from '../../../../../package.json';
import './Footer.scss';

const Footer = (props) => {
  const history = useHistory();
  const { account, appconfig } = props

  return (
    <footer className="footer container-fluid fixed-bottom">
      <div className="container p-2 p-md-2">
        <div className="row d-flex justify-content-between">
          <div className="d-none d-sm-flex col-md-3 col-sm-3 text-black-50 mt-2 mt-lg-1">{pckg.version}</div>
          { account?.user?.firstName &&
            <div className="col-12 col-md-6 col-sm-9 d-flex justify-content-between justify-content-lg-end ">
              { appconfig?.appSettings?.enableAdminChat && <ChatModal/> }
              <button onClick={() => history.push('/faq')} className="btn btn-outline-dark btn-sm ml-2">
                <span className="d-none d-sm-inline">Инструкции </span><FontAwesomeIcon icon={faQuestionCircle}/>
              </button>
              <a className="btn btn-link ml-2" href="mailto:support@qheal.ru">Поддержка</a>
            </div>
          }
          <div className="d-none d-md-block col-md-3 text-right text-black-50 mt-2 mt-lg-1">
            2023 © Qheal
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (store) => ({
  account: store.account,
  appconfig: store?.appconfig,
});

Footer.propTypes = {
  account: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string,
    }),
  }),
  appconfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(Footer);
