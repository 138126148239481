import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateFormats } from '../../../../const/dateFormats';

const ClaimItemDateTime = (props) => {
  const { claim } = props;
  return (
    <>
      <div className="col-6 d-flex justify-content-between mb-2">
        <div>
          <b>Заявка создана:</b> {moment(claim.createdAt).format(DateFormats.DAY_MONTH_YEAR_TIME)}
        </div>
      </div>
      <div className="col-6 mb-2">
        <div>
          <b>Заявка обновлена:</b> {moment(claim.updatedAt).format(DateFormats.DAY_MONTH_YEAR_TIME)}
        </div>
      </div>
    </>
  );
};

ClaimItemDateTime.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimItemDateTime;
