import React from 'react';
import PropTypes from 'prop-types';
import SelectUser from '../../root/User/UserSelect/UserSelect';

const AdminSelect = (props) => {
  const { admins, el, addAdmin } = props;
  return (
      <>
          {
              admins && <div className="mb-2">
                  <SelectUser
                    users={admins}
                    handleSelect={(id) => addAdmin({ id, el })}
                    placeholder={'Добавьте администратора'}
                  />
              </div>
          }
      </>
  );
};

AdminSelect.propTypes = {
  admins: PropTypes.array.isRequired,
  el: PropTypes.object.isRequired,
  addAdmin: PropTypes.func.isRequired,
};

export default AdminSelect;
