import { toast } from 'react-toastify';

export const actions = {
  NOTIFICATION_SUCCESS: 'NOTIFICATION: SUCCESS',
  NOTIFICATION_ERROR: 'NOTIFICATION: ERROR',
  NOTIFICATION_INFO: 'NOTIFICATION: INFO',
  NOTIFICATION_WARNING: 'NOTIFICATION: WARNING',
};

export function notificationReducer(state = {}, action) {
  switch (action.type) {
    case actions.NOTIFICATION_SUCCESS:
      if (action.payload !== '') {
        toast.success(action.payload);
      }
      return true;

    case actions.NOTIFICATION_ERROR:
      if (action.payload !== '') {
        toast.error(action.payload);
      }
      return true;

    case actions.NOTIFICATION_INFO:
      if (action.payload !== '') {
        toast.info(action.payload);
      }
      return true;

    case actions.NOTIFICATION_WARNING:
      if (action.payload !== '') {
        toast.warning(action.payload);
      }
      return true;

    default:
      return true;
  }
}
