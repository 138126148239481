import React from 'react';
import PropTypes from 'prop-types';
import { ClaimStatus } from '../../const/constants';

const ClaimItemStatus = (props) => {
  const { claim } = props;
  return (
    <div className="col-12 mb-2"><b>Статус заявки:</b> {ClaimStatus[claim.status]} <br/></div>
  );
};

ClaimItemStatus.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimItemStatus;
