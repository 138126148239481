import withQuery from '../../../../store/utils/mutators/withQuery';
import withPagination from '../../../../store/utils/mutators/withPagination';
import withFetch from '../../../../store/utils/mutators/withFetch';

const defaultState = {
  elements: [],
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  ARCHIVE_CHATS_GET_REQUEST: 'ARCHIVE_CHATS:GET_CHATS_REQUEST',
  ARCHIVE_CHATS_GET_SUCCESS: 'ARCHIVE_CHATS:GET_CHATS_SUCCESS',
  ARCHIVE_CHATS_GET_FAILED: 'ARCHIVE_CHATS:GET_CHATS_FAILED',

  ARCHIVE_CHATS_CHANGE_PAGE: 'ARCHIVE_CHATS:CHANGE_PAGE',
  ARCHIVE_CHATS_CHANGE_PAGE_SUCCESS: 'ARCHIVE_CHATS:CHANGE_PAGE_SUCCESS',
  ARCHIVE_CHATS_CHANGE_PAGE_FAILED: 'ARCHIVE_CHATS:CHANGE_PAGE_FAILED',
  ARCHIVE_CHATS_PAGE_RESET: 'ARCHIVE_CHATS:PAGE_RESET',

  ARCHIVE_CHATS_FILTER: 'ARCHIVE_CHATS:FILTER_REQUEST',
  ARCHIVE_CHATS_FILTER_RESET: 'ARCHIVE_CHATS:FILTER_REQUEST_RESET',
  ARCHIVE_CHATS_FILTER_SUCCESS: 'ARCHIVE_CHATS:FILTER_REQUEST_SUCCESS',
  ARCHIVE_CHATS_FILTER_FAILED: 'ARCHIVE_CHATS:FILTER_REQUEST_FAILED',
};

export function archiveChatsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ARCHIVE_CHATS_GET_REQUEST:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: state.pagination.page,
          end: false,
        },
      };

    case actions.ARCHIVE_CHATS_GET_SUCCESS:

      return {
        ...state,
        elements: action.payload.chats,
        query: action.payload.query,
        fetch: false,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      };

    case actions.ARCHIVE_CHATS_GET_FAILED:
      return {
        ...state,
        elements: [],
      };

    case actions.ARCHIVE_CHATS_FILTER:
      return {
        ...state,
        query: action.payload,
        pagination: { ...initialState.pagination },

      };

    case actions.CHATS_ADMIN_GET_REQUEST:
      return { ...state };

    case actions.ARCHIVE_CHATS_PAGE_RESET: {
      return {
        ...state,
        fetch: false,
        pagination: {
          ...initialState.pagination,
        },
      };
    }

    case actions.ARCHIVE_CHATS_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload,
          limit: state.pagination.limit,
        },
      };

    case actions.ARCHIVE_CHATS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}
