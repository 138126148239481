export const initialState = {
  error: false,
  historyVisits: [],
  futureVisits: [],
  analysis: [],
  visitDetails: {},
};

export const actions = {
  EMR_VISIT_HISTORY_REQUEST: 'EMR_VISIT_HISTORY:REQUEST',
  EMR_VISIT_HISTORY_SUCCESS: 'EMR_VISIT_HISTORY:SUCCESS',
  EMR_VISIT_HISTORY_ERROR: 'EMR_VISIT_HISTORY:ERROR',
  EMR_VISIT_HISTORY_DETAILS_REQUEST: 'EMR_VISIT_HISTORY_DETAILS:REQUEST',
  EMR_VISIT_HISTORY_DETAILS_SUCCESS: 'EMR_VISIT_HISTORY_DETAILS:SUCCESS',
  EMR_VISIT_HISTORY_DETAILS_ERROR: 'EMR_VISIT_HISTORY_DETAILS:ERROR',
  EMR_VISIT_FUTURE_REQUEST: 'EMR_VISIT_FUTURE:REQUEST',
  EMR_VISIT_FUTURE_SUCCESS: 'EMR_VISIT_FUTURE:SUCCESS',
  EMR_VISIT_FUTURE_ERROR: 'EMR_VISIT_FUTURE:ERROR',
  EMR_VISIT_FUTURE_CANCEL_REQUEST: 'EMR_VISIT_FUTURE_CANCEL:REQUEST',
  EMR_VISIT_FUTURE_CANCEL_SUCCESS: 'EMR_VISIT_FUTURE_CANCEL:SUCCESS',
  EMR_VISIT_FUTURE_CANCEL_ERROR: 'EMR_VISIT_FUTURE_CANCEL:ERROR',
  EMR_ANALYSIS_REQUEST: 'EMR_ANALYSIS:REQUEST',
  EMR_ANALYSIS_SUCCESS: 'EMR_ANALYSIS:SUCCESS',
  EMR_ANALYSIS_ERROR: 'EMR_ANALYSIS:ERROR',
  EMR_CLEAN_ALL: 'EMR_CLEAN_ALL:REQUEST',
};

export function userEmrReducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMR_VISIT_HISTORY_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_VISIT_HISTORY_SUCCESS:
      return {
        ...state,
        error: false,
        historyVisits: action.payload,
      };

    case actions.EMR_VISIT_HISTORY_ERROR:
      return {
        ...state,
        error: true,
      };
    case actions.EMR_VISIT_HISTORY_DETAILS_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_VISIT_HISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        error: false,
        visitDetails: action.payload,
      };

    case actions.EMR_VISIT_HISTORY_DETAILS_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.EMR_VISIT_FUTURE_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_VISIT_FUTURE_SUCCESS:
      return {
        ...state,
        error: false,
        futureVisits: action.payload,
      };

    case actions.EMR_VISIT_FUTURE_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.EMR_VISIT_FUTURE_CANCEL_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_VISIT_FUTURE_CANCEL_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.EMR_VISIT_FUTURE_CANCEL_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.EMR_ANALYSIS_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_ANALYSIS_SUCCESS:
      return {
        ...state,
        error: false,
        analysis: action.payload,
      };

    case actions.EMR_ANALYSIS_ERROR:
      return {
        ...state,
        error: true,
      };
    case actions.EMR_CLEAN_ALL:
      return {
        ...state,
        error: false,
        historyVisits: [],
        futureVisits: [],
        analysis: [],
        visitDetails: {},
      };

    default:
      return { ...state };
  }
}
