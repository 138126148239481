import AxiosClient from '../../../../../functional/Axios/AxiosClient';

const apiFilesPath = '/users/storage';

export const FilesApi = {
  getUserFiles: () => AxiosClient.get(apiFilesPath),
  deleteFile: (alias) => AxiosClient.delete(`${apiFilesPath}/${alias}`),
  shareFile: (alias) => AxiosClient.put(`${apiFilesPath}/${alias}/share`),
  unshareFile: (alias) => AxiosClient.put(`${apiFilesPath}/${alias}/unshare`),
  downloadFile: (alias) =>
    AxiosClient.get(`${apiFilesPath}/${alias}`, { responseType: 'blob' }),
  uploadFiles: (formData) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return AxiosClient.post(apiFilesPath, formData, config);
  },
};
