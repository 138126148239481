import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LocalLoader from '../../../containers/UI/LocalLoader';
import './WidgetsGrid.css';
import { getWidgets } from './store/widgets.actions';
import { hideModal, loadModal, showModal } from '../Modal/actions/ModalActions';
import { getNews, newsSetPage } from '../../News/store/news.actions';
import { getAllClinicChatsByAdmin } from '../../root/Chats/store/rootChats.actions';
import { getDoctorProfiles } from '../../Doctor/store/doctors.actions';
import WidgetsGridContainer from './Widgets/WidgetsGridContainer/WidgetsGridContainer';

const WidgetsGrid = (props) => {
  const { getWidgets } = props;
  useEffect(() => {
    getWidgets();
  }, []);

  return (
    <Suspense fallback={<LocalLoader/>}>
      <WidgetsGridContainer {...props}/>
    </Suspense>
  );
};

const mapStateToProps = (store) => ({
  widgets: store.widgets.elements,
  appconfig: store.appconfig,
  account: store.account,
  news: store.news,
  chats: store.root.chats,
  doctors: store.doctors.elements,
});

const mapDispatchToProps = {
  getWidgets,
  hideModal,
  loadModal,
  showModal,
  getNews,
  newsSetPage,
  getAllClinicChatsByAdmin,
  getDoctorProfiles,
};

WidgetsGrid.propTypes = {
  widgets: PropTypes.array.isRequired,
  appconfig: PropTypes.object,
  account: PropTypes.object,
  news: PropTypes.object,
  chats: PropTypes.object,
  doctors: PropTypes.array,
  getWidgets: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  loadModal: PropTypes.func,
  showModal: PropTypes.func,
  getNews: PropTypes.func,
  newsSetPage: PropTypes.func,
  getAllClinicChatsByAdmin: PropTypes.func,
  getDoctorProfiles: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsGrid);
