import React, { useEffect, useRef } from 'react';
import './PreviewImageFile.scss';
import Button from '../../../../../containers/UI/Buttons/Button';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import downloadBlob from '../../../../../utils/downloadBlob';
import { ALLOWED_PREVIEW_MIMES } from '../../../types/ALLOWED_MIMES';

export const PreviewImageFile = ({ data, close, filename, mime}) => {
  const imageRef = useRef(null);
  const previewEnable = ALLOWED_PREVIEW_MIMES.indexOf(mime) !== -1
  useEffect(() => {
    if (previewEnable) {
      imageRef.current.src = window.URL.createObjectURL(new Blob([data]));
    }
  }, []);

  return (
    <div className="img-thumbnail">
      <div className="text-right">
        <Button className="btn-sm btn-link text-dark mb-1" onClick={() => downloadBlob(data, filename)}>
          <FontAwesomeIcon icon={faSave}/>
        </Button>
        <Button className="btn-sm btn-link text-dark mb-1" onClick={close}>
          <FontAwesomeIcon icon={faTimesCircle}/>
        </Button>
      </div>
      <div className="text-center pb-4">
        {previewEnable && <Image ref={imageRef} width="50" height="50" rounded className="w-50 h-50" alt='Предпросмотр файла'/>}
        {!previewEnable && <div className="p-2">Предварительный просмотр файла невозможен</div>}
      </div>
    </div>
  );
};
