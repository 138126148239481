import { actions } from './headerReducer';

export function userMenuToggle() {
  return {
    type: actions.TOGGLE_MENU,
  };
}

export function userNotificationsToggle() {
  return {
    type: actions.TOGGLE_NOTIFICATIONS,
  };
}

export function userMenuHide() {
  return {
    type: actions.HIDE_MENU,
  };
}

export function userMenuShow() {
  return {
    type: actions.SHOW_MENU,
  };
}

export function userProfilesToggle() {
  return {
    type: actions.TOGGLE_USER_PROFILES,
  };
}

export function userLogout() {
  return {
    type: actions.LOGOUT_MENU,
  };
}

export function showBell() {
  return {
    type: actions.SHOW_BELL,
  };
}

export function hideBell() {
  return {
    type: actions.HIDE_BELL,
  };
}
