import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Chat.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { confirmAlert } from 'react-confirm-alert';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { isEmpty, throttle as _throttle } from 'lodash';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/cjs/Container';
import Row from 'react-bootstrap/cjs/Row';
import Card from 'react-bootstrap/cjs/Card';
import AxiosClient from '../../../../functional/Axios/AxiosClient';
import { SocketManager } from '../../../../ioc/socketManager';
import { getChat, initChat, updateChatMessages } from './chatRoomActions';
import LocalLoader from '../../../../containers/UI/LocalLoader';
import { ACCESS_ROLES } from '../../../../functional/roles';
import { hideSidePanel, loadSidePanel } from '../../../UI/SidePanel/actions/sidePanel.actions';
import { loadModal, hideModal } from '../../../UI/Modal/actions/ModalActions';
import SendTaskModal from '../../../UI/Modal/Modals/SendTask/SendTaskModal';
import { ChatMessage } from './components/ChatMessage/ChatMessage';
import randomBytes from '../../../../functional/randomBytes';
import ChatFiles from '../ChatFiles/ChatFiles';
import VisitHistory from '../../../User/components/UserEmr/components/VisitHistory/VisitHistory';
import UserAnalysis from '../../../User/components/UserEmr/components/UserAnalysis/UserAnalysis';
import ChatParticipants from '../ChatParticipants/ChatParicipants';
import { MESSAGE_EVENT } from '../../types/MESSAGE_EVENT';
import { CHAT_EVENTS } from '../../types/CHAT_EVENTS';
import { SOCKET_EVENT_TYPE } from '../../../../ioc/types/SOCKET_EVENT_TYPE';
import VideoChat from './components/VideoChat/VideoChat';
import ChatEmrDataControls from './components/ChatEmrDataControls/ChatEmrDataControls';
import { downloadChatFile } from '../../utils/downloadChatFile';
import { RSS_REGEX } from '../../../../const/rssRegex';
import Chip from '@material-ui/core/Chip';

const flashingText = ({ id, text }, timeout = 1500) => {
  const statusContainer = document.getElementById(id);
  let called = false;

  if (statusContainer) {
    if (called) return false;

    statusContainer.innerText = text;
    setTimeout(() => {
      statusContainer.innerText = '';
      called = true;
    }, timeout);
  }
};

function z() {
  const animatedTextStatus = {
    id: 'online-status',
    text: 'Пользователь печатает...',
  };
  flashingText(animatedTextStatus);
}
const debouncedTypingHandler = _throttle(z, 1550);

class ChatRoom extends Component {
  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();

    this.state = {
      chatId: '',
      loading: true,
      error: false,
      uploading: false,

      videoChat: false,
      webrtcStatus: 0,
      peerToken: null,
      peerServer: null,
      host: false,
      filesList: [],
      sideBar: false,
      userInfo: {},
      parseUrl: true,
    };

    this.peer = null;
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    this.setState({ error: true });
  }

  componentDidMount() {
    const id = window.location.pathname.split('/')[2];
    this.props.initChat(id);
    this.setState({ chatId: id });
    if(!this.props.chat.fetch) this.scrollDown();

    SocketManager.joinChannel(id);

    SocketManager.pushEvent('_onConnectEvents', { fn: SocketManager.joinChannel, args: id, type: SOCKET_EVENT_TYPE.SOCKET });
    SocketManager.pushEvent('_onDisconnectEvents', { fn: SocketManager.leaveChannel, args: id, type: SOCKET_EVENT_TYPE.SOCKET });

    const updateMessageEvent = {
      action: CHAT_EVENTS.CHAT_WITH_DOCTOR.UPDATE_MESSAGES,
      listener: (data) => {
        this.props.updateChatMessages(data);
        this.scrollDown();
      },
    };

    const userJoinEvent = {
      action: CHAT_EVENTS.CHAT_WITH_DOCTOR.USER_JOIN,
      listener: () => {
        const animatedTextStatus = {
          id: 'online-status',
          text: 'Пользователь присоединился',
        };
        flashingText(animatedTextStatus);
      },
    };

    const userLeftEvent = {
      action: CHAT_EVENTS.CHAT_WITH_DOCTOR.USER_LEFT,
      listener: () => {
        const animatedTextStatus = {
          id: 'online-status',
          text: 'Пользователь покинул чат',
        };
        flashingText(animatedTextStatus);
      },
    };

    const onChatBeenClosed = {
      action: CHAT_EVENTS.CHAT_WITH_DOCTOR.CLOSE_CHAT,
      listener: () => {
        window.location.reload();
      },
    };

    const onFileUploaded = {
      action: CHAT_EVENTS.CHAT_WITH_DOCTOR.FILE_UPLOADED,
      listener: (messageData) => {
        SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_DOCTOR.MESSAGE, messageData);
        this.props.updateChatMessages(messageData);
        setTimeout(() => this.scrollDown(), 50);
      },
    };

    const userTypingEvent = { action: CHAT_EVENTS.CHAT_WITH_DOCTOR.USER_TYPING, listener: debouncedTypingHandler };

    SocketManager.registerWsEvents([updateMessageEvent, userJoinEvent, userLeftEvent, userTypingEvent, onChatBeenClosed, onFileUploaded]);

    document.addEventListener('keydown', this.keyPressListener);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollDown();
  }

  //TODO: нахуя тут вообще реф удалить его к хуям
  keyPressListener = (e) => {
    if (e.keyCode === 13) {
      this.textareaRef.current.value = `${this.textareaRef.current.value}\n`;
    }

    if (e.keyCode === 13 && e.ctrlKey) {
      this.sendMessage();
    }
  }

  sendMessage = (ev) => {
    if (ev) ev.preventDefault();

    const message = this.textareaRef.current.value.trim().replace(RSS_REGEX, '');

    if (message.length < 1) return;

    const messageData = {
      chatId: this.state.chatId,
      author: this.props.account.user.id,
      text: message,
      hash: `${this.state.chatId}+${randomBytes()}`,
      status: 0,
      type: 'text',
    };

    SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_DOCTOR.MESSAGE, messageData);
    this.props.updateChatMessages(messageData);
    this.clearMessageForm();

    setTimeout(() => this.scrollDown(), 50);
  }

  clearMessageForm = () => {
    this.textareaRef.current.value = '';
  }

  userTyping = () => {
    SocketManager.ws.emit('WS:CHAT_USER_TYPING', this.state.chatId);
  }

  componentWillUnmount() {
    SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_DOCTOR.USER_LEFT, this.state.chatId);
    SocketManager.removeWsEvents(CHAT_EVENTS.CHAT_WITH_DOCTOR);
    SocketManager.leaveChannel(this.state.chatId);
    document.removeEventListener('keydown', this.keyPressListener);
  }

  toggleShowVisits = () => {
    this.props.loadSidePanel({
      component: VisitHistory,
      args: {
        profileId: this.props.chat.claim.user.currentProfile?.profileId,
      },
    });
  };

  toggleShowAnalysis = () => {
    this.props.loadSidePanel({
      component: UserAnalysis,
      args: {
        profileId: this.props.chat.claim.user.currentProfile?.profileId,
      },
    });
  };

  toggleModal = () => {
    this.props.loadModal({
      component: SendTaskModal,
      args: {
        userId: this.props.chat.claim.user._id,
      },
    });
  };

  scrollDown = (hard = false) => {
    const k = document.getElementsByClassName('messages-container')[0];

    if (k) {
      if (hard) {
        k.scrollTop = 999999;
      } else {
        k.lastChild.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  };

  endChat = () => {
    confirmAlert({
      title: 'Закрыть чат',
      message: 'Вы уверены что хотите закончить диалог и завершить консультацию?',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            AxiosClient.post(`/chats/archive/${this.state.chatId}`)
              .then(() => SocketManager.ws.emit(CHAT_EVENTS.CHAT_WITH_DOCTOR.CLOSE_CHAT, this.state.chatId))
              .finally(() => {
                window.location.reload();
              });
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  downloadFile = downloadChatFile.bind(this)

  render() {
    const {
      messages, fetch, claim, error,
    } = this.props.chat;

    if (error) return <Container>Чата не существует</Container>;

    const isDisabled = claim?.status === 8;
    const userRole = this.props.account.user.role;

    if (this.state.error) {
      return <div className="card bg-danger"> Произошла ошибка. Перезагрузите
        страницу.</div>;
    }

    const owner = `${this.props.account.user.firstName} ${this.props.account.user.surName}`;
    const isMessagesExists = !isEmpty(messages);

    return (
      <Container>
        <Row>
          <div className="col-12 mb-2">
            {isDisabled
            && <div className="bg-dark text-light p-2">
              <FontAwesomeIcon icon={faQuestionCircle}/>&nbsp;
              Это архивный чат. Вы не можете писать сообщения и отправлять файлы, но можете
              просматривать историю и прикрепленные файлы.</div>}
          </div>
          <div className="col-12 col-lg-8">
            <Card className="card">
              <Card.Body className="card-body chat">
                <div className="card-title">
                  <div className="row chat-container">
                    {claim?._id && <div className="col-12 d-flex justify-content-between">
                    </div>}
                  </div>
                  {
                    !isDisabled && <VideoChat webrtc={{ id: this.state.chatId }}/>
                  }
                  <div>
                    <small><span style={{ transition: 'all ease .2s' }} id="online-status"/>&nbsp;</small>
                  </div>
                  <hr/>
                </div>
                <div className="messages-container">
                  {
                    fetch && <LocalLoader/>
                  }
                  {
                    isMessagesExists && messages.map((message) => <ChatMessage
                    message={message}
                    key={message._id}
                    owner={owner}
                    socketEvent={MESSAGE_EVENT.CHAT}
                    downloadFile={this.downloadFile}/>)
                  }
                  {
                    (!isMessagesExists && !fetch) && <small className="text-black-50">История сообщений пуста</small>
                  }
                </div>
              </Card.Body>
              {
                !isDisabled && <div className="card-footer">
                <div className="message-input">
                  <textarea
                    placeholder="Сообщение"
                    ref={this.textareaRef}
                    onKeyPress={() => this.userTyping()}
                    className="form-control"
                  />
                </div>
                <br/>
                <div className="chat-controls d-flex justify-content-between">
                  <div className="chat-controls__control">
                    {(userRole === +ACCESS_ROLES.DOCTOR || userRole === +ACCESS_ROLES.CLINIC)
                      && <button onClick={this.endChat} className="btn btn-custom btn-secondary">
                      Завершить услугу <FontAwesomeIcon icon={faBan}/>
                    </button>
                    }
                  </div>
                  <div className="chat-controls__control">
                    <button onClick={this.sendMessage}
                            className="btn btn-success">Отправить <FontAwesomeIcon
                      icon={faPaperPlane}/></button>
                  </div>
                </div>
                <div className="chat-controls d-flex justify-content-end">
                  <span className="text-muted">
                    <small>
                       Ctrl+Enter
                    </small>
                  </span>
                </div>
              </div>
              }
            </Card>
          </div>
          <div className="col-12 col-lg-4 mt-4 mt-lg-0">
            <ChatParticipants chat={this.props.chat}/>
            <Chip className="mt-2" label={claim?.service?.title} variant="outlined" size={'small'}/>
            {
              claim && <ChatEmrDataControls
                allowAccess={claim?.user?.currentProfile?.allowEmrAccess}
                userRole={userRole}
                toggleShowVisits={this.toggleShowVisits.bind(this)}
                toggleShowAnalysis={this.toggleShowAnalysis.bind(this)}
                toggleModal={this.toggleModal.bind(this)}
              />
            }
            <ChatFiles
              loadModal={this.props.loadModal}
              hideModal={this.props.hideModal}
              files={this.state.filesList}
              isDisabled={isDisabled}
              chatId={this.state.chatId}
              socketEvents={CHAT_EVENTS.CHAT_WITH_DOCTOR}
            />
          </div>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
  chat: store.chat.chatRoom,
});

const mapDispatchToProps = {
  getChat,
  initChat,
  updateChatMessages,
  loadModal,
  hideModal,
  hideSidePanel,
  loadSidePanel
};

ChatRoom.propTypes = {
  hideSidePanel: PropTypes.func,
  loadSidePanel: PropTypes.func,
  loadModal: PropTypes.func,
  hideModal: PropTypes.func,
  initChat: PropTypes.func,
  updateChatMessages: PropTypes.func,
  chat: PropTypes.objectOf(PropTypes.any),
  account: PropTypes.object,
  currentChat: PropTypes.objectOf(PropTypes.any),
  events: PropTypes.oneOf([Object]),
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
