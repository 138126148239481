import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons/faNotesMedical';
import _isEmpty from 'lodash/isEmpty';
import { hideLoader, showLoader } from '../../../../../UI/Loader/store/loaderActions';
import UserAnalysisList from './UserAnalysisList/UserAnalysisList';
import { getUserAnalysis } from '../../store/userEmr.actions';
import Filters from '../Filters';
import NoContent from '../../../../../UI/NoContent/NoContent';

const UserAnalysis = (props) => {
  const {
    getUserAnalysis, files, profileId,
  } = props;

  useEffect(() => {
    getUserAnalysis({ profileId });
  }, [getUserAnalysis]);

  useEffect(() => {
    setFilesList(files);
  }, [files]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filesList, setFilesList] = useState(null);

  const isListEmpty = _isEmpty(filesList);

  const onPeriodChange = (dates) => {
    setDateRange(dates);
    const [start, end] = dates;
    const filteredFiles = files.filter((file) => {
      const fileTime = new Date(file.dateOf).getTime();
      if (!fileTime || (!start && !end)) {
        return true;
      }
      return (
        (start ? start.getTime() <= fileTime : true)
        && (end ? end.getTime() >= fileTime : true)
      );
    });
    setFilesList(filteredFiles);
  };

  return (
    <div className="container">
      <h4 className="center ml-2 mr-2 text-center"><strong>Список выгруженных файлов пациента:</strong></h4>
      <Filters
        startDate={startDate}
        endDate={endDate}
        onPeriodChange={onPeriodChange}
      />
      {
        isListEmpty ? <NoContent icon={faNotesMedical} title={'История анализов пуста'}/> :
            <UserAnalysisList files={filesList} profileId={profileId}/>
      }
    </div>
  );
};

UserAnalysis.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  getUserAnalysis: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  account: store.account,
  files: store.components.userEmr.analysis,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
  getUserAnalysis,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAnalysis);
