export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  TEMPLATES_REQUEST: 'TEMPLATES:REQUEST',
  TEMPLATES_REQUEST_SUCCESS: 'TEMPLATES:REQUEST_SUCCESS',
  TEMPLATES_REQUEST_FAILED: 'TEMPLATES:REQUEST_FAILED',
  TEMPLATES_CREATE_REQUEST: 'TEMPLATES_CREATE:REQUEST',
  TEMPLATES_CREATE_SUCCESS: 'TEMPLATES_CREATE:REQUEST_SUCCESS',
  TEMPLATES_CREATE_FAILED: 'TEMPLATES_CREATE:REQUEST_FAILED',
  TEMPLATES_UPDATE_REQUEST: 'TEMPLATES_UPDATE:REQUEST',
  TEMPLATES_UPDATE_SUCCESS: 'TEMPLATES_UPDATE:REQUEST_SUCCESS',
  TEMPLATES_UPDATE_FAILED: 'TEMPLATES_UPDATE:REQUEST_FAILED',
  TEMPLATES_ADD_REPLACEMENT_REQUEST: 'TEMPLATES_ADD_REPLACEMENT:REQUEST',
  TEMPLATES_ADD_REPLACEMENT_SUCCESS: 'TEMPLATES_ADD_REPLACEMENT:REQUEST_SUCCESS',
  TEMPLATES_ADD_REPLACEMENT_FAILED: 'TEMPLATES_ADD_REPLACEMENT:REQUEST_FAILED',
};

function templatesAdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TEMPLATES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.TEMPLATES_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.TEMPLATES_CREATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.TEMPLATES_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.TEMPLATES_UPDATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.TEMPLATES_UPDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.TEMPLATES_ADD_REPLACEMENT_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.TEMPLATES_ADD_REPLACEMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default templatesAdminReducer;
