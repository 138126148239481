export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  REPLACEMENTS_REQUEST: 'REPLACEMENTS:REQUEST',
  REPLACEMENTS_REQUEST_SUCCESS: 'REPLACEMENTS:REQUEST_SUCCESS',
  REPLACEMENTS_REQUEST_FAILED: 'REPLACEMENTS:REQUEST_FAILED',
  REPLACEMENTS_CREATE_REQUEST: 'REPLACEMENTS_CREATE:REQUEST',
  REPLACEMENTS_CREATE_SUCCESS: 'REPLACEMENTS_CREATE:REQUEST_SUCCESS',
  REPLACEMENTS_CREATE_FAILED: 'REPLACEMENTS_CREATE:REQUEST_FAILED',
  REPLACEMENTS_UPDATE_REQUEST: 'REPLACEMENTS_UPDATE:REQUEST',
  REPLACEMENTS_UPDATE_SUCCESS: 'REPLACEMENTS_UPDATE:REQUEST_SUCCESS',
  REPLACEMENTS_UPDATE_FAILED: 'REPLACEMENTS_UPDATE:REQUEST_FAILED',
};

function replacementsAdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REPLACEMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.REPLACEMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.REPLACEMENTS_CREATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.REPLACEMENTS_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.REPLACEMENTS_UPDATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.REPLACEMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default replacementsAdminReducer;
