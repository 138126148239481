import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions } from './userSettings.reducer';
import { actions as modalActions } from '../../../../UI/Modal/store/modalReducer';
import {
  update2Fa,
  updateEmrAccess,
  updateUser,
  userChangePassword,
  codeVerification,
  uploadUserAvatar,
  updateUserProfile, updateUserNotifications,
} from '../api/userSettingsAPI';
import { actions as notifyActions } from '../../../../UI/Notificator/notificatorReducer';
import { actions as userActions } from '../../../../Auth/store/auth.reducer';
import { actions as authActions } from '../../../../Auth/store/auth.actions';

export function* userChangePasswordSaga(payload) {
  const { response, error } = yield call(userChangePassword, { ...payload });
  if (response) {
    yield put({
      type: actions.USERSETTINGS_PASSWORDCHANGE_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Пароль успешно обновлен',
    });
    yield put({
      type: userActions.USER_REQUEST,
    });
  } else {
    yield put({
      type: actions.USERSETTINGS_PASSWORDCHANGE_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка обновления пароля',
    });
  }
}

export function* userUpdate2FaSaga(payload) {
  const { response, error } = yield call(update2Fa, { ...payload });
  if (response) {
    yield put({
      type: actions.USER_UPDATE_2FA_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Настройки успешно изменены',
    });
    yield put({
      type: userActions.USER_REQUEST,
    });
  } else {
    yield put({
      type: actions.USER_UPDATE_2FA_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export function* userUpdateSaga(payload) {
  const { response, error } = yield call(updateUser, { ...payload });
  if (response) {
    yield put({
      type: actions.USER_UPDATE_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Информация успешно обновлена',
    });
    yield put({
      type: userActions.USER_REQUEST,
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  } else {
    yield put({
      type: actions.USER_UPDATE_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export function* userUpdateEmrSaga(payload) {
  const { response, error } = yield call(updateEmrAccess, { ...payload });
  if (response) {
    yield put({
      type: actions.USER_UPDATE_EMR_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Настройки успешно изменены',
    });
    yield put({
      type: userActions.USER_REQUEST,
    });
  } else {
    yield put({
      type: actions.USER_UPDATE_EMR_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export function* verifyCode() {
  const response = yield call(codeVerification);
  if (response.result.status === 200) {
    yield put({
      type: actions.USER_VERIFY_CODE_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Код успешно выслан',
    });
  } else {
    yield put({
      type: actions.USER_VERIFY_CODE_ERROR,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export function* uploadAvatarSaga(payload) {
  try {
    const { response, error } = yield call(uploadUserAvatar, { ...payload });
    if (response.status === 200) {
      yield put({
        type: actions.USER_UPLOAD_AVATAR_SUCCESS,
        payload: response,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Фотография успешно обновлена',
      });
      yield put({
        type: userActions.USER_REQUEST,
      });
    }
  } catch (error) {
    yield put({
      type: actions.USER_UPLOAD_AVATAR_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export function* updateUserSaga(action) {
  try {
    const { profileId, fields, field } = action.payload;

    const response = yield call(updateUserProfile, profileId, fields);

    if (response.status === 200) {
      yield put({
        type: authActions.USER_REQUEST,
        payload: {
          showLoader: false
        }
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Информация успешно обновлена',
      });
      yield put({
        type: actions.UPDATE_USER_PROFILE_SUCCESS,
        payload: { field, fields },
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: actions.UPDATE_USER_PROFILE_ERROR,
      payload: {
        result: error,
        field: action.payload.field,
      },
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка обновления данных',
    });
  }
}

export function* userUpdateNotificationsSaga(action) {
  const data = action.payload;
  const response = yield call(updateUserNotifications, { ...data });
  if (response.status === 200) {
    yield put({
      type: actions.USER_UPDATE_NOTIFICATIONS_SUCCESS,
    });
    yield put({
      type: userActions.USER_REQUEST,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Изменения успешно сохранены',
    });
  } else {
    yield put({
      type: actions.USER_UPDATE_NOTIFICATIONS_ERROR,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export const registerUserSettingsSagas = [
  takeLatest(
    [actions.USERSETTINGS_PASSWORDCHANGE_REQUEST],
    userChangePasswordSaga,
  ),
  takeEvery([actions.USER_UPDATE_REQUEST], userUpdateSaga),
  takeEvery([actions.USER_UPDATE_EMR_REQUEST], userUpdateEmrSaga),
  takeEvery([actions.USER_UPDATE_2FA_REQUEST], userUpdate2FaSaga),
  takeEvery([actions.USER_VERIFY_CODE_REQUEST], verifyCode),
  takeEvery([actions.USER_UPLOAD_AVATAR_REQUEST], uploadAvatarSaga),
  takeEvery([actions.UPDATE_USER_PROFILE], updateUserSaga),
  takeEvery([actions.USER_UPDATE_NOTIFICATIONS_REQUEST], userUpdateNotificationsSaga),
];
