export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  WIDGETS_REQUEST: 'WIDGETS:REQUEST',
  WIDGETS_REQUEST_SUCCESS: 'WIDGETS:REQUEST_SUCCESS',
  WIDGETS_REQUEST_FAILED: 'WIDGETS:REQUEST_FAILED',
  WIDGETS_CREATE_REQUEST: 'WIDGETS_CREATE:REQUEST',
  WIDGETS_CREATE_SUCCESS: 'WIDGETS_CREATE:REQUEST_SUCCESS',
  WIDGETS_CREATE_FAILED: 'WIDGETS_CREATE:REQUEST_FAILED',
  WIDGETS_UPDATE_REQUEST: 'WIDGETS_UPDATE:REQUEST',
  WIDGETS_UPDATE_SUCCESS: 'WIDGETS_UPDATE:REQUEST_SUCCESS',
  WIDGETS_UPDATE_FAILED: 'WIDGETS_UPDATE:REQUEST_FAILED',
};

function widgetsAdminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.WIDGETS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.WIDGETS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.WIDGETS_CREATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.WIDGETS_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.WIDGETS_UPDATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.WIDGETS_UPDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default widgetsAdminReducer;
