import withPagination from '../../../store/utils/mutators/withPagination';
import withQuery from '../../../store/utils/mutators/withQuery';
import withFetch from '../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: null,
  _elements: null, // cache
  filter: [],
  fetch: false,
  error: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  CLAIMS_BY_USER_REQUEST: 'CLAIMS_BY_USER:REQUEST',
  CLAIMS_BY_USER_REQUEST_SUCCESS: 'CLAIMS_BY_USER:REQUEST_SUCCESS',
  CLAIMS_BY_USER_REQUEST_FAILED: 'CLAIMS_BY_USER:REQUEST_FAILED',

  CLAIMS_BY_USER_RESET_FILTER: 'CLAIMS:RESET_FILTER',

  CLAIMS_BY_USER_CHANGE_PAGE: 'CLAIMS_BY_USER:CHANGE_PAGE',
  CLAIMS_BY_USER_CHANGE_PAGE_SUCCESS: 'CLAIMS_BY_USER:CHANGE_PAGE_SUCCESS',
  CLAIMS_BY_USER_CHANGE_PAGE_FAILED: 'CLAIMS_BY_USER:CHANGE_PAGE_FAILED',
  CLAIMS_BY_USER_CHANGE_PAGE_RESET: 'CLAIMS_BY_USER:CHANGE_PAGE_RESET',

  CLAIM_CREATE_REQUEST: 'CLAIM_CREATE:REQUEST',
  CLAIM_CREATE_REQUEST_SUCCESS: 'CLAIM_CREATE:REQUEST_SUCCESS',
  CLAIM_CREATE_REQUEST_FAILED: 'CLAIM_CREATE:REQUEST_FAILED',
};

export function claimsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLAIMS_BY_USER_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.CLAIMS_BY_USER_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.claims,
        _elements: action.payload.claims,
        fetch: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.CLAIMS_BY_USER_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
        elements: [],
        _elements: [],
      };

    case actions.CLAIMS_BY_USER_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.CLAIMS_BY_USER_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.CLAIMS_BY_USER_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.CLAIMS_BY_USER_RESET_FILTER:
      return {
        ...state,
        elements: state._claims,
        count: state._claims?.length,
      };

    case actions.CLAIM_CREATE_REQUEST:
      return {
        ...state,
      };

    case actions.CLAIM_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.CLAIM_CREATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    default:
      return { ...state };
  }
}

export default claimsReducer;
