import React from 'react';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';

const ClaimItemComment = (props) => {
  const { appconfig: { appSettings }, claim } = props;
  return (
    <div className="col-12">
      <div>
        <b>Комментарий пациента:</b>
        {
          appSettings.enableClaimsRenderHtml && <div
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(claim?.comment?.replace(/\n/g, '<br/>')) }}/>
        }
        {
          !appSettings.enableClaimsRenderHtml && <div className="text-break">{claim.comment}</div>
        }
      </div>
    </div>
  );
};

ClaimItemComment.propTypes = {
  appconfig: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
};

export default ClaimItemComment;
