import React from 'react';
import PropTypes from 'prop-types';
import AdminSelect from '../../../UI/AdminSelect/AdminSelect';
import DoctorsModal from '../../../root/Doctor/DoctorsModal/DoctorsModal';

const ClaimItemDoctorAdminSelects = (props) => {
  const {
    claim, admins, addAdminToClaim, docs, handlePopup, fetch,
  } = props;
  return (
    <div className="d-sm-flex flex-column col-12 justify-content-end">
      <DoctorsModal
        docs={docs}
        claimId={claim._id}
        close={handlePopup}
        defaultValue={claim.doctor}
        fetch={fetch}
      />
      <div className="mt-2">
        <AdminSelect admins={admins} el={claim} addAdmin={addAdminToClaim}/>
      </div>
    </div>
  );
};

ClaimItemDoctorAdminSelects.propTypes = {
  claim: PropTypes.object.isRequired,
  admins: PropTypes.array.isRequired,
  addAdminToClaim: PropTypes.func.isRequired,
  docs: PropTypes.array.isRequired,
  handlePopup: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default ClaimItemDoctorAdminSelects;
