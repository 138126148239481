import { actions } from './doctors.reducer';

export function getDoctorProfiles() {
  return {
    type: actions.DOCTORS_ASPROFILES_REQUEST,
  };
}

export function updateDoctorProfiles(obj) {
  return {
    type: actions.DOCTORS_ASPROFILES_UPDATE,
    payload: { ...obj },
  };
}

export function getDoctorInfo(obj) {
  return {
    type: actions.DOCTOR_GET_INFO_REQUEST,
    payload: { ...obj },
  };
}

export function getApiDoctorInfo(obj) {
  return {
    type: actions.DOCTOR_GET_API_INFO_REQUEST,
    payload: { ...obj },
  };
}

export function getDoctorsByDirection(obj) {
  return {
    type: actions.DOCTORS_GET_BY_DIRECTION_REQUEST,
    payload: { ...obj },
  };
}

export function getDoctorInfoById(obj) {
  return {
    type: actions.DOCTOR_GET_INFO_ID_REQUEST,
    payload: { ...obj },
  };
}

export function getDoctorsByBranch(obj) {
  return {
    type: actions.DOCTORS_GET_BY_BRANCH_REQUEST,
    payload: { ...obj },
  };
}

export function getDoctors(payload) {
  return {
    type: actions.DOCTORS_GET_REQUEST,
    payload,
  };
}

export function doctorsSetPage(page) {
  return {
    type: actions.DOCTORS_CHANGE_PAGE,
    payload: { page },
  };
}

export function doctorsResetPage() {
  return {
    type: actions.DOCTORS_CHANGE_PAGE_RESET,
  };
}
