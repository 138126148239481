import withPagination from '../../../store/utils/mutators/withPagination';
import withQuery from '../../../store/utils/mutators/withQuery';
import withFetch from '../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  _elements: [],
  fetching: false,
  filter: [],
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 4),
  ...withFetch(defaultState),
};

export const actions = {
  NEWS_LATEST_REQUEST: 'NEWS_LATEST:REQUEST',
  NEWS_LATEST_REQUEST_SUCCESS: 'NEWS_LATEST:REQUEST_SUCCESS',
  NEWS_LATEST_REQUEST_FAILED: 'NEWS_LATEST:REQUEST_FAILED',

  NEWS_REQUEST: 'NEWS:REQUEST',
  NEWS_REQUEST_SUCCESS: 'NEWS:REQUEST_SUCCESS',
  NEWS_REQUEST_FAILED: 'NEWS:REQUEST_FAILED',

  NEWS_REQUEST_PAGINATED: 'NEWS:REQUEST_PAGINATED',
  NEWS_REQUEST_PAGINATED_SUCCESS: 'NEWS:REQUEST_PAGINATED_SUCCESS',
  NEWS_REQUEST_PAGINATED_FAILED: 'NEWS:REQUEST_PAGINATED_FAILED',

  NEWS_CHANGE_PAGE: 'NEWS:CHANGE_PAGE',
  NEWS_CHANGE_PAGE_SUCCESS: 'NEWS:CHANGE_PAGE_SUCCESS',
  NEWS_CHANGE_PAGE_FAILED: 'NEWS:CHANGE_PAGE_FAILED',
  NEWS_CHANGE_PAGE_RESET: 'NEWS:CHANGE_PAGE_RESET',
};

export function newsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.NEWS_LATEST_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_LATEST_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.NEWS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.NEWS_REQUEST_PAGINATED:
      return {
        ...state,
        fetching: true,
        query: action.payload || '',
      };

    case actions.NEWS_REQUEST_PAGINATED_SUCCESS:
      return {
        ...state,
        elements: action.payload.news,
        _elements: action.payload.news,
        fetching: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.NEWS_REQUEST_PAGINATED_FAILED:
      return {
        ...state,
        fetching: false,
        elements: [],
        _elements: [],
      };

    case actions.NEWS_CHANGE_PAGE:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.NEWS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.NEWS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}

export default newsReducer;
