import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { SocketManager } from '../../../../ioc/socketManager';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ACCESS_ROLES } from '../../../../functional/roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ChatParticipants extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pStatus: {}, // participants status
    };
    this.updateTimer = null;
  }

  componentDidMount() {
    SocketManager.ws.on('WS:COMMON:UPDATE_USER_STATUS', async (data) => {
      this.setState({
        pStatus: {
          ...this.state.pStatus,
          [data.id]: data.online,
        },
      });
    });

    this.updateTimer = setInterval(() => {
      if (this.props?.chat?.participants) {
        const participantsIds = this.props.chat.participants.map((p) => p._id);

        if (participantsIds && participantsIds.length > 0) {
          participantsIds.forEach((id) => {
            SocketManager.ws.emit('WS:COMMON:GET_USER_STATUS', id);
          });
        }
      }
    }, 5000);
  }

  userRenderer = (user) => {
    const { role } = this.props?.account?.user
    if(role === +ACCESS_ROLES.USER) {
      return <>{user.currentProfile.firstName} {user.currentProfile.surName}</>
    }
    if(role === +ACCESS_ROLES.ROOT || role === +ACCESS_ROLES.ADMIN) {
      return  <Link to={`/root/users-management/${user._id}`}>{user.currentProfile.firstName} {user.currentProfile.surName}</Link>
    }
    if(role === +ACCESS_ROLES.DOCTOR) {
      return  <Link to={`/patients/${user._id}`}>{user.currentProfile.firstName} {user.currentProfile.surName}</Link>
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  render() {
    const { pStatus: status } = this.state;
    const { chat: { participants } } = this.props;
    return (
      <div className="card p-2">
        <div className="font-weight-bold">
          Участники
        </div>
        {participants && participants.map((p) => (
          <div key={p._id} className="d-flex justify-content-start mt-2">
            {
              <Avatar
                className="mr-2"
                alt={`${p?.currentProfile?.firstName}
                 ${p?.currentProfile?.lastName}`}
                src={`/avatars/${p?.currentProfile?.avatar}`}
              />
            }
            <small key={p.currentProfile.firstName} className="pt-2 pl-2 justify-content-center">
              {this.userRenderer(p)}&nbsp;
              {status[p._id] ? <FontAwesomeIcon className="ml-2" size={'xs'} icon={faCircle} color={'green'}/> : <FontAwesomeIcon className="ml-2" size={'xs'} icon={faCircle} color={'red'}/>}
            </small>
          </div>))}
      </div>);
  }
}

const mapStateToProps = (store) => ({
  chat: store.chat.chatRoom,
  account: store.account,
});

export default connect(mapStateToProps, {})(ChatParticipants);
