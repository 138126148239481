import { actions } from './rightsManager.reducer';

export function getUserRights(obj) {
  return {
    type: actions.USER_RIGHTS_REQUEST,
    payload: { ...obj },
  };
}

export function updateUserRights(obj) {
  return {
    type: actions.USER_RIGHTS_UPDATE_REQUEST,
    payload: { ...obj },
  };
}

export function checkUserRights(history) {
  return {
    type: actions.USER_CHECK_RIGHTS_REQUEST,
    payload: history,
  };
}
