import withQuery from '../../../store/utils/mutators/withQuery';
import withPagination from '../../../store/utils/mutators/withPagination';
import withFetch from '../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  _elements: null, // cache
  filter: [],
  fetching: false,
  error: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  REVIEWS_USER_REQUEST: 'REVIEWS_USER:REQUEST',
  REVIEWS_USER_REQUEST_SUCCESS: 'REVIEWS_USER:REQUEST_SUCCESS',
  REVIEWS_USER_REQUEST_FAILED: 'REVIEWS_USER:REQUEST_FAILED',

  REVIEWS_BY_USER_CHANGE_PAGE: 'REVIEWS_BY_USER:CHANGE_PAGE',
  REVIEWS_BY_USER_CHANGE_PAGE_SUCCESS: 'REVIEWS_BY_USER:CHANGE_PAGE_SUCCESS',
  REVIEWS_BY_USER_CHANGE_PAGE_FAILED: 'REVIEWS_BY_USER:CHANGE_PAGE_FAILED',
  REVIEWS_BY_USER_CHANGE_PAGE_RESET: 'REVIEWS_BY_USER:CHANGE_PAGE_RESET',

  REVIEWS_CREATE_REQUEST: 'REVIEWS_CREATE:REQUEST',
  REVIEWS_CREATE_REQUEST_SUCCESS: 'REVIEWS_CREATE:REQUEST_SUCCESS',
  REVIEWS_CREATE_REQUEST_FAILED: 'REVIEWS_CREATE:REQUEST_FAILED',

  REVIEWS_UPDATE_REQUEST: 'REVIEWS_UPDATE:REQUEST',
  REVIEWS_UPDATE_REQUEST_SUCCESS: 'REVIEWS_UPDATE:REQUEST_SUCCESS',
  REVIEWS_UPDATE_REQUEST_FAILED: 'REVIEWS_UPDATE:REQUEST_FAILED',
};

export function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REVIEWS_USER_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.payload || '',
      };

    case actions.REVIEWS_USER_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.reviews,
        _elements: action.payload.reviews,
        fetching: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.REVIEWS_USER_REQUEST_FAILED:
      return {
        ...state,
        elements: [],
        _elements: [],
        fetching: false,
      };

    case actions.REVIEWS_CREATE_REQUEST:
      return {
        ...state,
      };

    case actions.REVIEWS_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.REVIEWS_CREATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    case actions.REVIEWS_UPDATE_REQUEST:
      return {
        ...state,
      };

    case actions.REVIEWS_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.REVIEWS_UPDATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    case actions.REVIEWS_BY_USER_CHANGE_PAGE:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.REVIEWS_BY_USER_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.REVIEWS_BY_USER_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}

export default reviewsReducer;
