import AxiosClient from '../../../functional/Axios/AxiosClient';

export function getDirections() {
  return AxiosClient.get('/root/directions')
    .then((data) => data)
    .catch((error) => error);
}

export function getDirectionDaysById({ ...payload }) {
  const {
    directionId, startDate,
  } = payload.payload;
  return AxiosClient.post('/schedule/avdays/directionId', { directionId, startDate })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
