import { put } from 'redux-saga/effects';
import { actions } from '../../../Auth/store/auth.reducer';

export function* userLogout() {
  try {
    sessionStorage.clear();
    yield put({ type: 'SHOW_LOADER' });
    yield put({ type: actions.WIPE_SESSION });
    yield put({ type: 'HIDE_LOADER' });
    window.location.reload();
  } catch (error) {
    console.log(error);
  } finally {
    yield put({ type: 'HIDE_LOADER' });
  }
}
