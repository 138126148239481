export const CHAT_EVENTS = {
  CHAT_WITH_DOCTOR: {
    UPDATE_MESSAGES: 'WS:CHAT_UPDATE_MESSAGES',
    USER_JOIN: 'WS:USER_JOIN',
    USER_LEFT: 'WS:USER_LEFT_CHAT',
    USER_TYPING: 'WS:CHAT_USER_TYPING',
    MESSAGE: 'WS:CHAT_MESSAGE',
    CLOSE_CHAT: 'WS:CHAT_CLOSE',
    GET_FILES: 'WS:CHAT_GET_FILES',
    UPLOAD_FILE: 'WS:CHAT_UPLOAD_FILE',
    FILE_UPLOADED: 'WS:CHAT_FILE_UPLOADED',
    CHAT_UPDATED: 'WS:CHAT_UPDATE',
  },
  CHAT_WITH_CLINIC: {
    UPDATE_MESSAGES: 'WS:CLINIC_CHAT_UPDATE_MESSAGES',
    USER_JOIN: 'WS:CLINIC_USER_JOIN',
    USER_LEFT: 'WS:CLINIC_USER_LEFT_CHAT',
    USER_TYPING: 'WS:CLINIC_CHAT_USER_TYPING',
    MESSAGE: 'WS:CLINIC_CHAT_MESSAGE',
    CLOSE_CHAT: 'WS:CLINIC_CHAT_CLOSE',
    GET_FILES: 'WS:CLINIC_CHAT_GET_FILES',
    UPLOAD_FILE: 'WS:CLINIC_CHAT_UPLOAD_FILE',
    FILE_UPLOADED: 'WS:CLINIC_CHAT_FILE_UPLOADED',
    CHAT_UPDATED: 'WS:CLINIC_CHAT_UPDATE',
  },
};
