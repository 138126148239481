import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../containers/UI/Buttons/Button';
import { getGlobalConfig } from '../root/AppConfig/store/appconfigActions';
import {
  resetPassword,
  updatePasswordViaEmail,
} from './store/ResetPassword.actions';
import { InputBase } from '../../containers/Inputs/InputBase/inputBase';

/**
 * Component ResetPassword
 * The reset password page for set new password
 *
 * @param {Object} props - component props
 *
 * @returns {JSX.Element}
 */
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      password: '',
      message: '',
    };
  }

  componentDidMount() {
    this.props.getAppConfig();
    this.props.resetPassword(this.props.match.params.token);
  }

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.updatePasswordViaEmail(
      this.props.resetpassword.email,
      this.state.password,
      () => this.props.history.push('/auth'),
    );
  };

  render() {
    const { password, error } = this.state;

    const errorContainer = (
      <div className="container">
        <div className="row">
          <div className="col-12">Ошибка, попробуйте еще раз</div>
        </div>
      </div>
    );

    return error ? (
      errorContainer
    ) : (
      <div className="container">
        <div className="row pt-5">
          <div className="col-6 offset-3">
            <form className="form-restore" onSubmit={this.onSubmit}>
              <h2 className="form-restore-heading">Восстановление пароля</h2>
              <div className="row">
                <div className="col-12">Введите новый пароль</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <InputBase
                      type="password"
                      className="form-control"
                      placeholder="Пароль *"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <p>
                <small className="text-muted">
                  Поля, отмеченные * обязательны для заполнения
                </small>
              </p>
              <Button className="btn-firm-filled w-100" type="submit">
                Восстановить пароль
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  getAppConfig: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  resetPassword: PropTypes.func.isRequired,
  updatePasswordViaEmail: PropTypes.func.isRequired,
  resetpassword: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  appconfig: store.appconfig,
  resetpassword: store.resetpassword,
});

const mapDispatchToProps = {
  getAppConfig: getGlobalConfig,
  resetPassword,
  updatePasswordViaEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
