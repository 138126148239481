import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import * as PropTypes from 'prop-types';

const ClaimItemDoctorError = ({ title }) => (<div style={{
  lineHeight: '.85',
  fontSize: '80%',
  fontWeight: '400',
}}>
    <Tooltip
      title="Врач удален, не активен по причине отпуска или недоступен. Нужно переназначить врача">
      <div><FontAwesomeIcon icon={faExclamationTriangle} className="__color-red"/> {title}</div>
    </Tooltip>
  </div>
);

ClaimItemDoctorError.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ClaimItemDoctorError;
