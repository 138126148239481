import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { getClaimsByUser, claimsByUserSetPage, claimsByUserResetPage } from '../../../Claim/store/claims.actions';
import { hideModal, loadModal } from '../../../UI/Modal/actions/ModalActions';
import SideBarTab from '../../../../containers/UI/SideBarTabs/SideBarTab';
import Button from '../../../../containers/UI/Buttons/Button';
import SideBarComponent from '../../../../containers/UI/SideBarTabs/SideBarComponent';
import SideBar from '../../../../containers/UI/SideBarTabs/SideBar';
import { getDoctorProfiles } from '../../../Doctor/store/doctors.actions';
import ClaimsContainer from '../../../Claim/ClaimsContainer/ClaimsContainer';
import cloudPaymentsDataCreator from '../../../../utils/cloudPaymentsDataCreator';
import { validateTransaction, updateTransaction } from '../../../Transactions/store/transactionsActions';
import { CLAIM_STATUSES } from '../../../Claim/const/constants';

class UserClaimsNew extends PureComponent {
  componentDidMount() {
    this.props.getDoctorProfiles();
    this.props.getClaimsByUser();
    const ref = document.createElement('script');
    ref.setAttribute('type', 'text/javascript');
    ref.setAttribute('src', 'https://widget.cloudpayments.ru/bundles/cloudpayments');
    document.getElementsByTagName('head')[0].appendChild(ref);
  }

  handlePayment = (transactionId, status) => {
    this.props.updateTransaction({ transactionId, status });
  }

  getService = (transactionId) => {
    this.props.validateTransaction(transactionId);
  };

  /**
   * Pass transaction id to payment
   * @param transactionId string
   * @param sum
   */
  startPayment = async (transactionId, sum) => {
    const { user } = this.props.account;
    const dataCreatorArgs = {transactionId, sum, ...this.props}
    const data = await cloudPaymentsDataCreator(dataCreatorArgs);

    console.log(data)

    if (window.cp !== undefined) {
      const pay = () => {
        const widget = new window.cp.CloudPayments();
        widget.charge({
          publicId: this.props.appconfig?.clinicSettings?.financial?.cpkey,
          description: 'Оплата онлайн консультации',
          currency: 'RUB',
          amount: sum,
          invoiceId: transactionId,
          accountId: user.email,
          skin: 'mini',
          data,
        },
        (options) => { // success
          this.handlePayment(transactionId, 7);
        },
        (reason, options) => { // fail
          this.handlePayment(transactionId, 0);
        });
      };
      pay();
    }
  }

  handleChangePage = (e) => {
    let value;

    if (e) {
      value = e.currentTarget.textContent;
      this.props.claimsByUserSetPage(+value - 1);
    }
  };

  fetchClaimsAndResetPage = (status) => {
    if (status && status.length !== 0) this.props.getClaimsByUser({ status });
    this.props.claimsByUserResetPage();
  };

  _claimsContainer = () => <ClaimsContainer
        {...this.props}
        handleChangePage={this.handleChangePage.bind(this)}
        fetch={this.props.getClaimsByUser}
        startPayment={this.startPayment.bind(this)}
        confirmPayment={this.handlePayment.bind(this)}
        getService={this.getService.bind(this)}
    />

  render() {
    return (
      <div className="container">
        <SideBar changeTab={() => {
        }} defaultTab={0}>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
          }, { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Все заявки
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage()}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.IN_PROCESS }],
          },
          { fn: this.props.claimsByUserResetPage },
          ]}>
            <div>
              В обработке
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage()}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.SET_TO_DOCTOR }],
          },
          { fn: this.props.claimsByUserResetPage },
          ]}>
            <div>
              Назначена врачу
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(3)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.AWAIT_PAYMENT }],
          },
          { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Ожидает оплаты
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(5)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.PAID }],
          },
          { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Оплачена
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(7)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.DONE }],
          },
          { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Услуга оказана
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(8)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.CANCELLED_BY_USER }],
          },
          { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Отказ от услуги
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(9)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: this.props.getClaimsByUser,
            args: [{ status: CLAIM_STATUSES.DELETED }],
          },
          { fn: this.props.claimsByUserResetPage }]}>
            <div>
              Отказ клиники
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(2)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={this.props.fetch}>
            {this._claimsContainer()}
          </SideBarComponent>
        </SideBar>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  ...store.claims,
  account: store.account,
  appconfig: store.appconfig,
  doctors: store.doctors.elements,
});

const mapDispatchToProps = {
  getClaimsByUser,
  hideModal,
  loadModal,
  claimsByUserSetPage,
  claimsByUserResetPage,
  getDoctorProfiles,
  validateTransaction,
  updateTransaction,
};

UserClaimsNew.propTypes = {
  elements: PropTypes.array,
  fetch: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object.isRequired,
  doctors: PropTypes.array.isRequired,
  getClaimsByUser: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loadModal: PropTypes.func.isRequired,
  claimsByUserSetPage: PropTypes.func.isRequired,
  claimsByUserResetPage: PropTypes.func.isRequired,
  getDoctorProfiles: PropTypes.func.isRequired,
  validateTransaction: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserClaimsNew);
