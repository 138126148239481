import {
  call, put, select,
  takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions as archiveChatsActions } from './archiveChats.reducer';
import { actions as activeChatsActions } from './activeChats.reducer';

import { chatsApi } from '../../api/chats.api';
import SessionStorageCrypt from '../../../../functional/SessionStorageCrypt';
import { querystring } from '../../../../store/utils/formatters/querystring';

export function* getUserChatsSaga() {
  try {
    const pagination = yield select((state) => state.chat.chats.activeChats.pagination);
    const query = yield select((state) => state.chat.chats.activeChats.query);
    const role = yield select((state) => state.account.user.role);
    const q = { ...pagination, ...query };

    let response;
    if (role === 98 || role === 99) {
      response = yield call(chatsApi.fetchClinicChats, { query: querystring(q) });
    } else {
      response = yield call(chatsApi.fetchChats, { query: querystring(q) });
    }

    response.data.chats.forEach((chat) => {
      if (chat?.messages?.length > 0) {
        chat.hasNewMessages = true;
      }
    });
    SessionStorageCrypt.setItem('Chats_Cache', response.data);

    yield put({
      type: activeChatsActions.CHATS_GET_SUCCESS,
      payload: { ...response.data },
    });
  } catch (error) {
    yield put({ type: activeChatsActions.CHATS_GET_FAILED });
  }
}

export function* getUserArchiveChatsSaga() {
  try {
    const pagination = yield select((state) => state.chat.chats.archiveChats.pagination);

    const query = yield select((state) => state.chat.chats.archiveChats.query);
    const role = yield select((state) => state.account.user.role);
    const q = { ...pagination, ...query };

    let response;
    if (role === 98 || role === 99) {
      response = yield call(chatsApi.fetchClinicChats, { query: querystring(q) });
    } else {
      response = yield call(chatsApi.fetchArchiveChats, { query: querystring(q) });
    }

    SessionStorageCrypt.setItem('Archive_Chats_Cache', response.data);

    yield put({
      type: archiveChatsActions.ARCHIVE_CHATS_GET_SUCCESS,
      payload: { ...response.data },
    });
  } catch (error) {
    yield put({ type: archiveChatsActions.ARCHIVE_CHATS_GET_FAILED });
  }
}

export function* getUserChatSaga(action) {
  const { payload } = action;

  try {
    const role = yield select((state) => state.account.user.role);
    let response;
    if (role === 98 || role === 99) {
      response = yield call(chatsApi.fetchClinicChat, payload.chatId);
    } else {
      response = yield call(chatsApi.fetchChat, payload.chatId);
    }

    yield put({
      type: activeChatsActions.CHAT_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: activeChatsActions.CHAT_GET_FAILED });
  }
}

export const registerChatsSagas = [
  takeLatest([
    activeChatsActions.CHATS_GET_REQUEST,
    activeChatsActions.CHATS_CHANGE_PAGE,
    activeChatsActions.CHATS_FILTER,
    activeChatsActions.CHATS_FILTER_RESET], getUserChatsSaga),
  takeEvery([activeChatsActions.CHAT_UPDATE_REQUEST], getUserChatSaga),
  takeEvery([archiveChatsActions.ARCHIVE_CHATS_GET_REQUEST, archiveChatsActions.ARCHIVE_CHATS_CHANGE_PAGE], getUserArchiveChatsSaga),
];
