import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { usersApi } from './api/usersApi';
import { actions } from './users.reducer';
import { querystring } from '../../../../store/utils/formatters/querystring';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { actions as sidePanelActions } from '../../../UI/SidePanel/store/sidePanel.reducer';

export function* getAllUsersAdmin(action) {
  try {
    const pagination = yield select((state) => state.root.users.pagination);
    const query = yield select((state) => state.root.users.query);

    const q = { ...pagination, ...query };

    const response = yield call(usersApi.getAllUsers, { query: querystring(q) });

    yield put({
      type: actions.USERS_ADMIN_REQUEST_SUCCESS,
      payload: { data: response.data, query },
    });
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_REQUEST_FAILED });
  }
}

export function* getAllUsersSaga(action) {
  try {
    const response = yield call(usersApi.getAllUsersNoPagination, action.payload);
    yield put({
      type: actions.USERS_ADMIN_NO_PAGINATION_REQUEST_SUCCESS,
      payload: { data: response.data },
    });
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_NO_PAGINATION_REQUEST_FAILED });
  }
}

export function* addUserSaga(action) {
  try {
    const response = yield call(usersApi.addUser, action.payload);
    if (response) {
      yield put({
        type: actions.USERS_ADMIN_ADD_USER_SUCCESS,
      });

      yield put({
        type: actions.USERS_ADMIN_REQUEST,
      });

      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });

      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Пользователь успешно добавлен',
      });
    }
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_ADD_USER_FAILED });

    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });

    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка добавления пользователя',
    });
  }
}

export function* changeRoleUser(action) {
  try {
    const response = yield call(usersApi.changeUserRole, action.payload);
    if (response) {
      yield put({
        type: actions.USERS_ADMIN_CHANGE_ROLE_USER_SUCCESS,
      });

      yield put({
        type: actions.USERS_ADMIN_REQUEST,
      });

      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Роль успешно изменена',
      });
    }
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_CHANGE_ROLE_USER_FAILED });

    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка добавления пользователя',
    });
  }
}

export function* deleteUser(action) {
  try {
    const response = yield call(usersApi.deleteUser, action.payload);
    if (response) {
      yield put({
        type: actions.USERS_ADMIN_DELETE_USER_SUCCESS,
      });

      yield put({
        type: actions.USERS_ADMIN_REQUEST,
      });

      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Пользователь успешно удален',
      });
    }
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_DELETE_USER_FAILED });

    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка удаления пользователя',
    });
  }
}

export function* getUserInfoSaga(action) {
  try {
    const response = yield call(usersApi.getUserData, action.payload);
    if (response) {
      yield put({ type: 'SHOW_LOADER' });

      yield put({
        type: actions.USERS_ADMIN_GET_USER_SUCCESS,
        payload: response.data,
      });
    }

    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.USERS_ADMIN_GET_USER_FAILED });

    yield put({ type: 'HIDE_LOADER' });

    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка загрузки данных',
    });
  }
}

export const registerUsersSagas = [
  takeLatest(
    [
      actions.USERS_ADMIN_REQUEST,
      actions.USERS_ADMIN_CHANGE_PAGE,
    ],
    getAllUsersAdmin,
  ),
  takeEvery([actions.USERS_ADMIN_NO_PAGINATION_REQUEST], getAllUsersSaga),
  takeEvery([actions.USERS_ADMIN_ADD_USER], addUserSaga),
  takeEvery([actions.USERS_ADMIN_CHANGE_ROLE_USER], changeRoleUser),
  takeEvery([actions.USERS_ADMIN_DELETE_USER], deleteUser),
  takeEvery([actions.USERS_ADMIN_GET_USER], getUserInfoSaga),
];
