import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
    Sentry.captureException(err);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header/>
          <div className="container">
            <div className="row justify-content-center">
                  <h3>Что-то пошло не так...🆘</h3>
                  <div>
                    Попробуйте <span className="text-primary cursor-pointer"
                                     onClick={() => window.location.reload()}>перезагрузить </span>страницу или выйти и войти снова.
                    Если ошибка будет повторяться, пожалуйста, <a href="mailto:support@qheal.ru">напишите нам</a>
                  </div>
                </div>
            </div>
          <Footer/>
        </>

      );
    }
    return this.props.children;
  }
}
