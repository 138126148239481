import { call, put, takeEvery } from 'redux-saga/effects';
import { faqAdminApi } from '../api';
import { actions } from './faqAdmin.reducer';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { actions as sidePanelActions } from '../../../UI/SidePanel/store/sidePanel.reducer';

export function* getAllFaqTopics() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(faqAdminApi.getFaqTopics);
    yield put({
      type: actions.FAQ_ALL_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.FAQ_ALL_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* addFaqTopic(payload) {
  try {
    const { response } = yield call(faqAdminApi.addFaqTopic, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_CREATE_TOPIC_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно добавлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_CREATE_TOPIC_FAILED,
      payload: error,
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* addFaqArticle(payload) {
  try {
    const { response } = yield call(faqAdminApi.addFaqArticleToTopic, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_CREATE_ARTICLE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно добавлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_CREATE_ARTICLE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* updateFaqArticle(payload) {
  try {
    const { response } = yield call(faqAdminApi.updateFaqArticleInTopic, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_UPDATE_ARTICLE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно обновлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_UPDATE_ARTICLE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* updateFaqTopic(payload) {
  try {
    const { response } = yield call(faqAdminApi.updateFaqTopic, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_UPDATE_TOPIC_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно обновлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_UPDATE_TOPIC_FAILED,
      payload: error,
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* deleteFaqTopic(payload) {
  try {
    const { response } = yield call(faqAdminApi.deleteFaqTopic, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_DELETE_TOPIC_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно удален',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_DELETE_TOPIC_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* deleteFaqArticle(payload) {
  try {
    const { response } = yield call(faqAdminApi.deleteFaqArticle, { ...payload });
    if (response) {
      yield put({
        type: actions.FAQ_DELETE_ARTICLE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.FAQ_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Элемент успешно удален',
      });
    }
  } catch (error) {
    yield put({
      type: actions.FAQ_DELETE_ARTICLE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export const registerAdminFaqSagas = [
  takeEvery([actions.FAQ_ALL_REQUEST], getAllFaqTopics),
  takeEvery([actions.FAQ_CREATE_TOPIC_REQUEST], addFaqTopic),
  takeEvery([actions.FAQ_UPDATE_TOPIC_REQUEST], updateFaqTopic),
  takeEvery([actions.FAQ_CREATE_ARTICLE_REQUEST], addFaqArticle),
  takeEvery([actions.FAQ_UPDATE_ARTICLE_REQUEST], updateFaqArticle),
  takeEvery([actions.FAQ_DELETE_TOPIC_REQUEST], deleteFaqTopic),
  takeEvery([actions.FAQ_DELETE_ARTICLE_REQUEST], deleteFaqArticle),
];
