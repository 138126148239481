import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const usersApi = {
  getAllUsers: ({ query }) => AxiosClient.get(`/root/users?${query}`),

  getAllUsersNoPagination: (data) => AxiosClient.get(`/root/users/query`, {params: {data}}),

  getAllDoctorsNoPagination: () => AxiosClient.get('/users/doctors'),

  addUser: (data) => AxiosClient.post('/root/users/', data),

  changeUserRole: (data) => {
    const { userId, role } = data;
    return AxiosClient.post(`/root/users/${userId}/role`, { role });
  },

  deleteUser: (id) => AxiosClient.delete(`/root/users/${id}`),

  getUserData: async (id) => AxiosClient.get(`/root/users/${id}`),
};
