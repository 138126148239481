export const defaultState = {
  fetch: false,
  error: false,
};

const initialState = {
  ...defaultState,
};

export const actions = {
  SETTINGS_SAVE_REQUEST: 'SETTINGS_SAVE:REQUEST',
  SETTINGS_SAVE_REQUEST_SUCCESS: 'SETTINGS_SAVE:REQUEST_SUCCESS',
  SETTINGS_SAVE_REQUEST_FAILED: 'SETTINGS_SAVE:REQUEST_FAILED',
};

function settingsRootReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SETTINGS_SAVE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.SETTINGS_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.SETTINGS_SAVE_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
        error: true,
      };

    default:
      return { ...state };
  }
}

export default settingsRootReducer;
