import React from 'react';
import VisitListItem from '../VisitListItem/VisitListItem';

const VisitHistoryList = (props) => (
  <div className="list-group mt-4">
    {
      props.visits
        && <div>
          {
            props.visits.map((elem, index) => (
              <div key={`${elem.emrRecId}_${index}`}>
                <VisitListItem
                  visit={elem}
                  profileId={props.profileId}
                />
              </div>
            ))
          }
        </div>
    }
  </div>
);

export default VisitHistoryList;
