import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const ClaimItemAdmins = (props) => {
  const { claim, account, deleteAdmin } = props;
  return (
    <>
      {
        (account.user.role !== 1 && claim.pinnedTo && claim.pinnedTo.length !== 0) && <div className="mt-2">
          {
            claim.pinnedTo.map((elem) => (
                <Chip key={claim._id}
                      label={`${elem.currentProfile?.surName} ${elem.currentProfile?.firstName}`}
                      onDelete={() => deleteAdmin({ id: elem._id, claimId: claim._id })}
                      variant="outlined"
                      size={'small'}
                />
            ))
          }
        </div>
      }
    </>
  );
};

ClaimItemAdmins.propTypes = {
  account: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  deleteAdmin: PropTypes.func.isRequired,
};

export default ClaimItemAdmins;
