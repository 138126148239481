import { call, put, takeEvery } from 'redux-saga/effects';
import { newsAdminApi } from '../api';
import { actions } from './newsAdmin.reducer';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';

export function* getAllNewsAdmin() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(newsAdminApi.getNews);
    yield put({
      type: actions.NEWS_ALL_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.NEWS_ALL_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* updateNewsItemSaga(payload) {
  try {
    const { response } = yield call(newsAdminApi.updateNewsItem, { ...payload });
    if (response) {
      yield put({
        type: actions.NEWS_UPDATE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.NEWS_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Новость успешно обновлена',
      });
    }
  } catch (error) {
    yield put({
      type: actions.NEWS_UPDATE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* addNewsItemSaga(payload) {
  try {
    const { response } = yield call(newsAdminApi.addNewsItem, { ...payload });
    if (response) {
      yield put({
        type: actions.NEWS_CREATE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.NEWS_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Новость успешно добавлена',
      });
    }
  } catch (error) {
    yield put({
      type: actions.NEWS_CREATE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* deleteNewsItemSaga(payload) {
  try {
    const { response } = yield call(newsAdminApi.deleteNewsItem, { ...payload });
    if (response) {
      yield put({
        type: actions.NEWS_DELETE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.NEWS_ALL_REQUEST,
        payload: response.data,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Новость успешно удалена',
      });
    }
  } catch (error) {
    yield put({
      type: actions.NEWS_DELETE_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export const registerAdminNewsSagas = [
  takeEvery([actions.NEWS_ALL_REQUEST], getAllNewsAdmin),
  takeEvery([actions.NEWS_CREATE_REQUEST], addNewsItemSaga),
  takeEvery([actions.NEWS_UPDATE_REQUEST], updateNewsItemSaga),
  takeEvery([actions.NEWS_DELETE_REQUEST], deleteNewsItemSaga),
];
