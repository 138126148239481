import React, { Component } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { SocketManager } from '../../../../../../ioc/socketManager';
import { ChatMessageAuthor } from './ChatMessageAuthor';
import { ChatMessageText } from './ChatMessageText';
import { ChatMessageInfo } from './ChatMessageInfo';
import { ChatMessageContainer } from './ChatMessageContainer';
import { ChatMessageTimestamp } from './ChatMessageTimestamp';
import { ChatMessageStatus } from './ChatMessageStatus';
import { MESSAGE_EVENT } from '../../../../types/MESSAGE_EVENT';

export class ChatMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 0,
      _createdAt: new Date(),
    };
    this.wsEvent = '';
  }

  componentDidMount() {
    const {
      message: {
        status, hash,
      },
      socketEvent,
    } = this.props;

    this.setState({ status });

    if (parseInt(status, 10) !== 3) {
      this.updateTimer = setTimeout(() => SocketManager.ws.emit(socketEvent, hash), 1500);
    }
    if (SocketManager.ws) {
      const updateMessageStatus = {
        action: `WS:UPDATE_MESSAGE_STATUS#${hash}`,
        listener: (res) => {
          this.setState({ status: res.status, createdAt: res.createdAt });
        },
      };
      this.wsEvent = updateMessageStatus.action;
      SocketManager.registerWsEvent(updateMessageStatus);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimer);
    SocketManager.removeWsEvents([this.wsEvent]);
  }

  render() {
    const {
      message: {
        author, text, type, createdAt, files,
      }, owner, downloadFile,
    } = this.props;

    const { status, _createdAt } = this.state;

    const isAuthorDefined = Object.prototype.hasOwnProperty.call(author, 'currentProfile');
    const _author = isAuthorDefined
      ? `${author.currentProfile.firstName} ${author.currentProfile.surName}`
      : owner;
    const className = owner !== _author ? '__light-warning' : '__success';
    return (
      <div style={{
        whiteSpace: 'pre-wrap',
        borderRadius: '4px',
      }} className={`${className} p-1 mt-1`}>
        <ChatMessageAuthor author={_author}/>
        <ChatMessageContainer>
          { type === 'text' && <ChatMessageText text={text}/>}
          { type === 'file' && <>
            <ChatMessageText text={text}/>
            { files && <div>{ files.map((file) => <small key={file._id} onClick={downloadFile} data-alias={file.alias} data-filename={file.filename}>
              { file.filename } <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faFileDownload}/>
            </small>) }
            </div>}
          </>}
        </ChatMessageContainer>
        <ChatMessageInfo>
          <ChatMessageTimestamp timestamp={createdAt || _createdAt}/>
          {owner === _author && <ChatMessageStatus status={status}/>}
        </ChatMessageInfo>
      </div>
    );
  }
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    author: oneOfType([
      PropTypes.string,
      PropTypes.shape({
        currentProfile: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          surName: PropTypes.string,
        }),
      })]).isRequired,
    text: PropTypes.string.isRequired,
    status: PropTypes.number,
    hash: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
  }),
  socketEvent: PropTypes.oneOf([MESSAGE_EVENT.CHAT, MESSAGE_EVENT.CLINIC]).isRequired,
  owner: PropTypes.string,
  downloadFile: PropTypes.func.isRequired,
};
