import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

export const RegistrationUserAgreement = (props) => {
  const {
    personalDataAgreement, checkboxHandler, togglePolicyModal,
    ofertaAgreement, toggleOfertaModal
  } = props;
  return (
    <div className="col-12">
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={personalDataAgreement}
              onChange={checkboxHandler}
              name="personalDataAgreement"
            />
          }
          label={
            <Box component="div" fontSize={13} m={0} p={0}>
              Согласен с&nbsp;
              <a
                onClick={togglePolicyModal}
                className="policy register-modalOpener"
              >
                политикой конфиденциальности
              </a>{' '}
              *
            </Box>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={ofertaAgreement}
              onChange={checkboxHandler}
              name="ofertaAgreement"
            />
          }
          label={
            <Box component="div" fontSize={13} m={0}>
              Согласен с&nbsp;
              <a
                onClick={toggleOfertaModal}
                className="oferta register-modalOpener"
              >
                офертой о предоставлении услуг
              </a>{' '}
              *
            </Box>
          }
        />
      </FormGroup>
    </div>
  );
};

RegistrationUserAgreement.propTypes = {
  personalDataAgreement: PropTypes.bool.isRequired,
  checkboxHandler: PropTypes.func.isRequired,
  togglePolicyModal: PropTypes.func.isRequired,
  ofertaAgreement: PropTypes.bool.isRequired,
  toggleOfertaModal: PropTypes.func.isRequired,
};
