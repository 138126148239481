import React, { Component, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../helpers/PrivateRoute/PrivateRoute';
import Admin from './Admin/Admin';

import InDevelopment from '../../containers/UI/InDevelopment';

const ServicesManager = lazy(() => import('./Service/ServicesManager'));
const ServicesStatistics = lazy(() => import('./Service/ServicesStatistics'));
const UsersManager = lazy(() => import('./User/UsersManager'));
const UserInfo = lazy(() => import('./User/UserInfo/UserInfo'));
const DoctorsManager = lazy(() => import('./Doctor/DoctorsManager/DoctorsManager'));
const ClaimsManager = lazy(() => import('./Claim/components/ClaimsManager'));
const ClaimStatistics = lazy(() => import('./Claim/components/ClaimStatistics'));
const News = lazy(() => import('./News2.0/components/NewsContainer/NewsContainer'));
const Faq = lazy(() => import('./Faq/Faq'));
const CallDoctor = lazy(() => import('./Claim/components/CallDoctorAdminClaims/CallDoctorAdminClaims'));
const RequestVisit = lazy(() => import('./Claim/components/RequestVisitAdminClaims/RequestVisitAdminClaims'));
const PrepaidClaims = lazy(() => import('./Claim/components/PrepaidAdminClaims/PrepaidAdminClaims'));
const ClinicChatsManagement = lazy(() => import('./Chats/ClinicChatsManagement/ClinicChatsManagement'));
const DoctorsChatsManagement = lazy(() => import('./Chats/DoctorsChatsManagement/DoctorsChatsManagement'));
const PinnedToAdminChats = lazy(() => import('./Chats/PinnedToAdminChats/PinnedToAdminChats'));
const Reviews = lazy(() => import('./Reviews/ReviewsRootContainer/ReviewsRootContainer'));
const Reports = lazy(() => import('./Reports/Reports'));
const RootSettings = lazy(() => import('./RootSettings/RootSettings/RootSettings'));
const AdminInfo = lazy(() => import('./AdminInfo/AdminInfo'));
// TODO:: move this both to their modules
const DirectionsManagement = lazy(() => import('./Directions/DirectionsManagement/DirectionsManagement'));
const BranchesManagement = lazy(() => import('./Branches/BranchesManagement/BranchesManagement'));

/* todo:: move routing to each module */
class RootPageRouter extends Component {
  render() {
    return (
      <Switch>
            <Route exact path="/root" component={Admin}/>
            <PrivateRoute exact path="/root/settings" component={RootSettings}/>
            <PrivateRoute exact path="/root/info" component={AdminInfo}/>

            <PrivateRoute exact path="/root/users-management" component={UsersManager}/>
            <PrivateRoute exact path="/root/users-management/:id" component={UserInfo}/>

            <PrivateRoute exact path="/root/doctors-management" component={DoctorsManager}/>

            <PrivateRoute exact path="/root/claim-management" component={ClaimsManager}/>
            <PrivateRoute exact path="/root/claim-statistics" component={ClaimStatistics}/>

            <PrivateRoute exact path="/root/news/news-management" component={News}/>

            <PrivateRoute exact path="/root/faq/faq-management" component={Faq}/>

            <PrivateRoute exact path="/root/services-statistics" component={ServicesStatistics}/>
            <PrivateRoute exact path="/root/services-management" component={ServicesManager}/>

            <PrivateRoute exact path="/root/chats/chats-management" component={DoctorsChatsManagement}/>
            <PrivateRoute exact path="/root/chats/clinic-chats-management" component={ClinicChatsManagement}/>
            <PrivateRoute exact path="/root/chats/clinic-chats-pinned-to-admin" component={PinnedToAdminChats}/>
            <PrivateRoute exact path="/root/chats/chats-statistics" component={InDevelopment}/>
            <PrivateRoute exact path="/root/chats/chats-settings" component={InDevelopment}/>

            <PrivateRoute exact path="/root/directions/directions-management" component={DirectionsManagement}/>
            <PrivateRoute exact path="/root/directions/directions-statistics" component={InDevelopment}/>
            <PrivateRoute exact path="/root/directions/directions-settings" component={InDevelopment}/>

            <PrivateRoute exact path="/root/branch/branch-management" component={BranchesManagement}/>
            <PrivateRoute exact path="/root/branch/branch-statistics" component={InDevelopment}/>
            <PrivateRoute exact path="/root/branch/branch-settings" component={InDevelopment}/>

            <PrivateRoute exact path="/root/claims/visit-request/management" component={RequestVisit}/>
            <PrivateRoute exact path="/root/claims/call-doctor/management" component={CallDoctor}/>
            <PrivateRoute exact path="/root/claims/prepaid/management" component={PrepaidClaims}/>

            <PrivateRoute exact path="/root/reports/claims/management" component={Reports}/>

            <PrivateRoute exact path="/root/reviews/management" component={Reviews}/>
      </Switch>
    );
  }
}

export default RootPageRouter;
