import { actions } from './appconfigReducer';

export function getGlobalConfig(obj) {
  return {
    type: actions.CONFIG_REQUEST,
    payload: { ...obj },
  };
}

export function getAdminAppConfig(obj) {
  return {
    type: actions.CONFIG_ADMIN_REQUEST,
    payload: { ...obj },
  };
}
