export const initialState = {
  visible: false,
  fetch: false,
  block: false
};

export const actions = {
  SHOW_LOADER: 'LOADER: SHOW',
  HIDE_LOADER: 'LOADER: HIDE',
  FETCH_INDICATOR_SHOW: 'LOADER: FETCH SHOW',
  FETCH_INDICATOR_HIDE: 'LOADER: FETCH HIDE',
};

export function loaderReducer(state = initialState, action) {
  switch (action.type) {
    // legacy
    case 'SHOW_LOADER':
      return {
        ...state,
        visible: true,
      };

    case 'HIDE_LOADER':

      return {
        ...state,
        visible: false,
      };
    // new
    case actions.SHOW_LOADER:
      return {
        ...state,
        visible: true,
        block: !!action?.payload
      };

    case actions.HIDE_LOADER:
      return {
        ...state,
        visible: false,
      };

    case actions.FETCH_INDICATOR_SHOW:
      return {
        ...state,
        fetch: true,
      };

    case actions.FETCH_INDICATOR_HIDE:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}
