import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

export const UserName = (props) => {
  const { user, link } = props;
  return (
        <div className="d-sm-flex mt-2">
            <div className="mr-2">
                <Avatar
                    alt={`${user?.currentProfile?.fullName}`}
                    src={`/avatars/${user?.currentProfile?.avatar}`}/>
            </div>
            {
                link &&
                <div className="pt-2">
                    <Link to={`/root/users-management/${user?._id}`}>
                        {user?.currentProfile?.fullName}
                    </Link>
                </div>
            }
            { !link && (<div className="pt-2">{user?.currentProfile?.fullName}</div>)}
        </div>
  );
};

UserName.propTypes = {
  user: PropTypes.object.isRequired,
  link: PropTypes.bool.isRequired,
};
