export const initialState = {
  menuVisible: false,
  changeProfileVisible: false,
  notificationsVisible: false,
  bellVisible: false,
};

export const actions = {
  TOGGLE_MENU: 'HEADER:USER_MENU_TOGGLE',
  TOGGLE_NOTIFICATIONS: 'HEADER:NOTIFICATIONS_TOGGLE',
  HIDE_MENU: 'HEADER:USER_MENU_HIDE',
  SHOW_MENU: 'HEADER:USER_MENU_SHOW',
  TOGGLE_USER_PROFILES: 'HEADER:USER_PROFILES_TOGGLE',
  LOGOUT_MENU: 'LOGOUT:MENU',
  SHOW_BELL: 'SHOW:BELL',
  HIDE_BELL: 'HIDE:BELL',
};

export function headerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TOGGLE_MENU:
      return {
        ...state,
        menuVisible: !state.menuVisible,
        changeProfileVisible: false,
      };

    case actions.TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        notificationsVisible: !state.notificationsVisible,
      };

    case actions.TOGGLE_USER_PROFILES:
      return {
        ...state,
        changeProfileVisible: !state.changeProfileVisible,
      };

    case actions.HIDE_MENU:
      return {
        ...state,
        menuVisible: false,
      };

    case actions.SHOW_MENU:
      return {
        ...state,
        menuVisible: true,
      };

    case actions.SHOW_BELL:
      return {
        ...state,
        bellVisible: true,
      };

    case actions.HIDE_BELL:
      return {
        ...state,
        bellVisible: false,
      };

    case actions.LOGOUT_MENU:
      return {
        ...state,
        notificationsVisible: false,
        changeProfileVisible: false,
        menuVisible: false,
        bellVisible: false,
      };

    default:
      return { ...state };
  }
}
