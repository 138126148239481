import {
  has, get, isNumber, isUndefined, isArray, invert, toString, indexOf, isString, toArray,
} from 'lodash';

export const ROLES = {
  1: 'USER',
  2: 'DOCTOR',
  3: 'ADMIN',
  98: 'CLINIC',
  99: 'ROOT',
};

export const ACCESS_ROLES = invert(ROLES);

//TODO: refactor roles checker - array of roles not working
const ACCESS_GROUPS = {
  ALL: toArray(ACCESS_ROLES),
  DOCTOR: [ACCESS_ROLES.DOCTOR],
  USER: [ACCESS_ROLES.USER],
  USER_AND_DOCTOR: [ACCESS_ROLES.USER, ACCESS_ROLES.DOCTOR],
  DOCTOR_AND_ADMIN: [ACCESS_ROLES.DOCTOR, ACCESS_ROLES.ADMIN],
  CLINIC: [ACCESS_ROLES.CLINIC],
  ADMINISTRATIVE: [ACCESS_ROLES.ADMIN, ACCESS_ROLES.ROOT],
  ROOT: [ACCESS_ROLES.ROOT],
  ADMIN: [ACCESS_ROLES.ADMIN],
};

export const GROUPS = {
  ALL: 'ALL',
  DOCTOR: 'DOCTOR',
  USER: 'USER',
  USER_AND_DOCTOR: 'USER_AND_DOCTOR',
  DOCTOR_AND_ADMIN: 'DOCTOR_AND_ADMIN',
  CLINIC: 'CLINIC',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
};

/**
 * Returns text representation of users role
 * @param int
 */
export function rolesMap(int) {
  if (isNumber(int)) return ROLES[int];
  throw new Error('Некорректная роль');
}

/**
 * Rbac for allowing presets when render something
 * @return {boolean}
 */
export function RBAC(user, accessGroup) {
  let role = get(user, 'user.role');

  if (!isNumber(role) || isUndefined(role)) {
    role = get(user, 'role');

    if (!isNumber(role) || isUndefined(role)) return false;
  }

  if (isArray(accessGroup)) {
    const access = indexOf(accessGroup, toString(role));
    if (access >= 0) return true;
  }

  if (isString(accessGroup)) {
    const roleExists = has(ACCESS_GROUPS, accessGroup);
    if (roleExists) {
      const access = indexOf(ACCESS_GROUPS[accessGroup], toString(role));
      if (access >= 0) return true;
    }
    return false;
  }

  return false;
}
