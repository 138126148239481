import io from 'socket.io-client';
import { toast } from 'react-toastify';
import store from '../store';
import {
  getNotifications,
  pushNotification,
  pushWebNotification,
} from '../components/Notifications/store/notificationsActions';
import SessionStorageCrypt from '../functional/SessionStorageCrypt';
import { renewToken, setOffline, setOnline } from '../components/Auth/store/auth.actions';
import { userLogout } from '../components/UI/Header/store/headerActions';
import { SOCKET_EVENT_TYPE } from './types/SOCKET_EVENT_TYPE';
import { SOCKET_EVENTS } from './types/SOCKET_EVENTS';
import {isLocalhost} from "../utils/isLocalhost";

class CSocketManager {
  _onConnectEvents = [
    { fn: setOnline, args: {}, type: SOCKET_EVENT_TYPE.REDUX },
    { fn: getNotifications, args: {}, type: SOCKET_EVENT_TYPE.REDUX },
  ];

  _onDisconnectEvents = [
    { fn: setOffline, args: {}, type: SOCKET_EVENT_TYPE.REDUX },
  ];

  _eventsList = [];

  constructor() {
    const unsubscribe = store.subscribe(() => {
      const isAuth = store.getState().account.auth.token;
      if (isAuth) {
        this.initSocket();
        this.listenEvents();
        unsubscribe();
      }
    });
  }

  initSocket() {
    this.ws = null;
    const socket = () => {
      if (isLocalhost()) {
        return io(`http://${window.location.hostname}:3000/`, { query: { token: SessionStorageCrypt.getItem('jwtToken') } });
      }
      return io(`https://${window.location.hostname}/`, { query: { token: SessionStorageCrypt.getItem('jwtToken') } });
    };

    this.ws = socket();
  }

  pushEvent(type, event) {
    this[type].push(event);
  }

  async registerConnectEvents() {
    this._onConnectEvents.forEach((event) => {
      if (event.type === SOCKET_EVENT_TYPE.REDUX) {
        store.dispatch(event.fn.call(null, event.args));
      }
      if (event.type === SOCKET_EVENT_TYPE.SOCKET) {
        event.fn.call(null, event.args);
      }
    });
  }

  async registerDisconnectEvents() {
    this._onDisconnectEvents.forEach((event) => {
      if (event.type === SOCKET_EVENT_TYPE.REDUX) {
        store.dispatch(event.fn.call(null, event.args));
      }
      if (event.type === SOCKET_EVENT_TYPE.SOCKET) {
        event.fn.call(null, event.args);
      }
    });
  }

  leaveChannel = (id) => {
    this.ws.emit(SOCKET_EVENTS.CHANNEL.LEAVE_CHANNEL, id);
  }

  joinChannel = (id) => {
    console.log('Joined to channel!');
    this.ws.emit(SOCKET_EVENTS.CHANNEL.JOIN_CHANNEL, id);
  }

  registerWsEvent(event) {
    if (this._eventsList.includes(event.action)) {
      delete this._eventsList[event.action];
    }

    this._eventsList.push(event.action);

    this.ws.on(event.action, event.listener);
  }

  registerWsEvents(events) {
    events.forEach((event) => {
      this.ws.on(event.action, event.listener);
    });
  }

  removeWsEvents(eventsArray) {
    const events = Object.values(eventsArray);
    if (events) {
      events.forEach((event) => {
        this.ws.removeAllListeners(event);
      });
    }
  }

  listenEvents() {
    this.interval = setInterval(() => {
      this.ws.emit('zigzag');
    }, 15000);

    this.ws.on('connect', async () => {
      await this.registerConnectEvents();
    });

    this.ws.on('disconnect', async () => {
      await this.registerDisconnectEvents();
    });

    this.ws.on('reconnecting', () => {
      store.dispatch(setOffline());
    });

    this.ws.on('disconnecting', () => {
      store.dispatch(setOffline());
    });

    // This one emits from the server while users receive online notifications only
    this.ws.on('WS:NOTIFICATION_SEND', (data) => {
      store.dispatch(pushWebNotification(data));
      store.dispatch(pushNotification(data));
    });

    this.ws.on('WS:LOGOUT', () => {
      toast.info('Сессия завершена');
      store.dispatch(userLogout());
    });

    this.ws.on('WS:RENEW_TOKEN', (accessToken) => {
      toast.info('Обновляем сессию...', { autoClose: 1500 });
      store.dispatch(renewToken(`Bearer ${accessToken}`));
      SessionStorageCrypt.setItem('jwtToken', `Bearer ${accessToken}`);
      store.dispatch(setOffline());
      this.ws.disconnect();
    });

    this.ws.on('reconnect_error', () => {
      store.dispatch(setOffline());
    });

    this.ws.onclose = () => {
      let i = 0;
      const limit = 20;

      const k = setTimeout(() => {
        if (i === limit) {
          clearTimeout(k);
        }
        i += i;

        clearInterval(this.interval);
        this.initSocket();
        this.listenEvents();
      }, 5000);
    };
  }
}

export const SocketManager = new CSocketManager();
