import React, { Component } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/cjs/Col';
import PropTypes from 'prop-types';
import { notifyError, notifySuccess } from '../../UI/Notificator/notificatorActions';
import {
  getDoctorServices, getAllClaims, claimSetPage, claimResetPage, claimsWipe,
} from '../../root/Claim/store/claims.actions';
import { hideModal, loadModal } from '../../UI/Modal/actions/ModalActions';
import LocalLoader from '../../../containers/UI/LocalLoader';
import SideBar from '../../../containers/UI/SideBarTabs/SideBar';
import SideBarTab from '../../../containers/UI/SideBarTabs/SideBarTab';
import SideBarComponent from '../../../containers/UI/SideBarTabs/SideBarComponent';
import UserSelectContainer from '../../root/User/UserSelectContainer/UserSelectContainer';
import SideBatPortal from '../../../containers/UI/SideBarTabs/SideBarPortal';
import { getDoctorProfile } from '../api/doctor.api';
import ClaimsContainer from '../../Claim/ClaimsContainer/ClaimsContainer';
import { CLAIM_STATUSES } from '../../Claim/const/constants';

class CallDoctorClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      doctorId: '',
    };
  }

  componentDidMount() {
    this.props.getDoctorServices();
    this.fetchDocClaims();
  }

  fetchDocClaims = async () => {
    await getDoctorProfile(this.props?.account?.user?.id)
      .then((doc) => {
        this.setState({ doctorId: doc?.data?._id });
        this.props.getAllClaims({ ...this.props.query, doctor: doc?.data?._id, claimType: 2 });
      });
  }

  handleChangePage = (e) => {
    let value;

    if (e) {
      value = e.currentTarget.textContent;
      this.props.claimSetPage(+value - 1);
    }
  };

  render() {
    const {
      fetching, fetch, getAllClaims, claimResetPage
    } = this.props;
    const { doctorId, activeTab } = this.state;
    if (fetching) return <div className="mt-5"><LocalLoader/></div>;
    return (
        <div className="container">
          <h1>Заявки на вызов врача</h1>
          <SideBar changeTab={this.tabChange} defaultTab={activeTab}>
            <SideBatPortal id="claim-sidebar-portal" className="w-100">
              <Col className="mt-2">
                <h6>Поиск по пользователю</h6>
                <UserSelectContainer
                  handleSelect={(id) => getAllClaims({
                    ...this.props.query, user: id, doctor: doctorId, claimType: 2
                  })}
                />
              </Col>
            </SideBatPortal>
            <SideBarTab sideEffects={[{
              fn: getAllClaims,
              args: [{ doctor: doctorId, claimType: 2 }],
            }, { fn: this.props.claimResetPage }]}>Все заявки</SideBarTab>
            <SideBarTab
              sideEffects={[{
                fn: getAllClaims,
                args: [{ doctor: doctorId, claimType: 2, status: CLAIM_STATUSES.PAID }],
              }, { fn: this.props.claimResetPage }]}>
              Оплаченные
            </SideBarTab>
            <SideBarTab
              sideEffects={[{
                fn: getAllClaims,
                args: [{ doctor: doctorId, claimType: 2, status: CLAIM_STATUSES.DONE }],
              }, { fn: claimResetPage }]}
              >Завершенные</SideBarTab>
            <SideBarComponent loading={fetch}>
              <ClaimsContainer
                {...this.props}
                handleChangePage={this.handleChangePage.bind(this)}
                fetch={this.fetchDocClaims.bind(this)}/>
            </SideBarComponent>
            <SideBarComponent loading={fetch}>
              <ClaimsContainer
                {...this.props}
                handleChangePage={this.handleChangePage.bind(this)}
                fetch={this.fetchDocClaims.bind(this)}/>
            </SideBarComponent>
            <SideBarComponent loading={fetch}>
              <ClaimsContainer
                {...this.props}
                handleChangePage={this.handleChangePage.bind(this)}
                fetch={this.fetchDocClaims.bind(this)}/>
            </SideBarComponent>
          </SideBar>
        </div>
    );
  }
}

const mapStateToProps = (store) => ({
  appconfig: store.appconfig,
  account: store.account,
  ...store.root.claims,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  getDoctorServices,
  getAllClaims,
  hideModal,
  loadModal,
  claimSetPage,
  claimResetPage,
  claimsWipe,
};

CallDoctorClaims.propTypes = {
  getDoctorServices: PropTypes.func.isRequired,
  getAllClaims: PropTypes.func.isRequired,
  claimSetPage: PropTypes.func.isRequired,
  claimResetPage: PropTypes.func.isRequired,
  claimsWipe: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loadModal: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallDoctorClaims);
