import { call, put, select } from 'redux-saga/effects';
import { faqGetAll } from '../api';
import { actions } from './faqReducer';

export function* faqGetAllSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const role = yield select((state) => state.account.user.role);
    const response = yield call(faqGetAll, role);
    yield put({
      type: actions.FAQ_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: actions.FAQ_REQUEST_FAILED });
  }
}
