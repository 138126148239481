import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { actions } from './reviews.reducer';
import { reviewsRootApi } from '../api';
import { querystring } from '../../../../store/utils/formatters/querystring';

export function* reviewsSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const pagination = yield select((state) => state.root.reviews.pagination);
    const query = yield select((state) => state.root.reviews.query);

    const q = { ...pagination, ...query };
    const response = yield call(reviewsRootApi.reviewsGet, { query: querystring(q) });
    yield put({
      type: actions.REVIEWS_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: actions.REVIEWS_REQUEST_FAILED });
  }
}

export const registerReviewsRootSagas = [
  takeLatest([
    actions.REVIEWS_REQUEST,
    actions.REVIEWS_CHANGE_PAGE,
  ], reviewsSaga),
];
