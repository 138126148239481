import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import { DateFormats } from '../../../const/dateFormats';
import NotificationItemFooter from './NotificationItemFooter/NotificationItemFooter';
import { NOTIFICATION_TYPES } from '../const/constants';

const NotificationItem = ({ notification, readHandler, showClinicChat }) => {
  const isAdmin = notification?.meta?.user?.role === 99 || notification?.meta?.user?.role === 3;
  const adminChatLinkCondition = (notification.type === NOTIFICATION_TYPES.NEW_MESSAGE_CLINIC && isAdmin) && notification?.meta?.chatId;
  const userChatLinkCondition = (notification.type !== NOTIFICATION_TYPES.NEW_MESSAGE_CLINIC && !isAdmin) && notification?.meta?.chatId;
  const userChatClinicCondition = !isAdmin && notification.type === NOTIFICATION_TYPES.NEW_MESSAGE_CLINIC;

  return (
      <div className={`${notification.status === 0 && 'notification'} card-body border-bottom`}>
          <div onClick={() => readHandler(notification._id)}>
              {
                  notification.status !== 99 &&
                  <button type="button" className="close" aria-label="Close"
                          title={notification.status === 0 ? 'Прочитано' : 'Удалить'}>
                      <span aria-hidden="true">&times;</span>
                  </button>
              }
              <p>
                  {notification.message}
              </p>
              <p>
                  {
                      adminChatLinkCondition &&
                      <small>
                          <Link to={`/chats/${notification?.meta?.chatId}?type=CHAT_WITH_CLINIC`}>Перейти в чат</Link>
                      </small>
                  }
              </p>
              <p>
                  {userChatLinkCondition && <small><Link to={`/chats/${notification?.meta?.chatId}`}>Перейти в чат</Link></small>}
              </p>
              <p>
                  {userChatClinicCondition && <small><Link onClick={showClinicChat}>Перейти в чат</Link></small>}
              </p>
              <div className="w-100 m-0 row">
                  <small className="flex-grow-1">
                      {notification.status === 0 && 'Не прочитано'}
                      {notification.status === 1 && 'Прочитано'}
                      {notification.status === 99 && 'История'}
                  </small>
                  <small>
                      {moment(notification.createdAt).format(DateFormats.DAY_MONTH_YEAR_TIME)}
                  </small>
              </div>
          </div>
          {notification.files && <NotificationItemFooter files={notification.files} />}
      </div>
  );
};
export default NotificationItem;
