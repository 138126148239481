import React from 'react';
import LocalLoader from '../LocalLoader';

const SideBarComponent = (props) => {
  if (!props.active) return null;

  if (typeof props.children === 'function') {
    return props.children();
  }
  if (props.loading) return <LocalLoader/>;
  return (<>{props.children}</>);
};
export default SideBarComponent;
