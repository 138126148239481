export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  BRANCHES_ALL_REQUEST: ' BRANCHES_ALL:REQUEST',
  BRANCHES_ALL_REQUEST_SUCCESS: ' BRANCHES_ALL:REQUEST_SUCCESS',
  BRANCHES_ALL_REQUEST_FAILED: ' BRANCHES_ALL:REQUEST_FAILED',
};

export function branchesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.BRANCHES_ALL_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.BRANCHES_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default branchesReducer;
