import AxiosClient from '../../../../../../functional/Axios/AxiosClient';

export const bookingApi = {

  bookVisit: (data) => AxiosClient.post('/schedule/bookvisit', data),

  approveVisit: (data) => AxiosClient.post('/schedule/approvevisit', data),

  getDays: async (data) => AxiosClient.post('/schedule/avdays', data),

  getSlots: async (data) => AxiosClient.post('/schedule/slots', data),
};
