import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { claimsApi } from './api/apiClaims';
import { actions } from './claims.reducer';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { querystring } from '../../../../store/utils/formatters/querystring';
import { actions as modalActions } from '../../../UI/Modal/store/modalReducer';

export function* getClaimSaga(action) {
  try {
    const pagination = yield select((state) => state.root.claims.pagination);
    const query = yield select((state) => state.root.claims.query);

    let q = { ...pagination, ...query };

    if (action?.payload?.status) {
      q = { ...q, status: action?.payload?.status };
    }

    const response = yield call(claimsApi.getAllClaims, { query: querystring(q) });

    yield put({
      type: actions.CLAIMS_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CLAIMS_REQUEST_FAILED });
  }
}

export function* getDoctorServices() {
  try {
    const id = yield select((state) => state.account.user.id);
    const response = yield call(claimsApi.fetchDoctorServices, id);
    yield put({
      type: actions.DOCTOR_SERVICES_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DOCTOR_SERVICES_REQUEST_FAILED });
  }
}

export function* resetClaimSaga(action) {
  try {
    const response = yield call(claimsApi.updateClaim, action);
    yield put({
      type: actions.CLAIM_RESET_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Заявка успешно обновлена',
    });
  } catch (error) {
    yield put({
      type: actions.CLAIM_RESET_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* closeClaimSaga(action) {
  try {
    const response = yield call(claimsApi.updateClaim, action);
    yield put({
      type: actions.CLAIM_CLOSE_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Заявка успешно обновлена',
    });
  } catch (error) {
    yield put({
      type: actions.CLAIM_CLOSE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Активный чат не найден',
    });
  }
}

export function* payClaimSaga(action) {
  try {
    const response = yield call(claimsApi.updateClaim, action);
    yield put({
      type: actions.CLAIM_PAY_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Заявка успешно обновлена',
    });
  } catch (error) {
    yield put({
      type: actions.CLAIM_PAY_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* claimAddServiceSaga(action) {
  const { serviceId, claim } = action.payload;
  const user = yield select((state) => state?.account?.user);
  const doctor = claim?.doctor;
  const data = {
    serviceId,
    claimId: claim?._id,
  };
  try {
    const response = yield call(claimsApi.addServiceToClaim, { ...data });
    yield put({
      type: actions.CLAIM_ADD_SERVICE_REQUEST_SUCCESS,
      payload: response.data,
    });
    const transaction = {
      claimId: claim?._id,
      userId: claim?.user?._id,
    };
    const transactionResponse = yield call(claimsApi.createTransaction, { ...transaction });
    if (transactionResponse) {
      yield put({
        type: actions.CLAIM_ADD_TRANSACTION_REQUEST_SUCCESS,
        payload: response.data,
      });
      if (user?.role === 2) {
        yield put({
          type: actions.CLAIMS_REQUEST,
          payload: { doctor },
        });
      } else {
        yield put({
          type: actions.CLAIMS_REQUEST,
          payload: { claimType: claim.claimType },
        });
      }
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Услуга успешно добавлена',
      });
    }
  } catch (error) {
    yield put({ type: actions.CLAIM_ADD_SERVICE_REQUEST_FAILED });
    yield put({ type: actions.CLAIM_ADD_TRANSACTION_REQUEST_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* claimAddAdminSaga(action) {
  const { id, el } = action.payload.data;
  const data = {
    id,
    claimId: el._id,
  };
  try {
    const response = yield call(claimsApi.addAdminToClaim, { ...data });
    yield put({
      type: actions.CLAIM_ADD_ADMIN_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CLAIM_ADD_ADMIN_REQUEST_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* claimDeleteAdminSaga(action) {
  const { id, claimId } = action.payload;
  const data = {
    id,
    claimId,
  };
  try {
    const response = yield call(claimsApi.deleteAdminFromClaim, { ...data });
    yield put({
      type: actions.CLAIM_DELETE_ADMIN_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CLAIM_DELETE_ADMIN_REQUEST_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* createClaimByAdminSaga(action) {
  const response = yield call(claimsApi.createClaim, { ...action.payload });
  if (response) {
    yield put({
      type: actions.CLAIM_BY_ADMIN_CREATE_REQUEST_SUCCESS,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Спасибо! Заявка успешно создана.',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  } else {
    yield put({
      type: actions.CLAIM_BY_ADMIN_CREATE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export const registerClaimsSagas = [
  takeLatest([
    actions.CLAIMS_REQUEST,
    actions.CLAIMS_CHANGE_PAGE,
    actions.CLAIMS_CHANGE_PAGE_RESET,
  ], getClaimSaga),
  takeEvery([actions.DOCTOR_SERVICES_REQUEST], getDoctorServices),
  takeEvery([actions.CLAIM_ADD_SERVICE_REQUEST], claimAddServiceSaga),
  takeEvery([actions.CLAIM_RESET_REQUEST], resetClaimSaga),
  takeEvery([actions.CLAIM_CLOSE_REQUEST], closeClaimSaga),
  takeEvery([actions.CLAIM_PAY_REQUEST], payClaimSaga),
  takeEvery([actions.CLAIM_ADD_ADMIN_REQUEST], claimAddAdminSaga),
  takeEvery([actions.CLAIM_DELETE_ADMIN_REQUEST], claimDeleteAdminSaga),
  takeEvery([actions.CLAIM_BY_ADMIN_CREATE_REQUEST], createClaimByAdminSaga),
];
