import React from 'react';
import PropTypes from 'prop-types';

const ClaimItemFiles = (props) => {
  const {
    claim, downloadFile,
  } = props;
  return (
    <div className="col-12">
      <b>Прикрепленные файлы: </b>
      {
        claim?.attachedFiles?.map((file) => (
            <div key={file.filename} role='button' onClick={() => downloadFile(file.filename)}>{file.filename}</div>
        ))
      }
    </div>
  );
};

ClaimItemFiles.propTypes = {
  claim: PropTypes.object.isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default ClaimItemFiles;
