import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import PropTypes from 'prop-types';

const SteerText = React.forwardRef((props, ref) => <span
  className={`${props.className}`}
  // TODO:: write CSS class
  ref={ref} {...props}
  style={{ borderBottom: '1px dotted', fontSize: '12px', cursor: 'help' }}>
  {props.text}&nbsp;
    <FontAwesomeIcon icon={props.icon || faQuestionCircle}/>
  </span>);

const SteerTextMemo = React.memo(SteerText);

/**
 * Component to make tooltips helpers easier
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Steer = (props) => {
  const {
    tooltip,
  } = props;
  if (tooltip) {
    return (
    <Tooltip placement={tooltip.position} title={tooltip.title} aria-label="add" arrow>
      <SteerTextMemo {...props} />
    </Tooltip>
    );
  }

  return <SteerTextMemo {...props} />;
};

export default React.memo(Steer);

SteerText.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Steer.propTypes = {
  icon: PropTypes.object,
  tooltip: PropTypes.shape({
    position: PropTypes.string,
    title: PropTypes.string,
  }),
  text: PropTypes.string,
  className: PropTypes.string,
};
