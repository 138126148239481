import AxiosClient from '../../../../functional/Axios/AxiosClient';

export const faqAdminApi = {
  getFaqTopics: () => AxiosClient.get('/root/faq'),

  deleteFaqTopic: ({ ...payload }) => {
    const { id } = payload.payload;
    return AxiosClient.delete(`/root/faq/${id}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  getFaqTopicArticles: ({ ...payload }) => {
    const { id } = payload.payload;
    return AxiosClient.delete(`/root/faq/topic/${id}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  addFaqTopic: ({ ...payload }) => {
    const { title, audience } = payload.payload;
    return AxiosClient.post('/root/faq/', { title, audience })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  updateFaqTopic: ({ ...payload }) => {
    const { title, audience, id } = payload.payload;
    return AxiosClient.put('/root/faq/', { title, audience, id })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  addFaqArticleToTopic: ({ ...payload }) => {
    const { title, content, chosenTopic } = payload.payload;
    return AxiosClient.post(`/root/faq/article/${chosenTopic}`, { title, content })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  updateFaqArticleInTopic: ({ ...payload }) => {
    const {
      title, content, articleItemId, topicId,
    } = payload.payload;
    return AxiosClient.put('/root/faq/article/', {
      title, content, articleItemId, topicId,
    })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  deleteFaqArticle: ({ ...payload }) => {
    const { articleId, topicId } = payload.payload;
    return AxiosClient.post('/root/faq/topic/article', null, { params: { articleId, topicId } })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
