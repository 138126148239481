import {
  call, put, select,
  takeEvery, takeLatest,
} from 'redux-saga/effects';

import { rootChatsApi } from '../api';
import { actions as rootChatActions } from './rootChats.reducer';

import { querystring } from '../../../../store/utils/formatters/querystring';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';

export function* getChatsByAdminSaga() {
  yield put({ type: 'SHOW_LOADER' });
  try {
    const pagination = yield select((state) => state.root.chats.pagination);
    const query = yield select((state) => state.root.chats.query);

    const q = { ...pagination, ...query };

    const response = yield call(rootChatsApi.fetchAllChatsByAdmin, { query: querystring(q) });

    response.data.chats.forEach((chat) => {
      if (chat?.messages?.length > 0) {
        chat.hasNewMessages = true;
      }
    });

    yield put({
      type: rootChatActions.CHATS_ADMIN_GET_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: rootChatActions.CHATS_ADMIN_GET_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* getClinicChatsByAdminSaga() {
  yield put({ type: 'SHOW_LOADER' });
  try {
    const pagination = yield select((state) => state.root.chats.pagination);
    const query = yield select((state) => state.root.chats.query);

    const q = { ...pagination, ...query };

    const response = yield call(rootChatsApi.fetchAllClinicChatsByAdmin, { query: querystring(q) });

    response.data.chats.forEach((chat) => {
      if (chat?.messages?.length > 0) {
        chat.hasNewMessages = true;
      }
    });

    yield put({
      type: rootChatActions.CHATS_CLINIC_ADMIN_GET_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: rootChatActions.CHATS_CLINIC_ADMIN_GET_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* addAdminToChat(payload) {
  try {
    const { response } = yield call(rootChatsApi.addAdminToChat, { ...payload });
    if (response) {
      yield put({
        type: rootChatActions.ADMIN_ADD_TO_CHAT_SUCCESS,
      });
      if (payload.payload.chatType === 'CHAT_WITH_DOCTOR') {
        yield put({
          type: rootChatActions.CHATS_ADMIN_GET_REQUEST,
        });
      } else {
        yield put({
          type: rootChatActions.CHATS_CLINIC_ADMIN_GET_REQUEST,
        });
      }
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Администратор успешно добавлен',
      });
    }
  } catch (error) {
    yield put({
      type: rootChatActions.ADMIN_ADD_TO_CHAT_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка добавления администартора',
    });
  }
}

export function* removeAdminFromChat(payload) {
  try {
    const { response } = yield call(rootChatsApi.removeAdminFromChat, { ...payload });
    if (response) {
      yield put({
        type: rootChatActions.ADMIN_REMOVE_FROM_CHAT_SUCCESS,
      });
      if (payload.payload.chatType === 'CHAT_WITH_DOCTOR') {
        yield put({
          type: rootChatActions.CHATS_ADMIN_GET_REQUEST,
        });
      } else {
        yield put({
          type: rootChatActions.CHATS_CLINIC_ADMIN_GET_REQUEST,
        });
      }
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Администратор успешно удален',
      });
    }
  } catch (error) {
    yield put({
      type: rootChatActions.ADMIN_REMOVE_FROM_CHAT_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка удаления администартора',
    });
  }
}

export const registerRootChatsSagas = [
  takeLatest([
    rootChatActions.CHATS_ADMIN_GET_REQUEST,
    rootChatActions.CHATS_ADMIN_CHANGE_PAGE], getChatsByAdminSaga),
  takeLatest([
    rootChatActions.CHATS_CLINIC_ADMIN_GET_REQUEST,
    rootChatActions.CHATS_ADMIN_CLINIC_CHANGE_PAGE], getClinicChatsByAdminSaga),
  takeEvery([rootChatActions.ADMIN_ADD_TO_CHAT_REQUEST], addAdminToChat),
  takeEvery([rootChatActions.ADMIN_REMOVE_FROM_CHAT_REQUEST], removeAdminFromChat),
];
