import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const userProfilesApi = {
  addUserProfile: (data) => AxiosClient.post('/users/profile', data),

  deleteUserProfile: (id) => AxiosClient.delete('/users/profile', { data: id }),

  verifyProfileRequest: (data) => AxiosClient.post('/integration/profileverification', data),

  cancelVerificationProfileRequest: (id) => AxiosClient.post('/integration/Profile/verification', { id }),

  verifyProfile: (id) => AxiosClient.post('/users/Profile/verify', { id, verified: true }),

  cancelProfileVerification: (id) => AxiosClient.post('/users/Profile/verify', { id, verified: false }),
};
