import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideLoader, showLoader } from '../Loader/store/loaderActions';
import { THEMES } from './const';

/**
 * Strategy component which decides what styles will be loaded
 */
class ThemeProvider extends React.Component {
  render() {
    const instance = this?.props?.clinicSettings?.ui?.theme;

    const selectedPreset = THEMES.find(((preset) => preset.instance === instance));

    if (selectedPreset) {
      this.props.showLoader(true);
      const theme = import(`../Themes/${selectedPreset.name}/${selectedPreset.css}`).finally(this.props.hideLoader);
    }
    return null;
  }
}

ThemeProvider.propTypes = {
  clinicSettings: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  ...store?.appconfig,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProvider);
