export const initialState = {
  visible: false,
  component: null,
  args: null, // component props!
  apiCallResult: [],
};

export const actions = {
  SHOW_MODAL: 'MODAL:SHOW',
  HIDE_MODAL: 'MODAL:HIDE',
  LOAD_MODAL: 'MODAL:LOAD',
};

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...state,
        visible: true,
      };

    case actions.HIDE_MODAL:
      return {
        ...state,
        visible: false,
      };

    case actions.LOAD_MODAL:
      return {
        ...state,
        visible: true,
        ...action.payload,
      };

    default:
      return { ...state };
  }
}
