import React, { useState } from 'react';
import Button from '../../../../containers/UI/Buttons/Button';

const NotificationItemFooter = ({ files }) => {
  const [show, setShow] = useState(false);
  return (
        <div>
            <Button className="flex-grow-1 p-0 mt-n2" onClick={() => setShow(!show)}>
                <small>Развернуть</small>
            </Button>
            {show && <div className="notification-footer">
                {files.map((f) => <a href={f.href} download><small>{f.text}</small></a>)}
            </div>}
        </div>
  );
};

export default NotificationItemFooter;
