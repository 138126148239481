/**
 * Two-factor authentication actions
 */
export const actions = {
  AUTH_GENERATE_2_FA_CODE: 'AUTH:GENERATE_2_FA_CODE',
  AUTH_SUBMIT_2_FA_CODE: 'AUTH:SUBMIT_2_FA_CODE',
};

/**
 * Create two-factor authentication code
 *
 * @returns {actions.AUTH_GENERATE_2_FA_CODE}
 */
export function generate2FACode() {
  return {
    type: actions.AUTH_GENERATE_2_FA_CODE,
  };
}

/**
 * Validate two-factor authentication code
 *
 * @param {Object} payload - action payload data
 * @param {string} payload.code - two-factor authentication code
 *
 * @returns {actions.AUTH_SUBMIT_2_FA_CODE}
 */
export function submit2FACode(payload) {
  return {
    type: actions.AUTH_SUBMIT_2_FA_CODE,
    payload,
  };
}
