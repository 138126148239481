import { actions } from './chatRoomReducer';

export function initChat(id) {
  return {
    type: actions.CHAT_INIT,
    id,
  };
}

export function updateChatMessages(message) {
  return {
    type: actions.CHAT_UPDATE_MESSAGES,
    payload: message,
  };
}

export function getChat() {
  return {
    type: actions.CHAT_GET_CHAT_REQUEST,
  };
}

export function getArchiveChats(obj) {
  return {
    type: actions.CHAT_GET_CHAT_ARCHIVE_REQUEST,
    payload: obj,
  };
}

export function getUserInfo(obj) {
  return {
    type: actions.CHAT_GET_USER,
    payload: obj,
  };
}
