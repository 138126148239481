import withQuery from '../../../store/utils/mutators/withQuery';
import withPagination from '../../../store/utils/mutators/withPagination';
import withFetch from '../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  _elements: null, // cache
  filter: [],
  fetching: false,
  error: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  EMR_RECORDS_REQUEST: 'EMR_RECORDS:REQUEST',
  EMR_RECORDS_REQUEST_SUCCESS: 'EMR_RECORDS:REQUEST_SUCCESS',
  EMR_RECORDS_REQUEST_FAILED: 'EMR_RECORDS:REQUEST_FAILED',

  EMR_RECORDS_CHANGE_PAGE: 'EMR_RECORDS:CHANGE_PAGE',
  EMR_RECORDS_CHANGE_PAGE_SUCCESS: 'EMR_RECORDS:CHANGE_PAGE_SUCCESS',
  EMR_RECORDS_CHANGE_PAGE_FAILED: 'EMR_RECORDS:CHANGE_PAGE_FAILED',
  EMR_RECORDS_CHANGE_PAGE_RESET: 'EMR_RECORDS:CHANGE_PAGE_RESET',

  EMR_RECORDS_CREATE_REQUEST: 'EMR_RECORDS_CREATE:REQUEST',
  EMR_RECORDS_CREATE_REQUEST_SUCCESS: 'EMR_RECORDS_CREATE:REQUEST_SUCCESS',
  EMR_RECORDS_CREATE_REQUEST_FAILED: 'EMR_RECORDS_CREATE:REQUEST_FAILED',

  EMR_RECORDS_UPDATE_REQUEST: 'EMR_RECORDS_UPDATE:REQUEST',
  EMR_RECORDS_UPDATE_REQUEST_SUCCESS: 'EMR_RECORDS_UPDATE:REQUEST_SUCCESS',
  EMR_RECORDS_UPDATE_REQUEST_FAILED: 'EMR_RECORDS_UPDATE:REQUEST_FAILED',
};

function emrRecordsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMR_RECORDS_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.payload || '',
      };

    case actions.EMR_RECORDS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.records,
        _elements: action.payload.records,
        fetching: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.EMR_RECORDS_REQUEST_FAILED:
      return {
        ...state,
        elements: [],
        _elements: [],
        fetching: false,
      };

    case actions.EMR_RECORDS_CREATE_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_RECORDS_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.EMR_RECORDS_CREATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    case actions.EMR_RECORDS_UPDATE_REQUEST:
      return {
        ...state,
      };

    case actions.EMR_RECORDS_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.EMR_RECORDS_UPDATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    case actions.EMR_RECORDS_CHANGE_PAGE:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.EMR_RECORDS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.EMR_RECORDS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}

export default emrRecordsReducer;
