import {
  call, put, takeEvery
} from 'redux-saga/effects';
import { actions } from './rightsManager.reducer';
import { rightsManagerApi } from '../api';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';

export function* rightsSaga(action) {
  try {
    const response = yield call(rightsManagerApi.rightsGet, action.payload.id);
    yield put({
      type: actions.USER_RIGHTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка прав доступа',
    });
    yield put({ type: actions.USER_RIGHTS_FAILED });
  }
}

export function* updateRightsSaga(action) {
  try {
    const response = yield call(rightsManagerApi.rightsUpdate, action.payload);
    yield put({
      type: actions.USER_RIGHTS_UPDATE_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Изменения успешно сохранены',
    });
  } catch (error) {
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка прав доступа',
    });
    yield put({ type: actions.USER_RIGHTS_UPDATE_FAILED });
  }
}

export function* checkRightsSaga(action) {
  try {
    const response = yield call(rightsManagerApi.checkUserRights);
    if (response) {
      yield put({
        type: actions.USER_CHECK_RIGHTS_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка прав доступа',
    });
    yield put({ type: actions.USER_CHECK_RIGHTS_FAILED });
    action.payload.push('/');
  }
}

export const registerRightsRootSagas = [
  takeEvery([actions.USER_RIGHTS_REQUEST], rightsSaga),
  takeEvery([actions.USER_RIGHTS_UPDATE_REQUEST], updateRightsSaga),
  takeEvery([actions.USER_CHECK_RIGHTS_REQUEST], checkRightsSaga),
];
