import { call, put, takeEvery } from 'redux-saga/effects';
import { actions } from './direcitons.root.reducer';
import { apiDirections } from '../api/apiDirections';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { actions as sidePanelActions } from '../../../UI/SidePanel/store/sidePanel.reducer';

/**
 * Getting directions for root
 */
export function* getDirections() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(apiDirections.fetchDirections);
    yield put({
      type: actions.DIRECTIONS_ADMIN_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DIRECTIONS_ADMIN_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* directionsFilterSaga(action) {
  try {
    yield put({
      type: actions.DIRECTIONS_ADMIN_FILTER_SUCCESS,
      payload: action.payload.elementsFiltered,
    });
  } catch (error) {
    yield put({ type: actions.DIRECTIONS_ADMIN_FILTER_FAILED });
  }
}

export function* addDirection(action) {
  const response = yield call(apiDirections.addDirection, { ...action.payload });
  if (response) {
    yield put({
      type: actions.DIRECTION_ADD_REQUEST_SUCCESS,
    });
    yield put({
      type: actions.DIRECTIONS_ADMIN_REQUEST,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Направление успешно добавлено.',
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
  } else {
    yield put({
      type: actions.DIRECTION_ADD_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
  }
}

export function* deleteDirection(action) {
  const { id } = action.payload;
  const response = yield call(apiDirections.deleteDirection, id);
  if (response) {
    yield put({
      type: actions.DIRECTION_DELETE_REQUEST_SUCCESS,
    });
    yield put({
      type: actions.DIRECTIONS_ADMIN_REQUEST,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Направление успешно удалено.',
    });
  } else {
    yield put({
      type: actions.DIRECTION_DELETE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export function* addDirectionMisId(action) {
  console.log(action.payload);
  const response = yield call(apiDirections.addMisId, { ...action.payload });
  if (response) {
    yield put({
      type: actions.DIRECTION_ADD_MISID_REQUEST_SUCCESS,
    });
    yield put({
      type: actions.DIRECTIONS_ADMIN_REQUEST,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Информация успешно добавлена.',
    });
  } else {
    yield put({
      type: actions.DIRECTION_ADD_MISID_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export const registerRootDirectionsSagas = [
  takeEvery([actions.DIRECTIONS_ADMIN_REQUEST], getDirections),
  takeEvery([actions.DIRECTIONS_ADMIN_FILTER], directionsFilterSaga),
  takeEvery([actions.DIRECTION_ADD_REQUEST], addDirection),
  takeEvery([actions.DIRECTION_DELETE_REQUEST], deleteDirection),
  takeEvery([actions.DIRECTION_ADD_MISID_REQUEST], addDirectionMisId),
];
