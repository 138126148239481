import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { toast } from 'react-toastify';
import * as PropTypes from 'prop-types';
import AxiosClient from '../../../../../functional/Axios/AxiosClient';
import './CancelClaim.css';
import { TextAreaBase } from '../../../../../containers/Inputs/TextAreaBase/TextAreaBase';

class CancelClaimModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelReason: '',
      cancelClaim: false,
      showConfirm: false,
    };
  }

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  cancelClaim = (id, withMail) => {
    AxiosClient.delete(`/root/claim/${id}`, { data: { cancelReason: this.state.cancelReason, withMail } })
      .then(() => {
        this.props.hideModal();
        this.props.fetch();
        this.setState({
          cancelClaim: false,
        });
        toast.success('Заявка успешно закрыта');
      });
  }

  cancelConsultation = () => {
    this.setState({
      showConfirm: true,
    });
  };

  render() {
    const {
      claim, hideModal,
    } = this.props;
    const { showConfirm, cancelReason } = this.state;

    return (
      <Row className="p-4">
        <>
          <Col xs={12} className="mb-2 text-right">
            <div>
              <FontAwesomeIcon icon={faTimesCircle} size={'lg'} onClick={hideModal} className="cancelclaim-close"/>
            </div>
          </Col>
          <Col xs={12}>
            {
              showConfirm && <div>
                <div className="text-center">
                  <b>Подтвердите закрытие заявки:</b>
                </div>
                <div className="d-flex flex-column justify-content-center mb-2 mt-4 text-center">
                  <div>
                    <button
                      type="button"
                      className="btn-custom btn btn-sm btn-success mb-3"
                      onClick={() => this.cancelClaim(claim._id, true)}>
                      Закрыть и отправить сообщение
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn-custom btn btn-sm btn-danger"
                      onClick={() => this.cancelClaim(claim._id, false)}>
                      Закрыть без отправки сообщения
                    </button>
                  </div>
                </div>
              </div>
            }
            {
              !showConfirm && <>
                <div className="text-center">
                  <b>Кратко опишите итог заявки:</b>
                </div>
                <form id="add-user" className="mt-2">
                  <TextAreaBase
                    id="cancelReason"
                    className="form-control"
                    placeholder="Кратко опишите итог заявки"
                    name="cancelReason"
                    rows="5"
                    value={this.state.cancelReason}
                    onChange={this.onChange}
                    required
                  />
                  <div className="mt-3">
                    <div className="d-flex flex-row justify-content-end mb-2">
                      <div>
                        <button
                          type="button"
                          className="btn-custom btn btn-sm btn-danger mr-3"
                          disabled={cancelReason.length === 0}
                          onClick={this.cancelConsultation}>
                          Закрыть заявку
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            }
          </Col>
        </>
      </Row>
    );
  }
}

CancelClaimModal.propTypes = {
  claim: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default CancelClaimModal;
