import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';

const SessionStatusBar = (props) => {
  const { session } = props;
  return (
    <div className="d-none d-md-block">
      {session && <FontAwesomeIcon icon={faUser} size={'1x'} style={{ marginTop: '5px' }} className='__color-green'
                            title={'Вы онлайн'}/>}
      {!session && <FontAwesomeIcon icon={faUser} size={'1x'} style={{ marginTop: '5px' }} className='__color-red'
                            title={'Вы оффлайн'}/>}
      &nbsp;&nbsp;
    </div>
  );
};

const mapStateToProps = (store) => ({
  session: store.account.session.online,
});

export default connect(mapStateToProps, null)(SessionStatusBar);
