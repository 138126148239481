export const initialState = {
  error: false,
  apiCallResult: [],
  field: {},
  fetch: false,
};

export const actions = {
  USERSETTINGS_PASSWORDCHANGE_REQUEST: 'USERSETTINGS_PASSWORDCHANGE:REQUEST',
  USERSETTINGS_PASSWORDCHANGE_SUCCESS: 'USERSETTINGS_PASSWORDCHANGE:SUCCESS',
  USERSETTINGS_PASSWORDCHANGE_ERROR: 'USERSETTINGS_PASSWORDCHANGE:ERROR',

  USER_UPDATE_REQUEST: 'USER_UPDATE:REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE:SUCCESS',
  USER_UPDATE_ERROR: 'USER_UPDATE:ERROR',

  USER_UPDATE_2FA_REQUEST: 'USER_UPDATE_2FA:REQUEST',
  USER_UPDATE_2FA_SUCCESS: 'USER_UPDATE_2FA:SUCCESS',
  USER_UPDATE_2FA_ERROR: 'USER_UPDATE_2FA:ERROR',

  USER_UPDATE_EMR_REQUEST: 'USER_UPDATE_EMR:REQUEST',
  USER_UPDATE_EMR_SUCCESS: 'USER_UPDATE_EMR:SUCCESS',
  USER_UPDATE_EMR_ERROR: 'USER_UPDATE_EMR:ERROR',

  USER_UPDATE_NOTIFICATIONS_REQUEST: 'USER_UPDATE_NOTIFICATIONS:REQUEST',
  USER_UPDATE_NOTIFICATIONS_SUCCESS: 'USER_UPDATE_NOTIFICATIONS:SUCCESS',
  USER_UPDATE_NOTIFICATIONS_ERROR: 'USER_UPDATE_NOTIFICATIONS:ERROR',

  USER_CANCEL_VERIFICATION_REQUEST: 'USER_CANCEL_VERIFICATION:REQUEST',
  USER_CANCEL_VERIFICATION_SUCCESS: 'USER_CANCEL_VERIFICATION:SUCCESS',
  USER_CANCEL_VERIFICATION_ERROR: 'USER_CANCEL_VERIFICATION:ERROR',

  USER_VERIFY_PROFILE_REQUEST: 'USER_VERIFY_PROFILE:REQUEST',
  USER_VERIFY_PROFILE_SUCCESS: 'USER_VERIFY_PROFILE:SUCCESS',
  USER_VERIFY_PROFILE_ERROR: 'USER_VERIFY_PROFILE:ERROR',

  USER_VERIFY_CODE_REQUEST: 'USER_VERIFY_CODE:REQUEST',
  USER_VERIFY_CODE_SUCCESS: 'USER_VERIFY_CODE:SUCCESS',
  USER_VERIFY_CODE_ERROR: 'USER_VERIFY_CODE:ERROR',

  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE:SUCCESS',
  UPDATE_USER_PROFILE_ERROR: 'UPDATE_USER_PROFILE:ERROR',

  USER_UPLOAD_AVATAR_REQUEST: 'USER_UPLOAD_AVATAR:REQUEST',
  USER_UPLOAD_AVATAR_SUCCESS: 'USER_UPLOAD_AVATAR:SUCCESS',
  USER_UPLOAD_AVATAR_ERROR: 'USER_UPLOAD_AVATAR:ERROR',
};

export function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USERSETTINGS_PASSWORDCHANGE_REQUEST:
      return {
        ...state,
      };

    case actions.USERSETTINGS_PASSWORDCHANGE_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USERSETTINGS_PASSWORDCHANGE_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_REQUEST:
      return {
        ...state,
      };

    case actions.USER_UPDATE_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_2FA_REQUEST:
      return {
        ...state,
      };

    case actions.USER_UPDATE_2FA_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_2FA_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_EMR_REQUEST:
      return {
        ...state,
      };

    case actions.USER_UPDATE_EMR_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_UPDATE_EMR_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_CANCEL_VERIFICATION_REQUEST:
      return {
        ...state,
      };

    case actions.USER_CANCEL_VERIFICATION_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_CANCEL_VERIFICATION_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_VERIFY_PROFILE_REQUEST:
      return {
        ...state,
      };

    case actions.USER_VERIFY_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_VERIFY_PROFILE_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_VERIFY_CODE_REQUEST:
      return {
        ...state,
      };

    case actions.USER_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_VERIFY_CODE_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.USER_UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
      };

    case actions.USER_UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.USER_UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    case actions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload.result,
        field: action.payload.field,
      };

    case actions.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload.result,
        field: action.payload.field,
      };

    case actions.USER_UPDATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USER_UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.USER_UPDATE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}
