import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../../../../containers/UI/Buttons/Button';
import Confirm from '../../../Confirm/Confirm';
import { getGlobalConfig } from '../../../../root/AppConfig/store/appconfigActions';
import { UserAgreement } from '../../../../User/components/UserAgreement/UserAgreement';
import { DATA_TYPES } from '../../../types/VALIDATE';
import { registerUser, verify } from '../../../store/registration-form.actions';
import { InputBase } from '../../../../../containers/Inputs/InputBase/inputBase';
import { FORMAT_CHARACTERS } from '../../../../../utils/FORMAT_CHARACTERS';
import { DateFormats, PHONE_FORMAT } from '../../../../../const/dateFormats';
import { RegistrationUserAgreement } from '../../../RegistrationUserAgreement/RegistrationUserAgreement';

/**
 * Component Registration Form
 *
 * @param {Object} props - component props
 *
 * @returns {JSX.Element}
 */
class RegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      password2: '',
      phone: '',
      firstName: '',
      surName: '',
      lastName: '',
      birthday: '',
      avatar: '',
      medCard: '',
      sex: '',
      errors: [],
      emailConfirmed: false,
      phoneConfirmed: false,

      verifyEmailVisible: false,
      verifyEmail: '',
      verifyPhoneVisible: false,
      verifyPhone: '',

      personalDataAgreement: false,
      ofertaAgreement: false,
      showPolicyModal: false,
      showOfertaModal: false,
      noLastName: false,
    };
  }

  componentDidMount() {
    getGlobalConfig();
  }

  // TODO: fix telephone validation

  disabledSubmitButton = () => {
    const {
      email,
      password,
      password2,
      phone,
      firstName,
      surName,
      lastName,
      birthday,
      personalDataAgreement,
      ofertaAgreement,
      sex,
    } = this.state;
    const isVerify = this.checkVerify();

    return (
      isVerify
      && personalDataAgreement
      && ofertaAgreement
      && email
      && this.validateEmail(email)
      && password
      && password2
      && phone
      && firstName
      && lastName
      && surName
      && birthday
      && sex
    );
  };

  checkboxHandler = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.checked;
    this.setState(state);
  };

  validatePhone = (phone) => String(phone)
    .toLowerCase()
    .match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);

  validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  isValid = (field, type) => {
    const { name, validity, value } = field;
    return type === name && validity.valid &&
      (this.validateEmail(value) || this.validatePhone(value));
  };

  onBlur = (e) => {
    const { appconfig: { appSettings } } = this.props;
    const { verifyEmail, verifyPhone } = this.state;
    const field = e.target;
    const { value } = field;

    if (this.isValid(field, DATA_TYPES.email) && appSettings.enableEmailVerify && value !== verifyEmail) {
      this.setState({
        verifyEmail: value,
        email: value,
        verifyEmailVisible: true,
      });
      this.onValidate(field.name);
    }
    if (this.isValid(field, DATA_TYPES.phone) && appSettings.enableSmsVerify && value !== verifyPhone) {
      this.setState({
        verifyPhone: value,
        phone: value,
        verifyPhoneVisible: true,
      });
      this.onValidate(field.name);
    }
  };

  onValidate = (type, code) => {
    if (this.state[type]) {
      this.props.verify({
        type: code && code.length > 0 ? `validate-${type}` : type,
        code,
        UID: this.state[type],
      });
    }
  };

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  checkVerify = () => {
    const { appconfig: { appSettings } } = this.props;
    const { emailConfirmed, phoneConfirmed } = this.props.register;

    return (
      (appSettings.enableEmailVerify && emailConfirmed)
      || (appSettings.enableSmsVerify && phoneConfirmed)
    );
  };

  onSubmit = (e) => {
    e.preventDefault();

    const isVerify = this.checkVerify();
    if (!isVerify) return false;

    this.props.registerUser(this.state, () => this.props.history.push('/auth'));
  };

  togglePolicyModal = () => {
    this.props.loadSidePanel({
      component: UserAgreement,
      args: {
        agreement: this.props.appconfig?.clinicSettings?.agreement,
        title: 'Политика конфиденциальности',
        hideSidePanel: this.props.hideSidePanel,
      },
    });
  };

  toggleOfertaModal = () => {
    this.props.loadSidePanel({
      component: UserAgreement,
      args: {
        agreement: this.props.appconfig?.clinicSettings?.oferta,
        title: 'Оферта о предоставлении услуг',
        hideSidePanel: this.props.hideSidePanel,
      },
    });
  };

  handleNoLastName = () => {
    this.setState({
      noLastName: !this.state.noLastName,
      lastName: this.state.noLastName ? '' : '-',
    });
  }

  render() {
    const { appconfig: { appSettings }, register } = this.props;

    const {
      email,
      password,
      password2,
      phone,
      firstName,
      surName,
      lastName,
      birthday,
      personalDataAgreement,
      ofertaAgreement,
      sex,
      noLastName,
    } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-xs-12 col-sm-12 m-auto">
            <form className="form-signin" onSubmit={this.onSubmit}>
              <h2 className="pt-5">Регистрация</h2>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <InputBase
                      required
                      type="email"
                      className="form-control"
                      placeholder="Почта *"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                    />
                  </div>
                </div>
                {appSettings.enableEmailVerify && register.verifyEmailVisible && (
                  <Confirm
                    refresh={() => this.props.verify({
                      UID: email,
                      type: DATA_TYPES.email,
                    })
                    }
                    type={DATA_TYPES.email}
                    name={`${DATA_TYPES.email}-confirm`}
                    handler={this.onValidate}
                  />
                )}
                <div className="col-4">
                  <div className="form-group">
                    <InputBase
                      required
                      type="text"
                      className="form-control"
                      placeholder="Фамилия *"
                      name="surName"
                      value={surName}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <InputBase
                      required
                      type="text"
                      className="form-control"
                      placeholder="Имя *"
                      name="firstName"
                      value={firstName}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <InputBase
                      type="text"
                      className="form-control"
                      placeholder="Отчество"
                      name="lastName"
                      value={lastName}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={noLastName}
                        onChange={this.handleNoLastName}
                        name="personalDataAgreement"
                      />
                    }
                    label={
                      <Box component="div" fontSize={13} m={0} p={0}>
                        Отчество отсутствует
                      </Box>
                    }
                  />
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <MaskedInput
                      required
                      className="form-control"
                      name="birthday"
                      mask={DateFormats.DEFAULT}
                      placeholder="Дата рождения *"
                      size="11"
                      value={birthday}
                      onChange={this.onChange}
                      formatCharacters={FORMAT_CHARACTERS}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <FormControl component="fieldset" className="col-12">
                    <div className="d-flex flex-row justify-content-between">
                      <div>
                        <FormLabel component="legend" className="mt-3 ml-2">
                          Пол *
                        </FormLabel>
                      </div>
                      <div>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="gender1"
                          value={sex}
                          onChange={this.onChange}
                        >
                          <FormControlLabel
                            className="mt-1"
                            value="F"
                            control={<Radio color="default" />}
                            label={
                              <Box component="div" fontSize={13}>
                                Женский
                              </Box>
                            }
                            name="sex"
                          />
                          <FormControlLabel
                            className="ml-3 mt-1"
                            value="M"
                            control={<Radio color="default" />}
                            label={
                              <Box component="div" fontSize={13}>
                                Мужской
                              </Box>
                            }
                            name="sex"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </FormControl>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <MaskedInput
                      required
                      mask={PHONE_FORMAT}
                      className="form-control"
                      placeholder="Телефон *"
                      name={DATA_TYPES.phone}
                      value={phone}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                    />
                  </div>
                </div>
                {appSettings.enableSmsVerify && register.verifyPhoneVisible && (
                  <Confirm
                    type={DATA_TYPES.phone}
                    name={`${DATA_TYPES.phone}-confirm`}
                    handler={this.onValidate}
                  />
                )}
                <div className="col-12">
                  <div className="form-group">
                    <InputBase
                      required
                      type="password"
                      className="form-control"
                      placeholder="Пароль *"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <InputBase
                      required
                      type="password"
                      className="form-control"
                      placeholder="Повторите пароль *"
                      name="password2"
                      value={password2}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <RegistrationUserAgreement
                  personalDataAgreement={personalDataAgreement}
                  checkboxHandler={this.checkboxHandler.bind(this)}
                  togglePolicyModal={this.checkboxHandler.bind(this)}
                  ofertaAgreement={ofertaAgreement}
                  toggleOfertaModal={this.toggleOfertaModal.bind(this)}
                />
              </div>
              <p>
                <small className="text-muted">
                  Поля, отмеченные * обязательны для заполнения
                </small>
              </p>
              {this.disabledSubmitButton() ? (
                <Button className="btn-firm-filled w-100" type="submit">
                  Зарегистрироваться
                </Button>
              ) : (
                <Button
                  type={'button'}
                  className="btn-firm-filled w-100 disabled">
                  Необходимо заполнить все пункты формы регистрации
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  appconfig: PropTypes.shape({
    clinicSettings: PropTypes.shape({
      oferta: PropTypes.string,
      agreement: PropTypes.string,
    }),
    appSettings: PropTypes.shape({
      enableEmailVerify: PropTypes.bool,
      enableSmsVerify: PropTypes.bool,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  registerUser: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
  register: PropTypes.object.isRequired,
};

RegistrationForm.defaultProps = {
  appconfig: {
    clinicSettings: {
      oferta: '',
      agreement: '',
    },
    appSettings: {
      enableEmailVerify: true,
      enableSmsVerify: true,
    },
  },
  register: {
    emailConfirmed: false,
    phoneConfirmed: false,
  },
  history: {
    push: () => {},
  },
  registerUser: () => {},
  verify: () => {},
};

const mapStateToProps = (store) => ({
  appconfig: store?.appconfig,
  register: store.register,
});

const mapDispatchToProps = {
  getAppConfig: getGlobalConfig,
  registerUser,
  verify,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationForm));
