import React from 'react';
import * as PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { RSS_REGEX } from '../../../const/rssRegex';

const _class = 'form-control ';

export const INPUT_ICONS = {
  loading: 'loading',
  ok: 'ok',
  fail: 'fail'
};

export const InputBase = props => {
  const {
    label,
    type,
    className,
    placeholder,
    name,
    value,
    defaultValue,
    required,
    disabled,
    onChange,
    onBlur,
    mask,
    size,
    formatCharacters,
    icon,
    max,
    min,
    pattern,
    step,
    allowScripts
  } = props;

  const updateStyle = () => {
    if (icon === INPUT_ICONS.loading) {
      return {
        paddingRight: '34px',
        background:
          'url(/rolling.svg) white no-repeat scroll calc(100% - 10px) 10px'
      };
    }
    if (icon === INPUT_ICONS.ok) {
      return {
        paddingRight: '34px',
        background: 'url(/ok.svg) white no-repeat scroll calc(100% - 10px) 10px'
      };
    }
    if (icon === INPUT_ICONS.fail) {
      return {
        paddingRight: '34px',
        background:
          'url(/fail.svg) white no-repeat scroll calc(100% - 10px) 10px'
      };
    }
  };

  const onChangeValue = event => {
    if (!allowScripts) {
      event.preventDefault();
      event.target.value = event.target.value.replace(RSS_REGEX, '');
    }
    onChange(event);
  };

  const maskedInput = (
    <MaskedInput
      style={updateStyle()}
      label={label}
      required={required}
      disabled={disabled}
      onBlur={onBlur}
      className={`${_class} ${className}`}
      mask={mask}
      placeholder={placeholder}
      size={size}
      name={name}
      value={value}
      formatCharacters={formatCharacters}
      onChange={onChangeValue}
    />
  );

  return (
    <>
      {label && <label className="font-weight-bold mb-n1">{label}</label>}
      {mask ? (
        maskedInput
      ) : (
        <input
          style={updateStyle()}
          type={type}
          className={`${_class} ${className}`}
          placeholder={placeholder}
          name={name}
          defaultValue={value ? undefined : defaultValue}
          value={defaultValue ? undefined : value || ''}
          required={required}
          disabled={disabled}
          onChange={onChangeValue}
          max={max}
          min={min}
          onBlur={e => onBlur(e)}
          pattern={pattern}
          step={step}
        />
      )}
    </>
  );
};

InputBase.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  size: PropTypes.string,
  formatCharacters: PropTypes.object,
  icon: PropTypes.oneOf([
    INPUT_ICONS.loading,
    INPUT_ICONS.ok,
    INPUT_ICONS.fail,
    ''
  ]),
  pattern: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowScripts: PropTypes.bool
};

InputBase.defaultProps = {
  label: '',
  type: 'text',
  className: '',
  placeholder: 'Введите ваш текст',
  name: undefined,
  value: '',
  defaultValue: '',
  required: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  mask: '',
  size: '',
  icon: '',
  pattern: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  allowScripts: false
};
