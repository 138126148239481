import { combineReducers } from 'redux';
import { chatRoomReducer } from '../components/ChatRoom/chatRoomReducer';
import { archiveChatsReducer } from '../components/Chats/archiveChats.reducer';
import { activeChatsReducer } from '../components/Chats/activeChats.reducer';
import { chatModalReducer } from '../components/ChatModal/chatModalReducer';

export const chatReducer = combineReducers({
  chatRoom: chatRoomReducer,
  chatModal: chatModalReducer,
  chats: combineReducers({ archiveChats: archiveChatsReducer, activeChats: activeChatsReducer }),
});
