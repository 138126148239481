import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/ModalActions';
import { sendTaskModalPost } from './store/SendTaskModalActions';
import ModalButtons from '../ModalButtons/ModalButtons';
import { TextAreaBase } from '../../../../../containers/Inputs/TextAreaBase/TextAreaBase';

class SendTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      disabled: false,
    };
  }

  handleSendTask = async () => {
    const { comment } = this.state;
    const id = this.props.userId;
    const docName = `${this.props.account.user.surName} ${this.props.account.user.firstName} ${this.props.account.user.lastName}`;
    this.setState({
      disabled: true,
    });
    this.props.sendTaskModalPost({
      id,
      comment,
      docName,
    });
  };

    onChange = (e) => {
      const { state } = this;
      state[e.target.name] = e.target.value;
      this.setState(state);
    };

    render() {
      return (
            <div className="p-4">
                <h1>Создать задание для администратора</h1>
                <div className="d-flex flex-column justify-content-center">
                    <form id="add-request">
                        <br/>
                        <TextAreaBase className="form-control comment" rows="6" name="comment" id="comment"
                                      required
                                      placeholder="Здесь вы можете создать задание администратору клиники для очного визита или напоминания.
                                       Напишите короткий комментарий, все остальные данные о пациенте уже доступны"
                                      value={this.state.comment}
                                      onChange={this.onChange}
                        />
                    </form>
                    <div className="d-sm-flex justify-content-end mb-2 mt-3">
                        <ModalButtons
                            doctorId={this.props.userId}
                            comment={this.state.comment}
                            disabled={this.state.disabled}
                            bookSecondOpinion={this.handleSendTask}
                        />
                        <div className="mt-2">
                            <button
                                type="button"
                                className="btn btn-custom btn-secondary btn-sm"
                                onClick={this.props.hideModal}>Отменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      );
    }
}

const mapStateToProps = (store) => ({
  account: store.account,
  appconfig: store.appconfig,
});

const mapDispatchToProps = {
  hideModal,
  sendTaskModalPost,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendTaskModal);
