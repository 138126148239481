import withQuery from '../../../../store/utils/mutators/withQuery';

const defaultState = {
  elements: [],
  fetch: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
};

export const actions = {
  SERVICES_ALL_REQUEST: 'SERVICES_ALL:REQUEST',
  SERVICES_ALL_REQUEST_SUCCESS: 'SERVICES_ALL:REQUEST_SUCCESS',
  SERVICES_ALL_REQUEST_FAILED: 'SERVICES_ALL:REQUEST_FAILED',

  GET_SERVICES: 'HTTP:GET_SERVICES',
  GET_SERVICES_SUCCESS: 'HTTP:GET_SERVICES_SUCCESS',
  GET_SERVICES_FAILED: 'HTTP:GET_SERVICES_FAILED',
  GET_SERVICE: 'HTTP:GET_SERVICE',

  CREATE_SERVICE: 'HTTP:CREATE_SERVICE',
  CREATE_SERVICE_SUCCESS: 'HTTP:CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_FAILED: 'HTTP:CREATE_SERVICE_FAILED',

  UPDATE_SERVICE: 'HTTP:UPDATE_SERVICE',
  UPDATE_SERVICE_SUCCESS: 'HTTP:UPDATE_SERVICE_SUCCESS',
  UPDATE_SERVICE_FAILED: 'HTTP:UPDATE_SERVICE_FAILED',

  DELETE_SERVICE: 'HTTP:DELETE_SERVICE',
  DELETE_SERVICE_SUCCESS: 'HTTP:DELETE_SERVICE_SUCCESS',
  DELETE_SERVICE_FAILED: 'HTTP:DELETE_SERVICE_FAILED',
};

export function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SERVICES_ALL_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.SERVICES_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetch: false,
      };

    case actions.GET_SERVICES:
      return {
        ...state,
        fetch: true,
        query: action.payload || null,
      };

    case actions.GET_SERVICES_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetch: false,
      };

    case actions.GET_SERVICES_FAILED:
      return {
        ...state,
        elements: [],
        fetch: false,
      };

    case actions.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}

export default servicesReducer;
