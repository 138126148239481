export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  NEWS_ALL_REQUEST: 'NEWS_ALL:REQUEST',
  NEWS_ALL_REQUEST_SUCCESS: 'NEWS_ALL:REQUEST_SUCCESS',
  NEWS_ALL_REQUEST_FAILED: 'NEWS_ALL:REQUEST_FAILED',
  NEWS_CREATE_REQUEST: 'NEWS_CREATE:REQUEST',
  NEWS_CREATE_SUCCESS: 'NEWS_CREATE:REQUEST_SUCCESS',
  NEWS_CREATE_FAILED: 'NEWS_CREATE:REQUEST_FAILED',
  NEWS_UPDATE_REQUEST: 'NEWS_UPDATE:REQUEST',
  NEWS_UPDATE_SUCCESS: 'NEWS_UPDATE:REQUEST_SUCCESS',
  NEWS_UPDATE_FAILED: 'NEWS_UPDATE:REQUEST_FAILED',
  NEWS_DELETE_REQUEST: 'NEWS_DELETE:REQUEST',
  NEWS_DELETE_SUCCESS: 'NEWS_DELETE:REQUEST_SUCCESS',
  NEWS_DELETE_FAILED: 'NEWS_DELETE:REQUEST_FAILED',
};

export function newsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.NEWS_ALL_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    case actions.NEWS_CREATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.NEWS_UPDATE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_UPDATE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case actions.NEWS_DELETE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.NEWS_DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default newsReducer;
