import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { CLAIM_TYPES, CLAIM_TYPES_TITLES } from '../../const/constants';

const ClaimItemType = (props) => {
  const { claim } = props;
  const renderClaimType = (claim) => (
          <small>Тип заявки:&nbsp;
              {claim.claimType === CLAIM_TYPES.SECOND_OPINION && CLAIM_TYPES_TITLES.SECOND_OPINION}
              {claim.claimType === CLAIM_TYPES.CALL_DOCTOR && CLAIM_TYPES_TITLES.CALL_DOCTOR}
              {claim.claimType === CLAIM_TYPES.PREPAID && CLAIM_TYPES_TITLES.PREPAID}
              {claim.claimType === CLAIM_TYPES.REQUEST_APPOINTMENT && CLAIM_TYPES_TITLES.REQUEST_APPOINTMENT}
          </small>
  );

  return (
      <>
          {renderClaimType(claim)}
          <Divider/>
      </>
  );
};

ClaimItemType.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimItemType;
