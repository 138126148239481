import { widgetsMap } from './WidgetsGridContainer/const';
import { CLAIM_STATUSES, ClaimStatus } from '../../../Claim/const/constants';

const monthsMap = {
  0: 'январь',
  1: 'февраль',
  2: 'март',
  3: 'апрель',
  4: 'май',
  5: 'июнь',
  6: 'июль',
  7: 'август',
  8: 'сентябрь',
  9: 'октябрь',
  10: 'ноябрь',
  11: 'декабрь',
};

export const linearGraphMapper = (data, title) => [
  {
    id: title,
    data: Object.entries(data).map((el) => ({ x: monthsMap[el[0]], y: el[1] }))
  },
];

export const radialBarMapper = (claims, title) => [
  {
    id: title,
    data: [
      {
        x: ClaimStatus[CLAIM_STATUSES.IN_PROCESS],
        y: claims.claimsByStatus[CLAIM_STATUSES.IN_PROCESS],
      },
      {
        x: ClaimStatus[CLAIM_STATUSES.SET_TO_DOCTOR],
        y: claims.claimsByStatus[CLAIM_STATUSES.SET_TO_DOCTOR],
      },
      {
        x: ClaimStatus[CLAIM_STATUSES.SERVICE_ASSIGNED],
        y: claims.claimsByStatus[CLAIM_STATUSES.SERVICE_ASSIGNED],
      },
      {
        x: ClaimStatus[CLAIM_STATUSES.PAID],
        y: claims.claimsByStatus[CLAIM_STATUSES.PAID],
      },
      {
        x: ClaimStatus[CLAIM_STATUSES.DONE],
        y: claims.claimsByStatus[CLAIM_STATUSES.DONE],
      },
      {
        x: ClaimStatus[CLAIM_STATUSES.CLOSED_FAILURE],
        y: claims.claimsByStatus[CLAIM_STATUSES.CLOSED_FAILURE],
      }
    ]
  }
];

const widgetsMapper = (resolution, props) => props.widgets?.map((widget) => (
  {
    component: widgetsMap(props)[widget.key],
    i: widget.key,
    x: widget['x'.concat(`${resolution}`)],
    y: widget['y'.concat(`${resolution}`)],
    h: widget['h'.concat(`${resolution}`)],
    w: widget['w'.concat(`${resolution}`)],
    role: widget.role,
    disabled: `${resolution}` !== '' ? widget[`${resolution}`.toLocaleLowerCase().concat('Disabled')] : widget.disabled,
    resizeHandles: ['se']
  }
));

export const getWidgetsLayout = (screenResolution, widgets) => {
  const { isDesktop, isTablet, isMobile } = screenResolution;
  let preparedWidgets;

  if (isDesktop && isMobile && isTablet) {
    preparedWidgets = widgetsMapper('', widgets);
  } else if (isTablet && isMobile) {
    preparedWidgets = widgetsMapper('Tablet', widgets);
  } else {
    preparedWidgets = widgetsMapper('Mobile', widgets);
  }

  return preparedWidgets.filter((widget) => widget.disabled === false);
};
