import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { ACCESS_ROLES } from '../../../../functional/roles';

const ClaimItemActions = (props) => {
  const {
    refreshClaim, claim, account, payClaim, claimCloseSuccess,
  } = props;
  return (
        <div className="d-sm-flex p-0">
            {
                (claim.status === 7 && (account.user.role === +ACCESS_ROLES.ADMIN
                    || account.user.role === +ACCESS_ROLES.ROOT
                    || account.user.role === +ACCESS_ROLES.DOCTOR))
                && <div onClick={claimCloseSuccess} className="mr-2" role="button"><FontAwesomeIcon icon={faTimesCircle}/></div>
            }
            {
                (claim.status !== 2 && claim.status === 5 && account.user.role === +ACCESS_ROLES.ROOT)
                && <div onClick={payClaim} className="mr-2 toggle-sendMail" role="button">
                    <FontAwesomeIcon icon={faDollarSign}/>
                </div>
            }
            {
                (claim.status === 9 && (account.user.role === +ACCESS_ROLES.ADMIN || account.user.role === +ACCESS_ROLES.ROOT))
                && <div onClick={refreshClaim} className="mr-2" role="button"><FontAwesomeIcon icon={faRedo}/></div>
            }
        </div>
  );
};

ClaimItemActions.propTypes = {
  refreshClaim: PropTypes.func.isRequired,
  payClaim: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

export default ClaimItemActions;
