import React from 'react';
import * as PropTypes from 'prop-types';
import { RSS_REGEX } from '../../../const/rssRegex';

const _class = 'form-control ';

export const TextAreaBase = props => {
  const {
    onChange,
    onBlur,
    onKeyPress,
    className,
    placeholder,
    name,
    value,
    defaultValue,
    required,
    disabled,
    rows,
    maxLength,
    allowScripts
  } = props;

  const onChangeValue = event => {
    if (!allowScripts) {
      event.preventDefault();
      event.target.value = event.target.value.replace(RSS_REGEX, '');
    }
    onChange(event);
  };

  return (
    <textarea
      onChange={onChangeValue}
      onBlur={e => onBlur(e)}
      onKeyPress={onKeyPress}
      className={`${_class} ${className}`}
      placeholder={placeholder}
      name={name}
      defaultValue={value ? undefined : defaultValue}
      value={defaultValue ? undefined : value || ''}
      required={required}
      disabled={disabled}
      rows={rows}
      maxLength={maxLength}
    />
  );
};

TextAreaBase.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  allowScripts: PropTypes.bool
};

TextAreaBase.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
  className: '',
  placeholder: 'Введите ваш текст',
  name: undefined,
  value: '',
  defaultValue: '',
  required: false,
  disabled: false,
  rows: 2,
  maxLength: undefined,
  allowScripts: false
};
