import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faHospitalUser } from '@fortawesome/free-solid-svg-icons/faHospitalUser';
import {
  hideLoader,
  showLoader,
} from '../../../../../UI/Loader/store/loaderActions';
import { getVisitsHistory } from '../../store/userEmr.actions';
import './VisitHistory.css';
import VisitHistoryList from './VisitHistoryList/VisitHistoryList';
import 'react-datepicker/dist/react-datepicker.css';
import Filters from '../Filters';
import NoContent from '../../../../../UI/NoContent/NoContent';

const VisitHistory = (props) => {
  const { getVisitsHistory, visits, profileId } = props;

  useEffect(() => {
    getVisitsHistory({ profileId });
  }, [getVisitsHistory]);

  useEffect(() => {
    setVisitsList(visits);
  }, [visits]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [visitsList, setVisitsList] = useState(null);

  const isListEmpty = _isEmpty(visitsList);

  const onPeriodChange = (dates) => {
    setDateRange(dates);
    const [start, end] = dates;
    const filteredVisits = visits.filter((visit) => {
      const visitTime = new Date(visit.dateOf).getTime();
      if (!visitTime || (!start && !end)) {
        return true;
      }
      return (
        (start ? start.getTime() <= visitTime : true)
        && (end ? end.getTime() >= visitTime : true)
      );
    });
    setVisitsList(filteredVisits);
  };

  return (
    <div className="container">
      <h4 className="ml-2 mr-2">
        <strong>Список выгруженных приемов пациента:</strong>
      </h4>
      <Filters
        startDate={startDate}
        endDate={endDate}
        onPeriodChange={onPeriodChange}
      />
      {
        isListEmpty ? <NoContent icon={faHospitalUser} title={'Информация о визитах не найдена'}/>
          : <VisitHistoryList visits={visitsList} profileId={profileId} />
      }
    </div>
  );
};

VisitHistory.propTypes = {
  getVisitsHistory: PropTypes.func.isRequired,
  visits: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  profileId: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  visits: store.components.userEmr.historyVisits,
  account: store.account,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  getVisitsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitHistory);
