import {
  put, call,
} from 'redux-saga/effects';
import { chatsApi } from '../../api/chats.api';
import { actions } from './chatRoomReducer';

export function* getChatUserSaga(payload) {
  try {
    const id = payload.payload.claim.user;
    const response = yield call(chatsApi.fetchUserInfo, id);

    yield put({
      type: actions.CHAT_GET_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CHAT_GET_USER_FAILED });
  }
}
