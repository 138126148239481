import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { templatesAdminApi } from './api';
import { actions } from './templates.reducer';
import { actions as sidePanelActions } from '../../../UI/SidePanel/store/sidePanel.reducer';

export function* getTemplates() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(templatesAdminApi.getTemplates);
    yield put({
      type: actions.TEMPLATES_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.TEMPLATES_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* addTemplate(payload) {
  try {
    const { response } = yield call(templatesAdminApi.addTemplate, { ...payload });
    if (response) {
      yield put({ type: 'SHOW_LOADER' });
      yield put({
        type: actions.TEMPLATES_CREATE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.TEMPLATES_REQUEST,
        payload: response.data,
      });
      yield put({ type: 'HIDE_LOADER' });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Шаблон успешно добавлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.TEMPLATES_CREATE_FAILED,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* updateTemplateSaga(payload) {
  try {
    const { response } = yield call(templatesAdminApi.updateTemplate, { ...payload });
    if (response) {
      yield put({ type: 'SHOW_LOADER' });
      yield put({
        type: actions.TEMPLATES_UPDATE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.TEMPLATES_REQUEST,
        payload: response.data,
      });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Шаблон успешно обновлен',
      });
      yield put({ type: 'HIDE_LOADER' });
    }
  } catch (error) {
    yield put({
      type: actions.TEMPLATES_UPDATE_FAILED,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export function* addReplacementToTemplate(payload) {
  try {
    const { response } = yield call(templatesAdminApi.addReplacementsToTemplate, { ...payload });
    if (response) {
      yield put({ type: 'SHOW_LOADER' });
      yield put({
        type: actions.TEMPLATES_ADD_REPLACEMENT_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.TEMPLATES_REQUEST,
        payload: response.data,
      });
      yield put({ type: 'HIDE_LOADER' });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Переменная успешно добавлен',
      });
    }
  } catch (error) {
    yield put({
      type: actions.TEMPLATES_ADD_REPLACEMENT_FAILED,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export const registerTemplatesSagas = [
  takeEvery([actions.TEMPLATES_REQUEST], getTemplates),
  takeEvery([actions.TEMPLATES_CREATE_REQUEST], addTemplate),
  takeEvery([actions.TEMPLATES_UPDATE_REQUEST], updateTemplateSaga),
  takeEvery([actions.TEMPLATES_ADD_REPLACEMENT_REQUEST], addReplacementToTemplate),
];
