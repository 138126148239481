import React from 'react';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Button from '../../../../../../../containers/UI/Buttons/Button';
import downloadFile from '../../../../../../../utils/downloadFile';
import { DateFormats } from '../../../../../../../const/dateFormats';

const UserAnalysisList = (props) => (
    <div className="list-group mt-4">
      {
        props.files && <>
            {
              props.files.map((elem) => (
                <div className="list-group-item mt-2" key={`${elem?.fileId}`}>
                    <div className="d-flex flex-row justify-content-between">
                        <small>{elem?.fileName}</small>
                        <small>Дата: {moment(elem?.dateOf).format(DateFormats.DAY_MONTH_YEAR)}</small>
                    </div>
                    <Chip label={elem?.rubrics} size={'small'} className="mt-2"/>
                    <div className="text-right">
                        <Button className="btn-outline-primary btn-sm"
                                onClick={(event) => downloadFile(event, elem.fileId, props.profileId)}>
                            Скачать
                        </Button>
                    </div>
                </div>
              ))
            }
          </>
      }

    </div>
);

export default UserAnalysisList;
