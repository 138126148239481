import { call, put, takeEvery } from 'redux-saga/effects';
import { apiAuth } from '../../../api/apiAuth';
import { actions as notifyActions } from '../../../../UI/Notificator/notificatorReducer';
import { actions } from '../../../store/auth.reducer';

import SessionStorageCrypt from '../../../../../functional/SessionStorageCrypt';
import {
  hideSidePanel,
  loadSidePanel,
} from '../../../../UI/SidePanel/actions/sidePanel.actions';
import AuthValidate from '../AuthValidate';
import { AUTH_MESSAGES } from '../../../types/MESSAGES';

function saveUserToSession(response) {
  SessionStorageCrypt.setItem('user', response.data.user);
  SessionStorageCrypt.setItem('jwtToken', response.data.token);
}

export function* generate2FACodeSaga() {
  try {
    yield call(apiAuth.apiCreate2FACode);
  } catch (error) {
    yield put({ type: actions.AUTH_TWOFACTOR_FAILED });
  }
}

export function* submit2FACodeSaga(action) {
  try {
    yield call(apiAuth.apiSend2FACode, action.payload.code);
    yield put({ type: actions.AUTH_TWOFACTOR_SUCCESS });
    yield put(hideSidePanel());
    const data = SessionStorageCrypt.getItem('temp-data');
    yield put({
      type: actions.AUTH_REQUEST_SUCCESS,
      payload: data,
    });
    saveUserToSession({ data });
    SessionStorageCrypt.remove('temp-data');
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: 'SHOW_BELL' });
  } catch (error) {
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: AUTH_MESSAGES.INCORRECT_2FA_CODE,
    });
    yield put({ type: actions.AUTH_TWOFACTOR_FAILED });
  }
}

export function* loginWith2FA(response) {
  SessionStorageCrypt.setItem('jwtToken', response.data.token);
  SessionStorageCrypt.setItem('temp-data', response.data);

  try {
    yield call(generate2FACodeSaga);
    yield put(
      loadSidePanel({
        component: AuthValidate,
        args: {},
      }),
    );
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: 'SHOW_BELL' });
  } catch (error) {
    yield put({ type: actions.AUTH_TWOFACTOR_FAILED });
  }
}

export function* loginWithout2FA(response) {
  saveUserToSession(response);
  yield put({
    type: actions.AUTH_REQUEST_SUCCESS,
    payload: response.data,
  });
  yield put({ type: 'HIDE_LOADER' });
  yield put({ type: 'SHOW_BELL' });
}

export const registerValidateSagas = [
  takeEvery([actions.AUTH_SUBMIT_2_FA_CODE], submit2FACodeSaga),
  takeEvery([actions.AUTH_GENERATE_2_FA_CODE], generate2FACodeSaga),
];
