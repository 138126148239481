import { actions } from './claims.reducer';

export function getAllClaims(payload) {
  return {
    type: actions.CLAIMS_REQUEST,
    payload,
  };
}

export function getDoctorServices() {
  return {
    type: actions.DOCTOR_SERVICES_REQUEST,
  };
}

export function claimSetPage(page) {
  return {
    type: actions.CLAIMS_CHANGE_PAGE,
    payload: { page },
  };
}

export function claimResetPage() {
  return {
    type: actions.CLAIMS_CHANGE_PAGE_RESET,
  };
}

export function claimsWipe() {
  return {
    type: actions.CLAIMS_WIPE,
  };
}

export function claimAddService(payload) {
  return {
    type: actions.CLAIM_ADD_SERVICE_REQUEST,
    payload,
  };
}

export function refreshClaim(payload) {
  return {
    type: actions.CLAIM_RESET_REQUEST,
    payload,
  };
}

export function claimAddAdmin(payload) {
  return {
    type: actions.CLAIM_ADD_ADMIN_REQUEST,
    payload,
  };
}

export function claimDeleteAdmin(payload) {
  return {
    type: actions.CLAIM_DELETE_ADMIN_REQUEST,
    payload,
  };
}

export function closeClaim(payload) {
  return {
    type: actions.CLAIM_CLOSE_REQUEST,
    payload,
  };
}

export function payClaim(payload) {
  return {
    type: actions.CLAIM_PAY_REQUEST,
    payload,
  };
}

export function createClaimByAdmin(obj) {
  return {
    type: actions.CLAIM_BY_ADMIN_CREATE_REQUEST,
    payload: { ...obj },
  };
}
