import AxiosClient from '../../../functional/Axios/AxiosClient';

export const transactionsApi = {
  getTransactions: ({ query }) => AxiosClient.get(`/users/v2/transactions?${query}`),

  getTransaction: (id) => AxiosClient.get(`/transactions/${id}`),

  getLatestTransactions: () => AxiosClient.get('/users/v2/transactions/latest'),

  validateTransaction: (transactionId) => AxiosClient.post(`/transactions/validate/${transactionId}`),

  updateTransaction: ({ ...payload }) => {
    const {
      transactionId,
      status,
    } = payload.payload;
    return AxiosClient.put(`/transactions/${transactionId}`, { status })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
