import React from 'react';
import * as PropTypes from 'prop-types';
import { ACCESS_ROLES } from '../../../../../../functional/roles';

const ChatEmrDataControls = (props) => {
  const {
    allowAccess, toggleShowVisits, toggleShowAnalysis, toggleModal, userRole,
  } = props;
  return (
    <>
      {
        allowAccess && userRole === +ACCESS_ROLES.DOCTOR
        && <div>
          <div>
            <button className="btn btn-sm btn-outline-dark mt-2"
                    onClick={toggleShowVisits}>ЭМК пациента
            </button>
          </div>
          <div>
            <button className="btn btn-sm btn-outline-dark mt-2"
                    onClick={toggleShowAnalysis}>Файлы пациента
            </button>
          </div>
          <div>
            <button className="btn btn-sm btn-outline-dark mt-2"
                    onClick={toggleModal}>Записать пациента
            </button>
          </div>
        </div>
      }
    </>
  );
};

ChatEmrDataControls.propTypes = {
  allowAccess: PropTypes.bool.isRequired,
  toggleShowVisits: PropTypes.func.isRequired,
  toggleShowAnalysis: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  userRole: PropTypes.number.isRequired,
};

export default ChatEmrDataControls;
