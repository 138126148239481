import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload';
import LocalLoader from '../../../../../../containers/UI/LocalLoader';
import Button from '../../../../../../containers/UI/Buttons/Button';
import { ChatMessage } from '../../../ChatRoom/components/ChatMessage/ChatMessage';
import { MESSAGE_EVENT } from '../../../../types/MESSAGE_EVENT';

const fileInputId = 'ClinicChatUploadFile';

const FileInput = (props) => <input type="file" {...props} hidden/>;

const EmptyMessages = () => <small>Приветствуем вас! Здесь вы можете задать вопрос онлайн напрямую клинике. Какой у вас вопрос? </small>;

export const ChatWindow = (props) => {
  const owner = `${props.user.firstName} ${props.user.surName}`;

  return (
    <ClickAwayListener onClickAway={(e) => {
      if (e.target?.href?.includes('blob')) {
        return false;
      }
      props.close(e);
    }}>
      <div className="chat-modal d-flex flex-column align-content-between justify-content-between">
        <div className="chat-modal--header d-flex justify-content-between p-1">
          <span className="pt-1">Чат с клиникой</span>
          <Button className="btn-sm btn-link text-dark mb-1" onClick={props.close}>закрыть</Button>
        </div>
        <div className="chat-modal--body">
          {props.messages.length === 0 && <EmptyMessages/>}
          {props.messages && props.messages.map((message) => <ChatMessage
            key={message.hash}
            message={message}
            owner={owner}
            socketEvent={MESSAGE_EVENT.CLINIC}
            downloadFile={props.downloadFile}
          />)}
          {props.fetch && <LocalLoader/>}
        </div>
        <div className="chat-modal--footer">
          <textarea id="txt" className="form-control mt-1" placeholder="Ваше сообщение..." onKeyPress={() => props.typingCb()}/>
          <div className="d-flex justify-content-between">
            <Button className="btn-outline-dark mt-3" onClick={(e) => props.send(e)}>Отправить</Button>
            <Button className="btn-outline-dark mt-3" onClick={() => document.getElementById(fileInputId).click()}>
              <FileInput onChange={(e) => props.loadFile(e)} id={fileInputId} />
              <FontAwesomeIcon icon={faFileUpload}/>
            </Button>
          </div>
        </div>
      </div>
    </ClickAwayListener>);
};

ChatWindow.propTypes = {
  close: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  loadFile: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    surName: PropTypes.string,
  }),
  messages: PropTypes.array,
  downloadFile: PropTypes.func.isRequired,
  fetch: PropTypes.bool,
};
